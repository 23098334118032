import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Modal,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  allPickupAndDeliveryPrinter,
  deleteBilling,
  deleteKot,
  deletepickupAndDeliveryPrinter,
  getAllKot,
  getBillings,
} from "../../service/printerConfiguration";
import styled from "@emotion/styled";
import DeleteIcon from "@mui/icons-material/Delete";
import DescriptionIcon from "@mui/icons-material/Description";
import { useSelector } from "react-redux";
import EditKot from "./EditKot";
import { toast } from "react-toastify";
import EditBilling from "./EditBilling";
import SearchBar from "../common/SearchBar";
import EditPickupAndDelivery from "./EditPickupAndDelivery";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: "bold",
  color: "var(--font-white)",
}));

const style = {
  width: "80%",
  maxHeight: "70%",
  // maxHeight: "600px", // Set maximum height to 300px
  overflowY: "auto", // Allow vertical scrolling
  overflowX: "hidden", // Hide horizontal overflow
  "&::-webkit-scrollbar": {
    display: "none", // Hide scrollbar
  },
  position: "absolute",
  top: "54%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "white",
  boxShadow: 24,
  px: 4,
  py: 2,
  borderRadius: "6px",
};

let initialValues;
export default function AllPrintes({ setValue }) {
  const { biller,switchedLanguage  } = useSelector((state) => state);
  const [query, setQuery] = useState("");
  const keys = ["areaName", "kotName", "printerType"];
  const [allKot, setKot] = useState([]);
  const [allBiiling, setAllBiiling] = useState([]);
  const [allPrinter, setAllPrinter] = useState([]);
  const [editItem, setEditItem] = useState({});
  const [editOpen, setEditOpen] = useState(false);
  const handleOpenEdit = () => setEditOpen(true);
  const handleCloseEdit = () => setEditOpen(false);
  const [editOpenBilling, setEditOpenBilling] = useState(false);
  const handleOpenEditBilling = () => setEditOpenBilling(true);
  const handleCloseEditBilling = () => setEditOpenBilling(false);
  const [editOpenPickup, setEditOpenPickup] = useState(false);
  const handleOpenPickup = () => setEditOpenPickup(true);
  const handleClosePickup = () => setEditOpenPickup(false);

  useEffect(() => {
    fetchAllKot();
    fetchAllBillers();
    allPrinterFunc();
  }, [editOpen, editOpenBilling, editOpenPickup]);

  const fetchAllKot = async () => {
    const data = await getAllKot(biller.token);
    setKot(data);
  };
  const fetchAllBillers = async () => {
    const data = await getBillings(biller.token);
    setAllBiiling(data);
  };
  const allPrinterFunc = async () => {
    const data = await allPickupAndDeliveryPrinter(biller.token);
    console.log(data)
    setAllPrinter(data);
  };
  console.log(allPrinter);
  const setInitialValueFun = (data) => {
    let groupIds = data.groups.map((id) => {
      return id.item;
    });
    let productIds = data.products.map((id) => {
      return id.item;
    });

    initialValues = {
      kotName: data.kotName,
      printer: data.printer,
      groups: groupIds,
      products: productIds,
    };
  };
  const setBillingInitialValueFun = (data) => {
    let tableIds = data.tables.map((id) => {
      return id.table;
    });
    initialValues = {
      areaName: data.areaName,
      printerIp: data.printerIp,
      tables: tableIds,
    };
  };
  const deleteKotFun = async (id) => {
    const data = await deleteKot(id, biller.token);
    if (data.status) {
      fetchAllKot();
      switchedLanguage ? toast.success("يتم حذف أمر المطبخ"):toast.success(data.message);
    }
  };
  const deleteBillingFun = async (id) => {
    const data = await deleteBilling(id, biller.token);
    if (data.status) {
      fetchAllBillers();
      switchedLanguage ? toast.success("يتم حذف مشروع القانون"):toast.success(data.message);
    }
  };
  const deletepickupBilling = async (id) => {
    const data = await deletepickupAndDeliveryPrinter(id, biller.token);
    if (data.status) {
      allPrinterFunc();
      switchedLanguage? toast.success("يتم تحرير الطابعة بنجاح"):toast.success(data.message);
    }
  };
  return (
    <div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: "10px",
        }}
      >
        <Typography sx={{ fontFamily: "poppins" }}>{switchedLanguage ? "جميع الطابعات": "All Printers"}</Typography>
        <Box sx={{ display: "flex" }}>
          <SearchBar
            searchBarWidth={"200px"}
            placeholder={switchedLanguage ? "البحث": "Search"}
            onChange={(e) => {
              setQuery(e.target.value);
            }}
          />
          <Button
            variant="contained"
            sx={{
              textTransform: "none",
              background: "var(--red)",
              ml: "15px",
              mr: "10px",
            }}
            onClick={() => {
              setValue("2");
            }}
          >
            {switchedLanguage ? "أضف إلى المطبخ": "Add Kot"}
          </Button>
          <Button
            variant="contained"
            sx={{
              textTransform: "none",
              background: "var(--red)",
            }}
            onClick={() => {
              setValue("3");
            }}
          >
            {switchedLanguage ? "إضافة فواتير": "Add Billing"}
          </Button>
        </Box>
      </Box>
      <Box>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: "100%" }} aria-label="simple table">
            <TableHead sx={{ background: "var(--dark-gray)" }}>
              <TableRow>
                <StyledTableCell align="center">{switchedLanguage ? "الاسم": "Name"}</StyledTableCell>
                <StyledTableCell align="center">{switchedLanguage ? "نوع الطابعة": "Printer Type"}</StyledTableCell>
                <StyledTableCell align="center">{switchedLanguage ? "الإجراءات": "Action"}</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {allKot
                ?.filter((item) =>
                  keys.some((key) => item[key]?.toLowerCase()?.includes(query))
                )
                .map((row) => (
                  <TableRow key={row._id}>
                    <TableCell align="center" sx={{ fontFamily: "poppins" }}>
                      {row.kotName}
                    </TableCell>
                    <TableCell align="center" sx={{ fontFamily: "poppins" }}>
                      {switchedLanguage ? "المطبخ": "KOT"}
                    </TableCell>
                    <TableCell align="center">
                      <Box>
                        <Button
                          sx={{
                            width: "20px",
                          }}
                          onClick={() => {
                            setInitialValueFun(row);
                            setEditItem(row);
                            // findsubUnit(item);
                            handleOpenEdit();
                          }}
                        >
                          <DescriptionIcon />
                        </Button>
                        <Button
                          sx={{
                            width: "20px",
                            color: "var(--red)",
                          }}
                          onClick={() => deleteKotFun(row._id)}
                        >
                          <DeleteIcon />
                        </Button>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              {allBiiling
                ?.filter((item) =>
                  keys.some((key) => item[key]?.toLowerCase()?.includes(query))
                )
                .map((row) => (
                  <TableRow key={row._id}>
                    <TableCell align="center" sx={{ fontFamily: "poppins" }}>
                      {row.areaName}
                    </TableCell>
                    <TableCell align="center" sx={{ fontFamily: "poppins" }}>
                      {switchedLanguage ? "الفواتير": "Billing"}
                    </TableCell>
                    <TableCell align="center">
                      <Box>
                        <Button
                          sx={{
                            width: "20px",
                          }}
                          onClick={() => {
                            setBillingInitialValueFun(row);
                            setEditItem(row);
                            handleOpenEditBilling();
                          }}
                        >
                          <DescriptionIcon />
                        </Button>
                        <Button
                          sx={{
                            width: "20px",
                            color: "var(--red)",
                          }}
                          onClick={() => deleteBillingFun(row._id)}
                        >
                          <DeleteIcon />
                        </Button>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              {allPrinter
                ?.filter((item) =>
                  keys.some((key) => item[key]?.toLowerCase()?.includes(query))
                )
                .map((row) => (
                  <TableRow key={row._id}>
                    <TableCell align="center" sx={{ fontFamily: "poppins" }}>
                    { row.printerType == "Delivery" && switchedLanguage
                        ? "التسليم"
                        : row.printerType == "Pickup" && switchedLanguage
                        ? "أمر التقاط"
                        : row.printerType} {switchedLanguage ? "الطابعة" : "Printer"}
                    </TableCell>
                    <TableCell align="center" sx={{ fontFamily: "poppins" }}>
                    { row.printerType == "Delivery" && switchedLanguage
                        ? "التسليم"
                        : row.printerType == "Pickup" && switchedLanguage
                        ? "أمر التقاط"
                        : row.printerType}
                    </TableCell>
                    <TableCell align="center">
                      <Box>
                        <Button
                          sx={{
                            width: "20px",
                          }}
                          onClick={() => {
                            setEditItem(row);
                            handleOpenPickup();
                          }}
                        >
                          <DescriptionIcon />
                        </Button>
                        <Button
                          sx={{
                            width: "20px",
                            color: "var(--red)",
                          }}
                          onClick={() => deletepickupBilling(row._id)}
                        >
                          <DeleteIcon />
                        </Button>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Modal
        open={editOpen}
        onClose={handleCloseEdit}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <EditKot
            editItem={editItem}
            initialValues={initialValues}
            handleClose={handleCloseEdit}
          />
        </Box>
      </Modal>
      <Modal
        open={editOpenBilling}
        onClose={handleCloseEditBilling}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <EditBilling
            editItem={editItem}
            initialValues={initialValues}
            handleClose={handleCloseEditBilling}
          />
        </Box>
      </Modal>
      <Modal
        open={editOpenPickup}
        onClose={handleClosePickup}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <EditPickupAndDelivery
            editItem={editItem}
            handleClose={handleClosePickup}
          />
        </Box>
      </Modal>
    </div>
  );
}
