import React, { useEffect, useState } from "react";
import { Button, Toolbar } from "@mui/material";
import { Box } from "@mui/system";
import { Link, useNavigate } from "react-router-dom";
import AdminSubNavbar from "./../components/common/SubNavbar";
import { TabContext, TabPanel } from "@mui/lab";
import AllKotOrders from "./../components/kot/AllKotOrders";
import SearchBar from "./../components/common/SearchBar";
import { useSelector } from "react-redux";

export default function Kot() {
  const navigate = useNavigate();
  const [value, setValue] = useState("1");
  const { switchedLanguage } = useSelector((state) => state);
  const navBarItems = [
    {
      label: switchedLanguage ? "الكل": "All",
      value: "1",
    },
    {
      label: switchedLanguage ? "على الطاولة": "Dine in",
      value: "2",
    },
    {
      label:switchedLanguage ? "التسليم": "Delivery",
      value: "3",
    },
    {
      label:switchedLanguage ? "أمر التقاط": "Pickup",
      value: "4",
    },
  ];
  const [query, setQuery] = useState("");
  const keys = ["orderId.orderId", "tableNo", "kotId.kotName"];
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <Toolbar />
      <Box>
        <TabContext value={value}>
          <AdminSubNavbar
            handleChange={handleChange}
            items={navBarItems}
            value={value}
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mt: "25px",
              mb: "10px",
              mx: "28px",
            }}
          >
            <SearchBar
              searchBarWidth={"200px"}
              placeholder={switchedLanguage ? "البحث": "Search"}
              onChange={(e) => setQuery(e.target.value.toLowerCase())}
            />
            <Button
              variant="contained"
              sx={{
                textTransform: "none",
                background: "var(--red)",
                "&:hover": {
                  backgroundColor: "var(--red)",
                },
              }}
              component={Link}
              to={"/orders"}
              // onClick={handleOpenTable}
            >
              {switchedLanguage ? "عرض الأوامر": "Order View"}
            </Button>
          </Box>
          <TabPanel value="1" sx={{ p: 0 }}>
            <AllKotOrders type={"All"} query={query} keys={keys} />
          </TabPanel>
          <TabPanel value="2" sx={{ p: 0 }}>
            {" "}
            <AllKotOrders type={"Dine in"} query={query} keys={keys} />
          </TabPanel>
          <TabPanel value="3" sx={{ p: 0 }}>
            {" "}
            <AllKotOrders type={"Delivery"} query={query} keys={keys} />
          </TabPanel>
          <TabPanel value="4" sx={{ p: 0 }}>
            {" "}
            <AllKotOrders type={"Pickup"} query={query} keys={keys} />
          </TabPanel>
        </TabContext>
      </Box>
    </div>
  );
}
