import React from "react";
import { Box, Modal, Paper, Typography, Button } from "@mui/material";
import TextField from "@mui/material/TextField";
import CloseIcon from "@mui/icons-material/Close";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";

import { useSelector } from "react-redux";

const PlaceOrderModal = ({
  handleOpenPlaceOrderModal,
  handleClosePlaceOrderModal,
  selectedTables,
  guests,
  setGuests,
  handlePlaceOrderConfirm,
}) => {
  const tables = selectedTables?.map((item) => item.tableNo).join(", T-");
  const { switchedLanguage } = useSelector((state) => state);

  return (
    <>
      {
        <Modal
          open={handleOpenPlaceOrderModal}
          onClose={handleClosePlaceOrderModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Paper
            sx={{ border: "none", display: "flex", justifyContent: "center" }}
          >
            <Box
              sx={{
                position: "absolute",
                left: "50%",
                padding: "26px",
                backgroundColor: "background.paper",
                width: "25em",
                borderRadius: "5px",
                top: "50%",
                transform: "translate(-50%,-50%)",
                display: "flex",
                flexDirection: "column",
                gap: "5px",
                borderRadius: "17px",
              }}
            >
              <Box sx={{ marginBottom: "30px" }}>
                <sup
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <CloseIcon
                    onClick={handleClosePlaceOrderModal}
                    sx={{
                      fontFamily: "poppins",
                      fontSize: "18px",
                      cursor: "pointer",
                      fontWeight: "bold",
                    }}
                  />
                </sup>
                <Typography
                  sx={{
                    fontFamily: "poppins",
                    fontSize: "17px",
                    fontWeight: "600",
                    textAlign: "center",
                  }}
                >
                  {switchedLanguage ? "طلبات جديدة": "New Order"}
                </Typography>
              </Box>

              <Box>
                <Box sx={{ marginBottom: "30px" }}>
                  <Typography
                    sx={{
                      fontFamily: "poppins",
                      marginBottom: "6px",
                    }}
                  >
                    {switchedLanguage ? "الجدول": "Table"}
                  </Typography>
                  <TextField
                    variant="standard"
                    disabled={true}
                    InputProps={{
                      disableUnderline: true,
                    }}
                    inputProps={{
                      style: { fontFamily: "poppins" },
                      value: `T-${tables}`,
                    }}
                    sx={{
                      width: "100%",
                      fontFamily: "poppins",
                      borderRadius: "18px",
                      backgroundColor: "#F2F3F7",
                      border: "1px solid #F0F0F0",
                      outline: "none",
                      height: "45px",
                      display: "flex",
                      justifyContent: "center",
                      padding: "20px",
                    }}
                  />
                </Box>
                <Box>
                  <Typography
                    sx={{
                      fontFamily: "poppins",
                      marginBottom: "6px",
                    }}
                  >
                    {switchedLanguage ? "ضيف": "Guest"}
                  </Typography>

                  <Box
                    sx={{ display: "flex", gap: "15px", alignItems: "center" }}
                  >
                    <button
                      onClick={() => setGuests(guests - 1)}
                      disabled={guests === 1 ? true : false}
                      style={{
                        border: "1px solid #F0F0F0",
                        height: "38px",
                        width: "48px",
                        borderRadius: "18px",
                        backgroundColor: "#FFFFFF",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                    >
                      <RemoveIcon />
                    </button>
                    <Typography sx={{ fontFamily: "poppins" }}>
                    {switchedLanguage ? guests.toLocaleString("ar-EG") : guests}
                    </Typography>
                    <button
                      onClick={() => setGuests(guests + 1)}
                      style={{
                        border: "1px solid #F0F0F0",
                        fontFamily: "poppins",
                        height: "38px",
                        width: "48px",
                        borderRadius: "18px",
                        backgroundColor: "#FFFFFF",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",

                        cursor: "pointer",
                      }}
                    >
                      <AddIcon />
                    </button>
                  </Box>
                </Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  gap: "10px",
                  justifyContent: "space-between",
                  marginTop: "40px",
                }}
              >
                <Button
                  onClick={handleClosePlaceOrderModal}
                  variant="contained"
                  sx={{
                    boxShadow: "none",
                    width: "48%",
                    height: "43px",
                    backgroundColor: "#FFFFFF",
                    color: "#4D575D",
                    border: "1px solid #F0F0F0",
                    fontFamily: "poppins",
                    cursor: "pointer",
                    borderRadius: "11px",
                    textTransform: "none",
                    "&:hover": {
                      boxShadow: "none",
                      backgroundColor: "#FFFFFF",
                    },
                  }}
                >
                  {switchedLanguage ? "إلغاء": "Cancel"}
                </Button>
                <Button
                  onClick={handlePlaceOrderConfirm}
                  variant="contained"
                  sx={{
                    boxShadow: "none",
                    width: "48%",
                    height: "43px",
                    backgroundColor: "#f40000",
                    color: "#f2f3f8",
                    border: "none",
                    fontFamily: "poppins",
                    cursor: "pointer",
                    borderRadius: "11px",
                    textTransform: "none",
                    "&:hover": {
                      boxShadow: "none",
                      backgroundColor: "#f40000",
                    },
                  }}
                >
                  {switchedLanguage ? "التأكيد": "Confirm"}
                </Button>
              </Box>
            </Box>
          </Paper>
        </Modal>
      }
    </>
  );
};

export default PlaceOrderModal;
