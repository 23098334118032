import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Moment from "react-moment";
import { kotProductsChangeStatus } from "../../service/kot";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

export default function KotCard({ item, fetchAllkots }) {
  const { biller, switchedLanguage  } = useSelector((state) => state);
  const kotProductsReady = async (kotId, status) => {
    const data = await kotProductsChangeStatus(kotId, status, biller.token);
 
    if (data.status) {
      switchedLanguage ? toast.success("تحديث حالة ترتيب المطبخ"): toast.success(data.message);
      fetchAllkots();
    }
  };

  // Function to convert a number to Arabic numerals
  function toArabicNumerals(number) {
    const arabicNumerals = ["٠", "١", "٢", "٣", "٤", "٥", "٦", "٧", "٨", "٩"];
    return String(number).replace(/\d/g, (digit) => arabicNumerals[digit]);
  }

  return (
    <Card
      sx={{
        borderRadius: "0px",
        width: "280px",
        mb: "10px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        mx: "8px",
      }}
    >
      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            background: "var(--card-bg-gray)",
            height: "40px",
            alignItems: "center",
            px: "20px",
          }}
        >
          <Typography color="var(--font-white)" sx={{ fontSize: "14px" }}>
            {switchedLanguage ? "البند": "Item"}
          </Typography>
          <Typography color="var(--font-white)" sx={{ fontSize: "14px" }}>
            {switchedLanguage ? "كمية المنتج": "Qty"}
          </Typography>
        </Box>
        <Box
          sx={{
            height: "105px",
            overflowX: "hidden",
            "&::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
   
          {item?.products.map((products) => (
         
            <Box key={products?._id}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  borderBottom: "solid var(--border-gray) 1px",
                  height: "35px",
                  alignItems: "center",
                  px: "20px",
                }}
              >
                <Typography sx={{ fontSize: "14px" }}>
                  {products?.varientId?.productName}
                </Typography>
                <Typography sx={{ fontSize: "14px" }}>
                  {switchedLanguage ? products?.quantity.toLocaleString("ar-EG"): products?.quantity}
                </Typography>
              </Box>

              {products?.addOns?.map((addOns) => (
                <Box
                  key={addOns?._id}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    borderBottom: "solid var(--border-gray) 1px",
                    height: "35px",
                    alignItems: "center",
                    px: "20px",
                  }}
                >
                  <Typography sx={{ fontSize: "14px" }}>
                    {addOns.name}
                  </Typography>
                  <Typography sx={{ fontSize: "14px" }}>
                    {addOns.quantity}
                  </Typography>
                </Box>
              ))}
            </Box>
          ))}
        </Box>
      </Box>
      <Box>
        <Box sx={{ borderTop: "solid var(--border-gray) 1px" }}>
          <Box
            sx={{
              height: "40px",
              alignItems: "center",
              px: "20px",
            }}
          >
            <Typography sx={{ fontSize: "14px", fontWeight: "bold" }}>
            {switchedLanguage ? "انها مخصصة ل": "Assigned"} : John Smith
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-around",
            background: "",
            alignContent: "center",
          }}
        >
          <Button
            variant="contained"
            type="submit"
            sx={{
              background: "var(--red)",
              width: "130px",
              // ml: "10px",
              textTransform: "none",
              borderRadius: "5px",
              "&:hover": {
                backgroundColor: "var(--red)",
              },
            }}
            onClick={() => {
              kotProductsReady(item._id, "Ready");
            }}
          >
            {switchedLanguage ? "جاهز": "Ready"}
          </Button>
          <Button
            variant="contained"
            sx={{
              color: "var(--font-black)",
              background: "var(--button-gray)",
              width: "130px",
              // mr: "10px",
              textTransform: "none",
              borderRadius: "5px",
              "&:hover": {
                backgroundColor: "var(--button-gray)",
              },
            }}
            onClick={() => kotProductsReady(item._id, "Cancelled")}
          >
            {switchedLanguage ? "إلغاء": "Cancel"}
          </Button>
        </Box>
        <Box
          sx={{
            display: "flex",
            background: "var(--bg-blue)",
            mt: "15px",
            height: "50px",
          }}
        >
          <Box
            sx={{
              width: "33%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              borderRight: "solid var(--border-gray) 1px",
            }}
          >
            <Typography sx={{ fontSize: "14px", mt: "6px" }}>
            {item?.typeofOrder == "Dine in" && switchedLanguage
                ? "على الطاولة"
                : item?.typeofOrder == "Delivery" && switchedLanguage
                ? "التسليم"
                : item?.typeofOrder == "Pickup" && switchedLanguage
                ? "أمر التقاط"
                : item?.typeofOrder}
            </Typography>
            <Typography sx={{ fontSize: "11px" }}>
              {switchedLanguage ? "الجدول": "Table"} {switchedLanguage
                ? toArabicNumerals(item?.tableNo)
                : item?.tableNo}
            </Typography>
          </Box>
          <Box
            sx={{
              width: "33%",
              display: "flex",
              borderRight: "solid var(--border-gray) 1px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography sx={{ fontSize: "14px" }}>
              <Moment element="span" style={{ width: "100%" }} fromNow ago>
                {item.createdAt}
              </Moment>
            </Typography>
          </Box>
          <Box
            sx={{
              width: "33%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography sx={{ fontSize: "12px" }}>
            {switchedLanguage ? "المطبخ": "Kot"} : {item?.kotId?.kotName}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Card>
  );
}
