import { useEffect, useState } from "react";
import {
  Typography,
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  TableBody,
  Button,
} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import PrintIcon from "@mui/icons-material/Print";
import { useSelector } from "react-redux";
import {
  getIncomeBasedOnPaymentMethod,
  getPurchaseSummary,
  getSalesByGroup,
} from "../service/dayEnd";

import moment from "moment";
import "moment/locale/ar"; // Import the Arabic locale for moment

function createExpenseData(expense, cash, cardUpi, total) {
  return { expense, cash, cardUpi, total };
}

const expense = [
  createExpenseData("Purchase", "20,000.00", "50,000.00", "50,000.00"),
  createExpenseData("Wages", "10,000.00", "30,000.00", "40,000.00"),
  createExpenseData("", "", "", ""),
  createExpenseData("Total Expense", "35,000.00", "86,000.00", "40,000.00"),
];

function createCashierReport(
  cashier,
  time,
  openingBalance,
  closingBalance,
  asPerRecord,
  difference
) {
  return {
    cashier,
    time,
    openingBalance,
    closingBalance,
    asPerRecord,
    difference,
  };
}

const cashier = [
  createCashierReport("Jamal", "10:00 am to 2:00 pm", 10000, 50000, 50000, 0),
  createCashierReport("Ajmal", "2:00 pm to 5:00 pm", 50000, 8000, 90000, -2000),
  createCashierReport(
    "Asif",
    "5:00 pm to 10:00 pm",
    88000,
    1010000,
    100000,
    1000
  ),
];

export default function DayEndSummary() {
  const { biller, switchedLanguage } = useSelector((state) => state);
  const [income, setIncome] = useState([]);
  const [group, setGroup] = useState([]);
  const [purchases, setPurchase] = useState([]);
  const getDatas = async () => {
    const income = await getIncomeBasedOnPaymentMethod(biller.token);
    setIncome(income);
    const group = await getSalesByGroup(biller.token);
    setGroup(group);
    const purchase = await getPurchaseSummary(biller.token);
    setPurchase(purchase);
  };

  // Currency
  const currency =
    switchedLanguage && biller.vendor.country == "UAE"
      ? "الدرهم"
      : switchedLanguage && biller.vendor.country == "KSA"
      ? "ريال"
      : !switchedLanguage && biller.vendor.country == "IND"
      ? "₹"
      : "";

  useEffect(() => {
    getDatas();
  }, []);

  const sums = {
    totalCOD: 0,
    totalCard: 0,
    totalUPI: 0,
    totalCard_UPI: 0,
    totalTotal: 0,
  };

  income.forEach((obj) => {
    sums.totalCOD += obj.COD || 0;
    sums.totalCard += obj.Card || 0;
    sums.totalUPI += obj.UPI || 0;
    sums.totalCard_UPI += obj.Card_UPI || 0;
    sums.totalTotal += obj.Total || 0;
  });

  const purch = {
    totalPaid: 0,
    totalUnpaid: 0,
    totalTotal: 0,
  };

  purchases.forEach((obj) => {
    purch.totalPaid += obj.Paid || 0;
    purch.totalUnpaid += obj.Credit || 0;
    purch.totalTotal += obj.Total || 0;
  });

  const timeRangeArabicConverter = (timeRange) => {
    const time1 = timeRange.split(" to ")[0];
    const time2 = timeRange.split(" to ")[1];

    const time1Formatted = moment(time1, "h:mm a").locale("ar").format("h:mm A");
    const time2Formatted = moment(time2, "h:mm a").locale("ar").format("h:mm A");

    return `${time1Formatted} إلى ${time2Formatted}`;
  };

  return (
    <>
      <div
        style={{
          marginTop: "8vh",
          paddingLeft: "4em",
          paddingRight: "4em",
          paddingTop: "1em",
          width: "100%",
        }}
      >
        <Typography variant="h6">
          {switchedLanguage ? "ملخص يوم نهاية" : "Day End Summary"}
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            py: 2,
            gap: 2,
          }}
        >
          <TableContainer component={Paper} sx={{ flex: 1 }}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow
                  sx={{
                    "&:first-child th": { bgcolor: "#F4F5F7" },
                  }}
                >
                  <TableCell
                    sx={{ fontWeight: "bold" }}
                    align={switchedLanguage ? "right" : "left"}
                  >
                    {switchedLanguage ? "الدخل" : "Income"}
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold" }} align="center">
                    {switchedLanguage ? "النقدية" : "Cash"}
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold" }} align="center">
                    {switchedLanguage ? "بطاقة" : "Card/Upi"}
                  </TableCell>
                  <TableCell
                    sx={{ fontWeight: "bold" }}
                    align={switchedLanguage ? "left" : "right"}
                  >
                    {switchedLanguage ? "المجموع" : "Total"}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {income.map((row) => (
                  <TableRow>
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{ fontWeight: "bold" }}
                      align={switchedLanguage ? "right" : "left"}
                    >
                      {row._id == "Dine in" && switchedLanguage
                        ? "على الطاولة"
                        : row._id == "Delivery" && switchedLanguage
                        ? "التسليم"
                        : row._id == "Pickup" && switchedLanguage
                        ? "أمر التقاط"
                        : row._id}
                    </TableCell>
                    <TableCell align="center">
                      {switchedLanguage
                        ? row.COD?.toLocaleString("ar-EG", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })
                        : row.COD.toFixed(2)}
                    </TableCell>
                    <TableCell align="center">
                      {switchedLanguage
                        ? row.Card_UPI?.toLocaleString("ar-EG", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })
                        : row.Card_UPI.toFixed(2)}
                    </TableCell>
                    <TableCell align={switchedLanguage ? "left" : "right"}>
                      {switchedLanguage
                        ? row.Total?.toLocaleString("ar-EG", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })
                        : row.Total.toFixed(2)}
                    </TableCell>
                  </TableRow>
                ))}
                <TableRow
                  sx={{
                    "&:last-child td, &:last-child th": {
                      border: 0,
                      bgcolor: "#F4F5F7",
                    },
                  }}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{ fontWeight: "bold" }}
                    align={switchedLanguage ? "right" : "left"}
                  >
                    {switchedLanguage ? "مجموع المبيعات" : "Total Sales"}
                  </TableCell>
                  <TableCell align="center">
                    {switchedLanguage
                      ? sums.totalCOD?.toLocaleString("ar-EG", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      : sums.totalCOD.toFixed(2)}
                  </TableCell>
                  <TableCell align="center">
                    {switchedLanguage
                      ? sums.totalCard_UPI?.toLocaleString("ar-EG", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      : sums.totalCard_UPI.toFixed(2)}
                  </TableCell>
                  <TableCell align={switchedLanguage ? "left" : "right"}>
                    {switchedLanguage
                      ? sums.totalTotal?.toLocaleString("ar-EG", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      : sums.totalTotal.toFixed(2)}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>

          <TableContainer component={Paper} sx={{ flex: 1 }}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow
                  sx={{
                    "&:first-child th": { bgcolor: "#F4F5F7" },
                  }}
                >
                  <TableCell
                    sx={{ fontWeight: "bold" }}
                    align={switchedLanguage ? "right" : "left"}
                  >
                    {switchedLanguage ? "النفقات" : "Expense"}
                  </TableCell>
                  <TableCell
                    sx={{ fontWeight: "bold" }}
                    align={switchedLanguage ? "left" : "right"}
                  >
                    {switchedLanguage ? "المبلغ" : "Amount"}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow
                  sx={{
                    "&:last-child td, &:last-child th": {
                      border: 0,
                      bgcolor: "#F4F5F7",
                    },
                    "&:last-child th, &:last-child td": {
                      alignSelf: "flex-end",
                    },
                    height: 52,
                  }}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{ fontWeight: "bold" }}
                    align={switchedLanguage ? "right" : "left"}
                  >
                    {switchedLanguage ? "شراء" : "Purchase"}
                  </TableCell>

                  <TableCell align={switchedLanguage ? "left" : "right"}>
                    {switchedLanguage
                      ? purch.totalTotal?.toLocaleString("ar-EG", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      : purch.totalTotal.toFixed(2)}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>

        <Typography variant="h6" sx={{ paddingBottom: 2 }}>
          {switchedLanguage ? "أمين الصندوق تقرير" : "Cashier Report"}
        </Typography>
        <TableContainer component={Paper} sx={{ flex: 1 }}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow
                sx={{
                  "&:first-child th": { bgcolor: "#F4F5F7" },
                }}
              >
                <TableCell
                  sx={{ fontWeight: "bold" }}
                  align={switchedLanguage ? "right" : "left"}
                >
                  {switchedLanguage ? "أمين الصندوق" : "Cashier"}
                </TableCell>
                <TableCell align="center">
                  {switchedLanguage ? "الوقت" : "Time"}
                </TableCell>
                <TableCell align="center">
                  {switchedLanguage ? "الرصيد الافتتاحي" : "Opening Balance"}
                </TableCell>
                <TableCell align="center">
                  {switchedLanguage ? "رصيد ختامي" : "Closing Balance "}
                </TableCell>
                <TableCell align="center">
                  {switchedLanguage ? "وفقا للسجلات" : "As Per Records"}
                </TableCell>
                <TableCell align={switchedLanguage ? "left" : "right"}>
                  {switchedLanguage ? "التغييرات" : "Difference"}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {cashier.map((row) => (
                <TableRow key={row.name}>
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{ fontWeight: "bold" }}
                    align={switchedLanguage ? "right" : "left"}
                  >
                    {row.cashier}
                  </TableCell>
                  <TableCell align="center">{switchedLanguage ? timeRangeArabicConverter(row.time) : row.time}</TableCell>
                  <TableCell align="center">
                    {switchedLanguage ? "" : currency}
                    {switchedLanguage
                      ? row.openingBalance?.toLocaleString("ar-EG", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      : row.openingBalance.toFixed(2)}{" "}
                    {switchedLanguage ? currency : ""}
                  </TableCell>
                  <TableCell align="center">
                    {switchedLanguage ? "" : currency}
                    {switchedLanguage
                      ? row.closingBalance?.toLocaleString("ar-EG", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      : row.closingBalance.toFixed(2)}{" "}
                    {switchedLanguage ? currency : ""}
                  </TableCell>
                  <TableCell align="center">
                    {switchedLanguage ? "" : currency}
                    {switchedLanguage
                      ? row.asPerRecord?.toLocaleString("ar-EG", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      : row.asPerRecord.toFixed(2)}{" "}
                    {switchedLanguage ? currency : ""}
                  </TableCell>
                  <TableCell align={switchedLanguage ? "left" : "right"}>
                    {switchedLanguage ? "" : currency}
                    {switchedLanguage
                      ? row.difference?.toLocaleString("ar-EG", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      : row.difference.toFixed(2)}{" "}
                    {switchedLanguage ? currency : ""}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            py: 2,
            gap: 2,
          }}
        >
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="h6" sx={{ paddingBottom: 2 }}>
              {switchedLanguage ? "المبيعات حسب المجموعة" : "Sales by Group"}
            </Typography>
            <TableContainer
              component={Paper}
              sx={{
                maxHeight: "268px",
                overflow: "auto",
                "&::-webkit-scrollbar": {
                  display: "none",
                },
              }}
            >
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow
                    sx={{
                      "&:first-child th": { bgcolor: "#F4F5F7" },
                    }}
                  >
                    <TableCell
                      sx={{ fontWeight: "bold" }}
                      align={switchedLanguage ? "right" : "left"}
                    >
                      {switchedLanguage ? "الفئة" : "Category"}
                    </TableCell>
                    <TableCell
                      sx={{ fontWeight: "bold" }}
                      align={switchedLanguage ? "left" : "right"}
                    >
                      {switchedLanguage ? "المجموع" : "Total"}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {group.map((row) => (
                    <TableRow
                      key={row._id}
                      sx={{
                        "&:last-child td, &:last-child th": {
                          border: 0,
                          bgcolor: "#F4F5F7",
                        },
                      }}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        sx={{ fontWeight: "bold" }}
                        align={switchedLanguage ? "right" : "left"}
                      >
                        {row._id}
                      </TableCell>
                      <TableCell align={switchedLanguage ? "left" : "right"}>
                        {switchedLanguage
                          ? row.Total?.toLocaleString("ar-EG", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                          : row.Total.toFixed(2)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>

          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="h6" sx={{ paddingBottom: 2 }}>
              {switchedLanguage ? "تسرب الإيرادات" : "Revenue Leakage"}
            </Typography>
            <Box
              sx={{
                border: "1px solid #F4F5F7",
                borderRadius: "5px",
                height: 270,
                flexGrow: 1,
                p: 2,
              }}
            >
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr 1fr",
                  gridTemplateRows: "1fr 1fr",
                  rowGap: 2,
                  columnGap: 2,
                  py: 4,
                }}
              >
                <Box sx={{ background: "#F4F5F7", p: 2, borderRadius: "5px" }}>
                  <Typography>
                    {switchedLanguage
                      ? Number(10).toLocaleString("ar-EG")
                      : "10"}
                  </Typography>
                  <Typography>
                    {switchedLanguage ? "الفواتير المعدلة" : "Bill Modified"}
                  </Typography>
                </Box>
                <Box sx={{ background: "#F4F5F7", p: 2, borderRadius: "5px" }}>
                  <Typography>
                    {switchedLanguage
                      ? Number(10).toLocaleString("ar-EG")
                      : "10"}
                  </Typography>
                  <Typography>
                    {switchedLanguage
                      ? "إعادة طباعة الفواتير"
                      : "Bills Re-printed"}
                  </Typography>
                </Box>
                <Box sx={{ background: "#F4F5F7", p: 2, borderRadius: "5px" }}>
                  <Typography>
                    {switchedLanguage
                      ? Number(10).toLocaleString("ar-EG")
                      : "10"}
                  </Typography>
                  <Typography>
                    {switchedLanguage ? "التنازل" : "Waived off"}
                  </Typography>
                </Box>

                <Box sx={{ background: "#F4F5F7", p: 2, borderRadius: "5px" }}>
                  <Typography>
                    {switchedLanguage
                      ? Number(10).toLocaleString("ar-EG")
                      : "10"}
                  </Typography>
                  <Typography>
                    {switchedLanguage ? "الرفض" : "Cancelled"}
                  </Typography>
                </Box>
                <Box sx={{ background: "#F4F5F7", p: 2, borderRadius: "5px" }}>
                  <Typography>
                    {switchedLanguage
                      ? Number(10).toLocaleString("ar-EG")
                      : "10"}
                  </Typography>
                  <Typography>
                    {switchedLanguage
                      ? "أوامر المطبخ المعدلة"
                      : "Modified KOTS"}
                  </Typography>
                </Box>
                <Box sx={{ background: "#F4F5F7", p: 2, borderRadius: "5px" }}>
                  <Typography>
                    {switchedLanguage
                      ? Number(10).toLocaleString("ar-EG")
                      : "10"}
                  </Typography>
                  <Typography>
                    {switchedLanguage
                      ? "غير مستخدمة في بيل"
                      : "Not Used in Bill"}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>

        <Typography variant="h6" sx={{ paddingBottom: 2 }}>
          {switchedLanguage ? "المشتريات" : "Purchases"}
        </Typography>
        <TableContainer
          component={Paper}
          sx={{
            flex: 1,
            maxHeight: "267px",
            overflow: "auto",
            "&::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow
                sx={{
                  "&:first-child th": { bgcolor: "#F4F5F7" },
                }}
              >
                <TableCell
                  sx={{ fontWeight: "bold" }}
                  align={switchedLanguage ? "right" : "left"}
                >
                  {switchedLanguage ? "المورد" : "Supplier"}
                </TableCell>
                <TableCell align="center">
                  {switchedLanguage ? "المدفوعة" : "Paid"}
                </TableCell>
                <TableCell align="center">
                  {switchedLanguage ? "الائتمان" : "Credit"}
                </TableCell>
                <TableCell align={switchedLanguage ? "left" : "right"}>
                  {switchedLanguage ? "المجموع" : "Total"}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {purchases.map((row) => (
                <TableRow>
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{ fontWeight: "bold" }}
                    align={switchedLanguage ? "right" : "left"}
                  >
                    {row._id}
                  </TableCell>
                  <TableCell align="center">
                    {switchedLanguage
                      ? row.Paid?.toLocaleString("ar-EG", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      : row.Paid.toFixed(2)}
                  </TableCell>
                  <TableCell align="center">
                    {switchedLanguage
                      ? row.Credit?.toLocaleString("ar-EG", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      : row.Credit.toFixed(2)}
                  </TableCell>
                  <TableCell align={switchedLanguage ? "left" : "right"}>
                    {switchedLanguage
                      ? row.Total?.toLocaleString("ar-EG", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      : row.Total.toFixed(2)}
                  </TableCell>
                </TableRow>
              ))}
              <TableRow
                sx={{
                  "&:last-child td, &:last-child th": {
                    border: 0,
                    bgcolor: "#F4F5F7",
                  },
                }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  sx={{ fontWeight: "bold" }}
                  align={switchedLanguage ? "right" : "left"}
                >
                  {switchedLanguage ? "مجموع المشتريات" : "Total Purchase"}
                </TableCell>
                <TableCell align="center">
                  {switchedLanguage
                    ? purch.totalPaid?.toLocaleString("ar-EG", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : purch.totalPaid.toFixed(2)}
                </TableCell>
                <TableCell align="center">
                  {switchedLanguage
                    ? purch.totalUnpaid?.toLocaleString("ar-EG", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : purch.totalUnpaid.toFixed(2)}
                </TableCell>
                <TableCell align={switchedLanguage ? "left" : "right"}>
                  {switchedLanguage
                    ? purch.totalTotal?.toLocaleString("ar-EG", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : purch.totalTotal.toFixed(2)}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        <hr style={{ marginTop: "50px" }} />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            py: 2,
          }}
        >
          <Box sx={{ display: "flex", gap: 2 }}>
            <Typography>
              {switchedLanguage ? "مجموع المبيعات" : "Total Sales"}:{" "}
              {switchedLanguage ? "" : currency}{" "}
              {switchedLanguage
                ? Number(100000)?.toLocaleString("ar-EG", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                : Number(100000).toFixed(2)}{" "}
              {switchedLanguage ? currency : ""}
            </Typography>
            <Typography>
              {switchedLanguage ? "مجموع النفقات" : "Total Expense"}:{" "}
              {switchedLanguage ? "" : currency}
              {switchedLanguage
                ? Number(100000)?.toLocaleString("ar-EG", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                : Number(100000).toFixed(2)}{" "}
              {switchedLanguage ? currency : ""}
            </Typography>
          </Box>

          <Box sx={{ display: "flex", gap: 2 }}>
            <Button variant="contained" sx={{ bgcolor: "#F40000" }}>
              <DownloadIcon /> &nbsp;{" "}
              {switchedLanguage ? "تحميل البيانات" : "Download"}
            </Button>
            <Button variant="contained" sx={{ bgcolor: "#4D575D" }}>
              <PrintIcon /> &nbsp; {switchedLanguage ? "طباعة" : "Print"}
            </Button>
          </Box>
        </Box>
      </div>
    </>
  );
}
