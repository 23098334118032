import React, { useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import TableStyles from "./TableStyles";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import PlaceOrderModal from "./PlaceOrderModalPopup";
import ReserveTableModal from "./ReserveTableModalPopup";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import { getAllTableAreas, getReservedTables } from "../../service/admin";
import ReservedTablesModal from "./ReservedTablesModal";

export default function TableContainer() {
  const { biller,switchedLanguage  } = useSelector((state) => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedTables, setSelectedTables] = useState([]);
  const [placeOrderBtn, setPlaceOrderBtn] = useState(false);
  const [reserveTableBtn, setReserveTableBtn] = useState(false);
  const [guests, setGuests] = useState(1);

  const [tableReservation, setTableReservation] = useState({
    reservedDate: null,
    reservedTime: null,
    bufferTime: null,
    name: null,
    mobile: null,
  });

  // const [reservedTables, setReservedTables] = useState([]);
  const [reservedTableBtn, setReservedTableBtn] = useState(false);

  const [allArea, setAllArea] = useState([]);
  const [query, setQuery] = useState("All");

  useEffect(() => {
    getArea();
  }, []);

  // useEffect(() => {
  //   allReservedTables();
  // }, [reservedTables]);

  const getArea = async () => {
    const areas = await getAllTableAreas(biller.token);
    setAllArea(areas);
  };

  // const allReservedTables = async () => {
  //   let res = await getReservedTables(biller.token);
  //   setReservedTables(res)
  //   //  console.log(res, "reservedConia");
  // };

  const newTables = new Map();
  const handleOpenPlaceOrderModal = () => setPlaceOrderBtn(true);
  const handleClosePlaceOrderModal = () => {
    setGuests(1);
    setPlaceOrderBtn(false);
  };

  const handleOpenReservedTableModal = () => setReservedTableBtn(true);
  const handleCloseReservedTableModal = () => {
    setReservedTableBtn(false);
  };

  const handleOpenReserveTableModal = () => {
    setReserveTableBtn(true);
  };

  const handleCloseReserveTableModal = () => {
    setTableReservation({
      reservedDate: null,
      reservedTime: null,
      bufferTime: null,
      name: null,
      mobile: null,
    });
    setGuests(1);
    setReserveTableBtn(false);
  };

  const handleDeliveryBtn = () => {
    navigate("/billing");
    dispatch({ type: "clickedOrderTypeActiveBtn", payload: "Delivery" });
  };

  const handlePickupBtn = () => {
    navigate("/billing");
    dispatch({ type: "clickedOrderTypeActiveBtn", payload: "Pickup" });
  };

  const handleTableClick = (table) => {
    if (table && !table._id) {
      return; // if table doesn't have an _id property, don't add it
    }

    const tableExists = selectedTables.some((t) => t._id === table._id);
    if (!tableExists) {
      setSelectedTables((prevTables) => [...prevTables, table]);
    }
  };

  const handleTableDelete = (tbl) => {
    const updatedTables = selectedTables.filter((item) => item._id !== tbl._id);
    setSelectedTables(updatedTables);
  };

  const handlePlaceOrderBtn = () => {
    if (!selectedTables.length) {
      toast.error(switchedLanguage ? "الرجاء اختيار طاولة": "Please select a table!");
    } else {
      setPlaceOrderBtn(true);
      handleOpenPlaceOrderModal();
    }
  };

  const handleReserveTableBtn = () => {
    // if (!selectedTables.length) {
    //   toast.error("Please select a table!");
    // } else {
    //   setReserveTableBtn(true);
    //   handleOpenReserveTableModal();
    // }

    setReserveTableBtn(true);
    handleOpenReserveTableModal();
  };

  const handleSetReservedDate = (date) => {
    const formattedDate = date ? dayjs(date).format("D MMM YY") : null;
    setTableReservation({ ...tableReservation, reservedDate: formattedDate });
  };

  const handleSetReservedTime = (time) => {
    const bufferBefore = 60; // Buffer time before reserved time in minutes
    const bufferAfter = 59; // Buffer time after reserved time in minutes

    const formattedTime = time ? dayjs(time).format("h:mm A") : null;

    const bufferStartTime = time
      ? dayjs(time).subtract(bufferBefore, "minutes").format("h:mm A")
      : null;

    // Calculate buffer end time by adding bufferAfter minutes to the formatted time
    const bufferEndTime = time
      ? dayjs(time).add(bufferAfter, "minutes").format("h:mm A")
      : null;

    setTableReservation({
      ...tableReservation,
      reservedTime: formattedTime,
      bufferTime: `${bufferStartTime} - ${bufferEndTime}`,
    });
  };

  const handlePlaceOrderConfirm = () => {
    dispatch({ type: "table", payload: selectedTables });
    dispatch({ type: "clickedOrderTypeActiveBtn", payload: "Dine in" });
    dispatch({ type: "noOfGuests", payload: guests });
    handleClosePlaceOrderModal();
    setSelectedTables([]);
    navigate("/billing");
  };

  // Function to convert a number to Arabic numerals
  function toArabicNumerals(number) {
    const arabicNumerals = ["٠", "١", "٢", "٣", "٤", "٥", "٦", "٧", "٨", "٩"];
    return String(number).replace(/\d/g, (digit) => arabicNumerals[digit]);
  }


  return (
    <>
      {reservedTableBtn && (
        <ReservedTablesModal
          handleOpenReservedTableModal={handleOpenReservedTableModal}
          handleCloseReservedTableModal={handleCloseReservedTableModal}
        />
      )}
      <Box
        component="section"
        sx={{
          background: "#F5F5F5",
          px: 2,
          paddingBottom: 2,
          height: "93vh",
          width: "100vw",
          position: "relative",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        {placeOrderBtn && (
          <PlaceOrderModal
            handleOpenPlaceOrderModal={handleOpenPlaceOrderModal}
            handleClosePlaceOrderModal={handleClosePlaceOrderModal}
            selectedTables={selectedTables}
            guests={guests}
            setGuests={setGuests}
            handlePlaceOrderConfirm={handlePlaceOrderConfirm}
          />
        )}

        {reserveTableBtn && (
          <ReserveTableModal
            handleOpenReserveTableModal={handleOpenReserveTableModal}
            handleCloseReserveTableModal={handleCloseReserveTableModal}
            guests={guests}
            setGuests={setGuests}
            handleSetReservedDate={handleSetReservedDate}
            handleSetReservedTime={handleSetReservedTime}
            tableReservation={tableReservation}
            reservedTableBtn={reservedTableBtn}
          />
        )}
        <Box
          className="table-stat-bar"
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          <Box
            className="table-stat-colour"
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Box
              sx={{
                border: "2px solid #fff",
                bgcolor: "#D9D9D9",
                height: "15px",
                width: "15px",
                borderRadius: "50%",
              }}
            ></Box>
            <Typography sx={{ fontFamily: "poppins" }}>{switchedLanguage ? "متوفر": "Available"}</Typography>
            <Box
              sx={{
                border: "2px solid #fff",
                bgcolor: "#0095F7",
                height: "15px",
                width: "15px",
                borderRadius: "50%",
              }}
            ></Box>
            <Typography sx={{ fontFamily: "poppins" }}>{switchedLanguage ? "المحتلة": "Occupied"}</Typography>
            <Box
              sx={{
                border: "2px solid #fff",
                bgcolor: "#F8AF2A",
                height: "15px",
                width: "15px",
                borderRadius: "50%",
              }}
            ></Box>
            <Typography sx={{ fontFamily: "poppins" }}>{switchedLanguage ? "الحجز": "Reserved"}</Typography>
            {/* <Box
              sx={{
                border: "2px solid #fff",
                bgcolor: "#2ABC7A",
                height: "15px",
                width: "15px",
                borderRadius: "50%",
              }}
            ></Box>
            <Typography sx={{ fontFamily: "poppins" }}>Delivered</Typography> */}
          </Box>
          <Box
            className="pickup-delivery-btn"
            component="div"
            sx={{
              p: 2,
              display: "flex",
              gap: "10px",
            }}
          >
            <Button
              onClick={handlePickupBtn}
              variant="contained"
              sx={{
                width: "130px",
                height: "42px",
                textTransform: "none",
                color: "var(--font-white)",
                backgroundColor: "var(--red)",
                "&:hover": {
                  backgroundColor: "var(--red)",
                },
                fontFamily: "poppins",
                cursor: "pointer",
                borderRadius: "10px",
              }}
            >
              {switchedLanguage ? "أمر التقاط": "Pickup"}
            </Button>
            <Button
              onClick={handleDeliveryBtn}
              variant="contained"
              sx={{
                width: "130px",
                height: "42px",
                textTransform: "none",
                color: "var(--font-white)",
                backgroundColor: "var(--red)",
                "&:hover": {
                  backgroundColor: "var(--red)",
                },
                fontFamily: "poppins",
                cursor: "pointer",
                borderRadius: "10px",
              }}
            >
              {switchedLanguage ? "التسليم": "Delivery"}
            </Button>
          </Box>
        </Box>

        <TableStyles
          handleTableClick={handleTableClick}
          query={query}
          reserveTableBtn={reserveTableBtn}
          reservedTableBtn={reservedTableBtn}
        />

        <Box
          className="table-footer"
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            component="div"
            sx={{
              display: "flex",
              gap: "10px",
              py: 2,
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ display: "flex", gap: "10px" }}>
              <Button
                onClick={() => setQuery("All")}
                variant="outlined"
                sx={{
                  textTransform: "none",
                  color: query === "All" ? "red" : "var(--border-grey)",
                  borderColor: query === "All" ? "red" : "var(--border-grey)",
                  backgroundColor: "var(--font-white)",
                  "&:hover": {
                    backgroundColor: "var(--font-white)",
                    borderColor: query === "All" ? "red" : "var(--border-grey)",
                  },
                  fontFamily: "poppins",
                  cursor: "pointer",
                  borderRadius: "10px",
                  width: "120px",
                  height: "42px",
                }}
              >
                {switchedLanguage ? "الكل": "All"}
              </Button>

              {allArea?.map((item, index) => (
                <Button
                  onClick={() => setQuery(item.areaName)}
                  variant="outlined"
                  sx={{
                    textTransform: "none",
                    color:
                      query === item.areaName ? "red" : "var(--border-grey)",
                    borderColor:
                      query === item.areaName ? "red" : "var(--border-grey)",
                    backgroundColor: "var(--font-white)",
                    "&:hover": {
                      backgroundColor: "var(--font-white)",
                      borderColor:
                        query === item.areaName ? "red" : "var(--border-grey)",
                    },
                    fontFamily: "poppins",
                    cursor: "pointer",
                    borderRadius: "10px",
                    height: "42px",
                  }}
                >
                  {item.areaName}
                </Button>
              ))}
            </Box>
            <Box>
              <Button
                onClick={handleOpenReservedTableModal}
                variant="outlined"
                sx={{
                  textTransform: "none",
                  color: "var(--border-grey)",
                  borderColor: "var(--border-grey)",
                  backgroundColor: "var(--font-white)",
                  "&:hover": {
                    backgroundColor: "var(--font-white)",
                    borderColor: "var(--border-grey)",
                  },
                  fontFamily: "poppins",
                  cursor: "pointer",
                  borderRadius: "10px",
                  width: "140px",
                  height: "42px",
                  whiteSpace: "nowrap",
                }}
              >
                {switchedLanguage ? "الجداول المحجوزة": "Reserved Tables"}
              </Button>
            </Box>
          </Box>
          <Box
            component="section"
            sx={{
              background: "#FFF",
              display: "flex",
              gap: "10px",
              justifyContent: "space-between",
              borderRadius: "10px",
            }}
          >
            <Box
              component="section"
              sx={{
                background: "#FFF",
                p: 2,
                display: "flex",
                gap: "10px",
                borderRadius: "10px",
                alignItems: "center",
              }}
            >
              <Box>
                <Typography sx={{ fontFamily: "poppins", fontWeight: "bold" }}>
                {switchedLanguage ? "الجداول": "Tables"}
                </Typography>
                {/* <Typography sx={{ fontSize: "13px" }}>
                  Order #12344556
                </Typography> */}
              </Box>

              {selectedTables.map((tbl) => {
                return (
                  <>
                    <Box sx={{ position: "relative" }}>
                      <Button
                        variant="outlined"
                        sx={{
                          textTransform: "none",
                          color: "#0095F7",
                          borderColor: "#AFAFAF",
                          backgroundColor: "var(--font-white)",
                          "&:hover": {
                            backgroundColor: "var(--font-white)",
                            borderColor: "#F5F5F5",
                          },
                          fontFamily: "poppins",
                          cursor: "pointer",
                          borderRadius: "11px",
                        }}
                      >
                        T-{switchedLanguage ? toArabicNumerals(tbl.tableNo):  tbl.tableNo}
                      </Button>
                      <Box
                        variant="div"
                        sx={{
                          backgroundColor: "red",
                          borderRadius: "50%",
                          position: "absolute",
                          top: -5,
                          right: -3,
                          width: "20px",
                          height: "20px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          cursor: "pointer",
                          borderRadius: "11px",
                        }}
                      >
                        <Typography
                          onClick={() => handleTableDelete(tbl)}
                          sx={{ color: "#fff" }}
                        >
                          x
                        </Typography>
                      </Box>
                    </Box>
                  </>
                );
              })}
            </Box>
            <Box sx={{ p: 2, display: "flex", gap: "10px" }}>
              <Button
                onClick={handleReserveTableBtn}
                variant="outlined"
                sx={{
                  height: "43px",
                  width: "135px",
                  textTransform: "none",
                  color: "var(--font-black)",
                  borderColor: "#AFAFAF",
                  backgroundColor: "var(--font-white)",
                  "&:hover": {
                    backgroundColor: "var(--font-white)",
                    // borderColor: "#F5F5F5",
                    borderColor: "#AFAFAF",
                  },
                  fontFamily: "poppins",
                  cursor: "pointer",
                  borderRadius: "11px",
                }}
              >
                {switchedLanguage ? "الاحتياطي": "Reserve"}
              </Button>
              <Button
                onClick={handlePlaceOrderBtn}
                variant="contained"
                sx={{
                  boxShadow: "none",
                  width: "135px",
                  height: "43px",
                  textTransform: "none",
                  color: "var(--font-white)",
                  backgroundColor: "var(--red)",
                  "&:hover": {
                    backgroundColor: "var(--red)",
                    boxShadow: "none",
                  },
                  fontFamily: "poppins",
                  cursor: "pointer",
                  borderRadius: "11px",
                }}
              >
                {switchedLanguage ? "جعل النظام": "Place Order"}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}
