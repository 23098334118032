import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Box, Grid } from "@mui/material";
import KotCard from "./KotCard";
import { getAllKotProducts } from "../../service/kot";
export default function AllKotOrders({ type, query, keys }) {
  const { biller } = useSelector((state) => state);

  const [allkotProducts, setAllkotProducts] = useState([]);

  useEffect(() => {
    fetchAllkots();
  }, []);

  const fetchAllkots = async () => {
    const data = await getAllKotProducts(biller.token);
 
    setAllkotProducts(data);
  };
  
  return (
    <div>
      <Box sx={{ px: "20px" }}>
        <Box>
          <Grid
            container
            sx={{
              display: "flex",
              // gap: "10px",
              flexWrap: "wrap",
              alignItems: "start",
              alignContent:'start',
              // justifyContent:'space-around'
            }}
          >
            {allkotProducts
              ?.filter((item) =>
                keys.some((key) => item[key]?.toLowerCase()?.includes(query))
              )
              ?.map((item, index) => (
                <Box>
                  {type === "All" ? (
                    <KotCard item={item} fetchAllkots={fetchAllkots} />
                  ) : (
                    item.typeofOrder == type && (
                      <KotCard item={item} fetchAllkots={fetchAllkots} />
                    )
                  )}
                </Box>
                // </Grid>
              ))}
          </Grid>
        </Box>
      </Box>
    </div>
  );
}
