import React, { useEffect, useState, useRef } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import DeleteIcon from "../../assets/DeletePngIcon.png";
import EditIcon from "../../assets/EditPngIcon.png";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import dayjs from "dayjs";
import "dayjs/locale/ar"; // Import the Arabic locale for dayjs

import { Modal, Paper } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteReservationDetails,
  getReservedTables,
} from "../../service/admin";
import { toast } from "react-toastify";

function ReservedTablesModal({
  handleOpenReservedTableModal,
  handleCloseReservedTableModal,
}) {
  const { biller, switchedLanguage } = useSelector((state) => state);
  const [reservedTables, setReservedTables] = useState([]);
  const [filterReservedTables, setFilterReservedTables] = useState([]);
  const [searchDate, setSearchDate] = useState("");

  useEffect(() => {
    allReservedTables();
  }, []);

  const allReservedTables = async () => {
    let res = await getReservedTables(biller.token);
    setReservedTables(res);
    setFilterReservedTables(res);
    //  console.log(res, "reservedConia");
  };

  const handleDelete = async (reservationId) => {
    const res = await deleteReservationDetails(biller.token, reservationId);

    if (res.status) {
      allReservedTables();
      switchedLanguage ? toast.error("حذفت تفاصيل الحجز بنجاح") : toast.error(res.message)
      ;
    }
  };

  // const handleEdit=(reservedData)=>{
  //   console.log(reservedData,"")
  //   handleOpenReserveTableModal(reservedData)
  // }

  const handleSearchReservedData = (date) => {
    const formattedDate = date ? dayjs(date).format("D MMM YY") : null;
    const filteredData = reservedTables.filter(
      (item) => item.reservedDate === formattedDate
    );
    setFilterReservedTables(filteredData);
    setSearchDate(formattedDate);
  };

  const handleResetSearch = () => {
    setFilterReservedTables(reservedTables);
    setSearchDate("");
  };

  // Function to convert a number to Arabic numerals
  function toArabicNumerals(number) {
    const arabicNumerals = ["٠", "١", "٢", "٣", "٤", "٥", "٦", "٧", "٨", "٩"];
    return String(number).replace(/\d/g, (digit) => arabicNumerals[digit]);
  }

  const convertToArabicNumerals = (number) => {
    const arabicNumerals = ["٠", "١", "٢", "٣", "٤", "٥", "٦", "٧", "٨", "٩"];
    return number
      .toString()
      .replace(/\d/g, (match) => arabicNumerals[parseInt(match)]);
  };

  const formatDateToArabic = (dateString) => {
    if (dateString) {
      const formattedDate = dayjs(dateString)
        .locale("ar")
        .format("D MMMM YYYY");
      return formattedDate.replace(/\d/g, (match) =>
        convertToArabicNumerals(match)
      );
    } else {
      return null;
    }
  };

  const formatTimeToArabic = (timeString) => {
    if (timeString) {
      const [hours, minutesPart] = timeString.split(":");
      const minutes = minutesPart.split(" ")[0];

      let formattedTime = `${convertToArabicNumerals(
        hours
      )}:${convertToArabicNumerals(minutes)}`;

      if (timeString.toLowerCase().includes("pm")) {
        formattedTime += " مساءً";
      } else {
        formattedTime += " صباحاً";
      }

      return formattedTime;
    } else {
      return null;
    }
  };

  return (
    <>
      <Modal
        open={handleOpenReservedTableModal}
        onClose={handleCloseReservedTableModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Paper sx={{ border: "none" }}>
          <Box
            sx={{
              position: "absolute",
              right: "0",
              bottom: 0,
              height: "93%",
              backgroundColor: "#F2F3F8",
              width: "25em",
              borderRadius: "5px",
              overflow: "scroll",
              "&::-webkit-scrollbar": {
                display: "none", // Hide scrollbar
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "poppins",
                  fontSize: "19px",
                  padding: "15px",
                  //   fontWeight:"600"
                }}
              >
                {switchedLanguage ? "الجداول المحجوزة" : "Reserved Tables"}
              </Typography>
            </Box>
            {/* DIV--1 */}
            <Box
              sx={{
                display: "flex",
                gap: "10px",
                px: "15px",
                alignItems: "center",
              }}
            >
              <Box sx={{ fontFamily: "poppins", width: "50%" }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                    value={searchDate}
                    onChange={handleSearchReservedData}
                    sx={{
                      width: "100%",
                      fontFamily: "poppins",
                      borderRadius: "18px",
                      backgroundColor: "#F2F3F7",
                      border: "1px solid #F0F0F0",
                      outline: "none",
                      height: "45px",
                      display: "flex",
                      justifyContent: "center",
                      cursor: "pointer",
                      padding: "0",
                      scrollBehavior: "none",
                      "&:focus": {
                        border: "1px solid #F0F0F0",
                      },
                      ".MuiOutlinedInput-input": {
                        fontFamily: "poppins",
                        height: "9px",
                        borderRadius: "18px",
                      },
                      ".MuiInputBase-root": {
                        fontFamily: "poppins",
                        borderRadius: "18px",
                        "&.MuiInputBase-colorPrimary": {
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            border: "1px solid #F0F0F0",
                          },
                        },
                      },
                      ".MuiIconButton-root": {
                        color: "red",
                      },
                    }}
                    renderInput={(props) => (
                      <input
                      // {...props}
                      // value={tableReservation.reservedDate || ""}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Box>
              <Box sx={{ fontFamily: "poppins" }}>
                <button
                  onClick={handleResetSearch}
                  style={{
                    borderRadius: "18px",
                    border: "1px solid var(--red)",
                    height: "40px",
                    width: "80px",
                    cursor: "pointer",
                    fontFamily: "poppins",
                    fontSize: "16px",
                    //  backgroundColor:"var(--red)",
                    //  color: "var(--font-white)",
                    backgroundColor: "#FFFFFF",
                    color: "var(--red)",
                  }}
                >
                  {switchedLanguage ? "إعادة تعيين" : "Reset"}
                </button>
              </Box>
            </Box>

            {/* DIV--2*/}

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                marginTop: "15px",
                gap: "10px",
                marginBottom: "15px",
              }}
            >
              {filterReservedTables?.map((reservedTbl, index) => {
                return (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: "92%",
                      height: "140px",
                      margin: "auto",
                      backgroundColor: "#FFFFFF",
                      justifyContent: "space-between",
                      px: "15px",
                      borderRadius: "8px",
                      py: "10px",
                    }}
                  >
                    <Box
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <Box
                        sx={{
                          height: "38px",
                          width: "45%",
                          border: "1px solid #F40000",
                          borderRadius: "8px",
                          color: "#F40000",
                          backgroundColor: "rgba(255, 45, 45, 0.2)",
                          fontFamily: "poppins",
                          display: "flex",
                          alignItems: "center",
                          padding: "10px",
                          overflowX: "auto",
                          overflowY: "hidden",
                          whiteSpace: "nowrap",
                          "&::-webkit-scrollbar": {
                            display: "none", // Hide scrollbar
                          },
                          fontSize: "15px",
                        }}
                      >
                        {switchedLanguage ? "الجدول" : "Table"} :{" "}
                        {reservedTbl?.tables.map((tbl, index) => (
                          <React.Fragment key={tbl?.table?._id}>
                            {switchedLanguage
                              ? toArabicNumerals(tbl?.table?.tableNo)
                              : tbl?.table?.tableNo}
                            {index !== reservedTbl?.tables.length - 1 && ", "}
                          </React.Fragment>
                        ))}
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-end",
                        }}
                      >
                        <Typography
                          sx={{
                            fontFamily: "poppins",
                            fontWeight: "600",
                            fontSize: "15px",
                          }}
                        >
                          {switchedLanguage
                            ? formatDateToArabic(reservedTbl.reservedDate)
                            : reservedTbl.reservedDate}
                        </Typography>
                        <Typography
                          sx={{ fontFamily: "poppins", fontSize: "15px" }}
                        >
                          {switchedLanguage
                            ? formatTimeToArabic(reservedTbl.reservedTime)
                            : reservedTbl.reservedTime}
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Box>
                        <Typography
                          sx={{ fontFamily: "poppins", fontSize: "15px" }}
                        >
                          {reservedTbl.name}
                        </Typography>
                        <Typography
                          sx={{ fontFamily: "poppins", fontSize: "15px" }}
                        >
                          {switchedLanguage? convertToArabicNumerals(reservedTbl.mobile) :reservedTbl.mobile}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          gap: "25px",
                        }}
                      >
                        {/* <img
                        onClick={() => handleEdit(reservedTbl)}
                          style={{ cursor: "pointer" }}
                          src={EditIcon}
                          alt="Edit icon"
                        /> */}
                        <img
                          onClick={() => handleDelete(reservedTbl._id)}
                          style={{ cursor: "pointer" }}
                          src={DeleteIcon}
                          alt="Delete icon"
                        />
                      </Box>
                    </Box>
                  </Box>
                );
              })}
            </Box>
          </Box>
        </Paper>
      </Modal>
    </>
  );
}

export default ReservedTablesModal;
