import Cookies from "js-cookie";

export function BillerReducer(
  state = Cookies.get("biller") ? JSON.parse(Cookies.get("biller")) : null,
  action
) {
  switch (action.type) {
    case "LOGIN":
      return action.payload;

    case "LOGOUT":
      return null;

    default:
      return state;
  }
}
