import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Typography,
  IconButton,
  TextField,
  Collapse,
} from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import SearchIcon from "@mui/icons-material/Search";
import { getAllGroupWithProducts, getAllProducts } from "../../service/admin";
import { useSelector } from "react-redux";
import LoadingImage from "../../assets/loader.gif";
import { toast } from "react-toastify";

export default function BillingMenuSection({ handleOpenFoodVariPopup }) {
  const { biller, switchedLanguage } = useSelector((state) => state);
  const [showAll, setShowAll] = useState(false);
  const [lessButton, setLessButton] = useState(false);
  const [allGroups, setAllGroups] = useState([]);
  const [allProducts, setAllProducts] = useState([]);
  const [productWithGroups, setProductWithGroups] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [activeGroupBtn, setActiveGroupBtn] = useState("All");

  const [isLoading, setIsLoading] = useState(true);
  const [networkError, setNetworkError] = useState(true);

  const ACCESS_TOKEN = biller.token;
  const containerRef = useRef(null);

  const [query, setQuery] = useState("");
  const keys = ["title"];

  // Currency
  const currency =
  switchedLanguage && biller.vendor.country == "UAE"
    ? "الدرهم"
    : switchedLanguage && biller.vendor.country == "KSA"
    ? "ريال"
    : !switchedLanguage && biller.vendor.country == "IND" ? "₹" : "";

  useEffect(() => {
    fetchGroupsByUser();

    const delay = setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    return () => {
      clearTimeout(delay);
    };
  }, []);

  useEffect(() => {
    let interval;

    if (!navigator.onLine) {
      interval = setInterval(() => {
        toast.error(
          switchedLanguage ? "الشبكة غير متوفرة" : "Network not available!"
        );
      }, 6000);
    }

    return () => {
      clearInterval(interval);
    };
  }, []);

  const fetchGroupsByUser = async () => {
    let id = biller.vendor._id;
    const res = await getAllGroupWithProducts(ACCESS_TOKEN, id);
    const allGroups = res.map((item) => {
      return item.groupName;
    });
    setAllGroups(allGroups);

    let getProducts = res?.map((products, index) => {
      let allProducts = products?.products?.map((varients) => {
        return varients;
      });
      return allProducts;
    });
    let allElements = getProducts.flat().flat();
    setAllProducts(allElements);
    setFilteredProducts(allElements);

    let getProductWithGroups = res?.map((items) => {
      return items;
    });
    setProductWithGroups(getProductWithGroups);

    setIsLoading(false);
  };

  //Changing groups btn as per click
  const handleGroupBtn = (btn) => {
    setActiveGroupBtn(btn);
    if (btn === "All") {
      setFilteredProducts(allProducts);
    } else {
      const res = productWithGroups.filter((item) => {
        let formattedName = item.groupName
          .toLowerCase()
          .replace(/\b\w/g, (c) => c.toUpperCase());
        if (formattedName === btn) {
          let varients = item.products.map((varient) => {
            return varient;
          });
          setFilteredProducts(varients);
        }
      });
    }
  };

  const handleMoreButtonClick = () => {
    if (switchedLanguage) {
      containerRef.current.scrollLeft -= 200; // Scroll container by 100 pixels
    } else {
      containerRef.current.scrollLeft += 200; // Scroll container by 100 pixels
    }
  };

  const handleScroll = () => {
    const { scrollLeft, scrollWidth, clientWidth } = containerRef.current;

    if (switchedLanguage) {
      if (scrollLeft < 0 && scrollWidth > clientWidth) {
        setLessButton(true);
      } else {
        setLessButton(false);
      }
    } else {
      if (scrollLeft > 0 && scrollWidth > clientWidth) {
        setLessButton(true);
      } else {
        setLessButton(false);
      }
    }
  };
  const handleLessButtonClick = () => {
    // containerRef.current.scrollLeft -= 200; // Scroll container by 100 pixels
    if (switchedLanguage) {
      containerRef.current.scrollLeft += 200; // Scroll container by 100 pixels
    } else {
      containerRef.current.scrollLeft -= 200; // Scroll container by 100 pixels
    }
  };

  const [expanded, setExpanded] = useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleOnMouseLeave = () => {
    setExpanded(false);
  };

  const containerStyle = {
    position: "fixed",
    bottom: "0",
    right: switchedLanguage ? 0 : "",
    left: switchedLanguage ? "" : 0,
    marginRight:switchedLanguage ? 30 : "",
    marginBottom: 30,
    marginLeft: 30,
    zIndex: 1,
    height: expanded ? "60px" : "40px",
    width: expanded ? "500px" : "40px",
    borderRadius: expanded ? "8px" : "50%",
    transition: "margin-left 0.9s ease, opacity 0.9s ease",
    backgroundColor: expanded ? "#FFFFFF" : "rgba(0, 0, 0, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #ccc",
  };

  const textFieldStyle = {
    flexGrow: 1,
    marginLeft: expanded ? "10px" : "0",
    opacity: expanded ? 1 : 0,
    transition: "margin-left 0.9s ease, opacity 0.9s ease",
    borderRadius: "4px",
    width: "400px",
    backgroundColor: "#FFFFFF",
  };

  return (
    <>
      <Box
        component="section"
        sx={{
          background: "#F5F5F5",
          padding: 2,
          height: "92vh",
          width: "70vw",
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: "10px",
            width: "100%",
          }}
        >
          {lessButton && (
            <Box>
              <Button
                variant="contained"
                onClick={handleLessButtonClick}
                sx={{
                  height: "70px",
                  textTransform: "none",
                  color: "var(--font-white)",
                  backgroundColor: "#4D575D",
                  "&:hover": {
                    backgroundColor: "#4D575D",
                  },
                  fontFamily: "poppins",
                  cursor: "pointer",
                  flex: 1,
                  borderRadius: "10px",
                  fontSize: "18px",
                }}
              >
                <KeyboardArrowLeftIcon />
              </Button>
            </Box>
          )}
          <Box
            component="div"
            onScroll={handleScroll}
            ref={containerRef}
            sx={{
              display: "flex",
              gap: "10px",
              // maxWidth: "68vw",
              overflowX: "scroll",
              "&::-webkit-scrollbar": {
                display: "none", // Hide scrollbar
              },
              width: "90%",
              paddingBottom: "10px",
            }}
          >
            <Button
              onClick={() => handleGroupBtn("All")}
              variant="contained"
              sx={{
                boxShadow: "none",
                textTransform: "none",
                color:
                  activeGroupBtn === "All"
                    ? "var(--font-white)"
                    : "var(--font-black)",
                backgroundColor:
                  activeGroupBtn === "All" ? "var(--red)" : "var(--bg-white)",
                "&:hover": {
                  backgroundColor:
                    activeGroupBtn === "All" ? "var(--red)" : "var(--bg-white)",
                  boxShadow: "none",
                },
                fontFamily: "poppins",
                cursor: "pointer",
                minWidth: "170px",
                borderRadius: "10px",
                fontSize: "18px",
              }}
            >
              {switchedLanguage ? "الكل" : "All"}
            </Button>
            {allGroups?.map((category, index) => (
              <Button
                onClick={() =>
                  handleGroupBtn(
                    category
                      .toLowerCase()
                      .replace(/\b\w/g, (c) => c.toUpperCase())
                  )
                }
                key={index}
                variant="contained"
                sx={{
                  fontSize: "18px",
                  boxShadow: "none",
                  textTransform: "none",
                  color:
                    activeGroupBtn ===
                    category
                      .toLowerCase()
                      .replace(/\b\w/g, (c) => c.toUpperCase())
                      ? "var(--font-white)"
                      : "var(--font-black)",
                  backgroundColor:
                    activeGroupBtn ===
                    category
                      .toLowerCase()
                      .replace(/\b\w/g, (c) => c.toUpperCase())
                      ? "var(--red)"
                      : "var(--bg-white)",
                  "&:hover": {
                    boxShadow: "none",
                    backgroundColor:
                      activeGroupBtn ===
                      category
                        .toLowerCase()
                        .replace(/\b\w/g, (c) => c.toUpperCase())
                        ? "var(--red)"
                        : "var(--bg-white)",
                  },
                  fontFamily: "poppins",
                  cursor: "pointer",
                  minWidth: "170px",
                  height: "70px",
                  //   flex: 1,
                  borderRadius: "10px",
                }}
              >
                {category
                  .toLowerCase()
                  .replace(/\b\w/g, (c) => c.toUpperCase())}
              </Button>
            ))}
          </Box>
          <Box>
            <Button
              variant="contained"
              onClick={handleMoreButtonClick}
              sx={{
                height: "70px",
                boxShadow: "none",
                textTransform: "none",
                color: "var(--font-white)",
                backgroundColor: "#4D575D",
                "&:hover": {
                  boxShadow: "none",
                  backgroundColor: "#4D575D",
                },
                fontFamily: "poppins",
                cursor: "pointer",
                // flex: 1,
                borderRadius: "10px",
                fontSize: "18px",
              }}
            >
              {showAll
                ? switchedLanguage
                  ? "أقل"
                  : "Less"
                : switchedLanguage
                ? "المزيد"
                : "More"}
              {showAll ? <KeyboardArrowLeftIcon /> : <KeyboardArrowRightIcon />}
            </Button>
          </Box>
        </Box>

        <Box
          sx={{
            padding: "5px",
            height: "80vh",
            paddingTop: "15px",
            display: "flex",
            gap: "10px",
            flexWrap: "wrap",
            position: "relative",
            overflowX: "auto",
            alignContent: "flex-start",
            "&::-webkit-scrollbar": {
              display: "none", // Hide scrollbar
            },
            justifyContent: "flex-start",
          }}
        >
          {isLoading && navigator.onLine ? (
            <Box
              sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                style={{ height: "300px" }}
                src={LoadingImage}
                alt="Loading"
              />
            </Box>
          ) : (
            filteredProducts
              ?.filter((item) =>
                keys.some((key) => item[key]?.toLowerCase()?.includes(query))
              )
              .map((food) => (
                <Card
                  onClick={() => {
                    handleOpenFoodVariPopup(food.item ? food.item : food);
                  }}
                  sx={{
                    // minWidth: "200px",
                    // flex: 1,
                    borderRadius: "16px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    height: "170px",
                    width: "24%",
                    // width:"200px"
                    cursor: "pointer",
                  }}
                >
                  <CardContent>
                    <Typography
                      sx={{ fontFamily: "poppins", fontSize: "16px" }}
                    >
                      {food.title}
                    </Typography>
                    <Typography
                      sx={{
                        color: "#AFAFAF",
                        fontSize: "small",
                        fontFamily: "poppins",
                      }}
                    >
                      {switchedLanguage ? "البنود البديلة" : "Varients: "}
                      <Typography
                        component="span"
                        sx={{ fontFamily: "poppins" }}
                      >
                        {switchedLanguage
                          ? `عناصر مختلفة: ${food.varients?.length.toLocaleString(
                              "ar-EG"
                            )}`
                          : food.varients?.length}

                        {/* {food.varients?.length.toLocaleString('ar-EG')} {switchedLanguage ?  "عناصر مختلفة": ""} */}
                      </Typography>
                    </Typography>
                    <Typography
                      sx={{
                        color: "#AFAFAF",
                        fontSize: "small",
                        fontFamily: "poppins",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {switchedLanguage ? "" : "Available:"}&nbsp;
                      <Typography
                        component="span"
                        sx={{ fontFamily: "poppins", marginRight: "5px" }}
                      >
                        {switchedLanguage
                          ? `متوفر: ${
                              activeGroupBtn === "All"
                                ? food.varients[0]?.varientId?.stock.toLocaleString(
                                    "ar-EG"
                                  )
                                : food?.varients[0]?.varientId?.stock.toLocaleString(
                                    "ar-EG"
                                  )
                            }`
                          : activeGroupBtn === "All"
                          ? food.varients[0]?.varientId?.stock
                          : food?.varients[0]?.varientId?.stock}
                      </Typography>
                    </Typography>
                  </CardContent>
                  <Typography
                    sx={{ display: "flex", padding: "0px 15px 15px 15px" }}
                  >
                    <sup>{switchedLanguage ? "" : currency}</sup>
                    <Typography
                      component="span"
                      sx={{
                        fontSize: "23px",
                        fontFamily: "poppins",
                        marginLeft: "3px",
                      }}
                    >
                      {switchedLanguage
                        ? `${
                            activeGroupBtn === "All"
                              ? food.varients[0]?.varientId?.salePrice.toLocaleString(
                                  "ar-EG"
                                )
                              : food?.varients[0]?.varientId?.salePrice.toLocaleString(
                                  "ar-EG"
                                )
                          }`
                        : `${
                            activeGroupBtn === "All"
                              ? food.varients[0]?.varientId?.salePrice
                              : food?.varients[0]?.varientId?.salePrice
                          }.00`}
                    </Typography>
                    <sup>{switchedLanguage ? currency : ""}</sup>
                  </Typography>
                </Card>
              ))
          )}

          <Box
            variant="div"
            style={containerStyle}
            onMouseLeave={handleOnMouseLeave}
          >
            <IconButton onClick={handleExpandClick}>
              <SearchIcon
                style={{ color: expanded ? "#FFFFFF" : "#FFFFFF" }}
                sx={{
                  cursor: "pointer",
                }}
              />
            </IconButton>
            <Collapse in={expanded}>
              <TextField
                size="small"
                style={textFieldStyle}
                onChange={(e) => setQuery(e.target.value)}
                sx={{ border: "none", "& fieldset": { border: "none" } }}
              />
            </Collapse>
          </Box>
        </Box>
      </Box>
    </>
  );
}
