import React, { useState, useEffect } from "react";
import { Box, Button, Typography } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import TableRestaurantIcon from "@mui/icons-material/TableRestaurant";
import RamenDiningIcon from "@mui/icons-material/RamenDining";
import ChatIcon from "@mui/icons-material/Chat";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import DeleteIcon from "@mui/icons-material/Delete";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  cancelRecentOrderFromBilling,
  editRecentOrderFromBilling,
  getRazorpayKey,
  paymentGatewayDetails,
  placeNewOrderFromBilling,
  sendOrderToKot,
} from "../../service/admin";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { printInvoice, printKot } from "../../service/printServer";
import BillingSettlePopup from "./BillingSettlePopup";
import PleaseChooseProductPopup from "./PleaseChooseProduct";
import { Grading } from "@mui/icons-material";

export default function BillingOrderSection({
  items,
  setItems,
  products,
  handleOpenCommentModal,
  comments,
  setComments,
  handleOpenAssigneeModal,
  foodAssignerPopupValue,
  setFoodAssignerPopupValue,

  handleOpenSettleModal,
  handleCloseSettleModal,
  settlmentMode,
  handleSettlementMode,
  settleBtn,

  handleOpenUserModal,
  userData,
  setUserData,

  handleOpenTableModal,

  orderTypeActiveBtn,
  setOrderTypeActiveBtn,
}) {
  const {
    biller,
    clickedOrderTypeActiveBtn,
    selectedTable,
    recentOrderClickedItem,
    switchedLanguage,
  } = useSelector((state) => state);
  // console.log(biller);
  const ACCESS_TOKEN = biller.token;

  // Currency
  const currency =
    switchedLanguage && biller.vendor.country == "UAE"
      ? "الدرهم"
      : switchedLanguage && biller.vendor.country == "KSA"
      ? "ريال"
      : !switchedLanguage && biller.vendor.country == "IND"
      ? "₹"
      : "";

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [splitCount, setSplitCount] = useState(1);
  const [splittedAmount, setSplittedAmount] = useState(null);

  // const [orderTypeActiveBtn, setOrderTypeActiveBtn] = useState(
  //   clickedOrderTypeActiveBtn ? clickedOrderTypeActiveBtn : "Dine in"
  // );

  function calculateSubtotal(argItems, recentClickedItem) {
    const subTotal = argItems?.reduce((sum, item) => {
      const itemTotal = item.salePrice * item.quantity;
      // ? item.salePrice * item.quantity
      // : recentClickedItem[0]?.products
      // ? item.varientId.salePrice * item.quantity
      // : item.salePrice * item.quantity;
      const addonsTotal = item?.addOns?.reduce((acc, addon) => {
        return acc + addon.price * addon.quantity;
      }, 0);
      return sum + itemTotal + addonsTotal;
    }, 0);
    return subTotal;
  }
  const subTotal = calculateSubtotal(items, recentOrderClickedItem);

  // To calculate the grandTotal
  const [totalSum, setTotalSum] = useState({
    package: null,
    discount: null,
    tips: null,
    roundOff: null,
    gstAmount: null,
    gstPercentage: null,
  });
  const grandTotal =
    (subTotal || 0) +
    (totalSum.gstAmount || 0) +
    (totalSum.package || 0) +
    (totalSum.tips || 0) -
    (totalSum.discount || 0) -
    (totalSum.roundOff || 0);

  useEffect(() => {
    setTotalSum({
      ...totalSum,
      gstAmount:
        (subTotal - totalSum.discount || 0) *
        (biller.vendor.gstPercentage / 100),
    });
  }, [subTotal, totalSum.discount]);

  const [chooseProductPopup, setChooseProductPopup] = useState(false);
  const handleOpenChooseProduct = () => setChooseProductPopup(true);
  const handleCloseChooseProduct = () => setChooseProductPopup(false);

  useEffect(() => {
    if (recentOrderClickedItem.length !== 0) {
      setItems(recentOrderClickedItem[0]?.products);
      setOrderTypeActiveBtn(clickedOrderTypeActiveBtn);

      setTotalSum({
        package: recentOrderClickedItem[0]?.package,
        discount: recentOrderClickedItem[0]?.discountPrice,
        tips: recentOrderClickedItem[0]?.tips,
        roundOff: recentOrderClickedItem[0]?.roundOff,
      });
      setComments(recentOrderClickedItem[0]?.note);
      dispatch({
        type: "table",
        payload: recentOrderClickedItem[0]?.tablesArray,
      });
      setFoodAssignerPopupValue(recentOrderClickedItem[0]?.captain);

      setUserData({
        name: recentOrderClickedItem[0]?.deliveryDetails?.name,
        mobile: recentOrderClickedItem[0]?.deliveryDetails?.mobile,
        locality: recentOrderClickedItem[0]?.deliveryDetails?.locality,
        gstNum: recentOrderClickedItem[0]?.deliveryDetails?.gstNum,
      });
    }
  }, [clickedOrderTypeActiveBtn, recentOrderClickedItem]);

  useEffect(() => {
    if (
      !selectedTable.length &&
      orderTypeActiveBtn == "Dine in" &&
      (clickedOrderTypeActiveBtn === "Delivery" ||
        clickedOrderTypeActiveBtn === "Pickup")
    ) {
      navigate("/");
    }
  }, [orderTypeActiveBtn, clickedOrderTypeActiveBtn]);

  const tables = selectedTable?.map((item) => item.tableNo).join(",");
  const tableIds = selectedTable
    ?.map((item) => item._id)
    .filter((id) => id !== undefined && id !== null);

  const handleOrderTypeActiveBtn = (btn) => {
    setOrderTypeActiveBtn(btn);
  };

  const handleIncrement = (itemId, productType, product) => {
    if (productType === "addons") {
      const updatedItems = items.map((item) => {
        if (item.varientId === product.varientId) {
          const updatedAddons = item.addOns.map((addon) => {
            if (addon.valueId === itemId) {
              return {
                ...addon,
                quantity: addon.quantity + 1,
              };
            }
            return addon;
          });
          const subTotal = calcSubTotal(
            item.salePrice,
            item.quantity,
            updatedAddons
          );
          return {
            ...item,
            addOns: updatedAddons,
            subTotal: subTotal,
          };
        } else {
          return item;
        }
      });

      setItems(updatedItems);
    } else {
      const updatedItems = items.map((item) => {
        if (item.varientId === itemId) {
          const newQuantity = item.quantity + 1;
          const subTotal = calcSubTotal(
            item.salePrice,
            newQuantity,
            item.addOns
          );
          return {
            ...item,
            quantity: newQuantity,
            subTotal: subTotal,
          };
        }
        return item;
      });
      setItems(updatedItems);
    }
  };

  const calcSubTotal = (salePrice, quantity, addOns) => {
    let subTotal = salePrice * quantity;

    if (addOns.length > 0) {
      addOns.forEach((addOn) => {
        subTotal += addOn.price * addOn.quantity;
      });
    }

    return subTotal;
  };

  const handleDecrement = (itemId, productType, product) => {
    if (productType === "addons") {
      const updatedItems = items.map((item) => {
        if (item.varientId === product.varientId) {
          if (item.addOns) {
            const updatedAddOns = item.addOns
              .map((addOn) => {
                if (addOn.valueId === itemId) {
                  if (addOn.quantity <= 1) {
                    return null;
                  } else {
                    return {
                      ...addOn,
                      quantity: addOn.quantity - 1,
                    };
                  }
                } else {
                  return addOn;
                }
              })
              .filter(Boolean);

            // Get the index of the add-on to be updated
            const index = item.addOns.findIndex(
              (addOn) => addOn.valueId === itemId
            );

            // Calculate the new subTotal
            const updatedSubTotal = calcSubTotal(
              item.salePrice,
              item.quantity,
              updatedAddOns
            );

            return {
              ...item,
              addOns: updatedAddOns,
              subTotal: updatedSubTotal,
            };
          } else {
            return item;
          }
        } else {
          return item;
        }
      });

      setItems(updatedItems);
    } else {
      let deleted = false;
      const updatedItems = items
        .map((item) => {
          if (item.varientId === itemId) {
            if (item.quantity <= 1) {
              deleted = true;
              return null;
            } else {
              const newQuantity = item.quantity - 1;
              const updatedSubTotal = calcSubTotal(
                item.salePrice,
                newQuantity,
                item.addOns
              );
              return {
                ...item,
                quantity: newQuantity,
                subTotal: updatedSubTotal,
              };
            }
          } else {
            return item;
          }
        })
        .filter(Boolean);

      if (deleted) {
        setItems(updatedItems);
        toast.error(
          switchedLanguage ? "تم حذف المنتج" : "Product has been deleted!"
        );
      } else {
        setItems(updatedItems);
      }
    }
  };

  const handleDeleteListedItem = (itemId, productType, product) => {
    if (productType === "mainItem") {
      const updatedItems = items.filter((prdt) => prdt.varientId !== itemId);
      setItems(updatedItems);
      toast.error(
        switchedLanguage ? "تم حذف المنتج" : "Product has been deleted!"
      );
    } else {
      const updatedItems = items.map((item) => {
        if (item.varientId === product.varientId) {
          const updatedAddons = item.addOns.filter(
            (addon) => addon.valueId !== itemId
          );
          return {
            ...item,
            addOns: updatedAddons,
            subTotal:
              item.salePrice * item.quantity +
              updatedAddons.reduce((total, addon) => total + addon.price, 0),
          };
        } else {
          return item;
        }
      });
      setItems(updatedItems);
      toast.error(
        switchedLanguage ? "تم حذف عناصر إضافية" : "Addon has been deleted!"
      );
    }
  };

  // To handle reset button functionality
  const handleReset = () => {
    setTotalSum({
      package: "",
      discount: "",
      tips: "",
      roundOff: "",
      gstAmount: "",
    });

    setItems([]);
    dispatch({ type: "reset", payload: [] });
    dispatch({
      type: "resetOrderTypeActiveBtn",
      payload: clickedOrderTypeActiveBtn
        ? clickedOrderTypeActiveBtn
        : "Dine in",
    });

    setUserData({
      name: "",
      mobile: "",
      locality: "",
      gstNum: "",
    });
    navigate("/");
  };

  // To handle Save button functionality
  const placeNewPosOrder = async (status = "Delivered", razorPay) => {
    if (
      orderTypeActiveBtn === "Delivery" &&
      (userData.name === "" ||
        userData.mobile === "" ||
        userData.locality === "")
    ) {
      toast.error(
        switchedLanguage
          ? "الاسم، الجوال، حقول العنوان إلزامية"
          : "Name, Mobile, Address fields are mandatory!"
      );
      handleOpenUserModal();
    } else if (items.length <= 0) {
      handleOpenChooseProduct();
      //toast.error("Please choose a product!");
      //Show error
    } else {
      if (orderTypeActiveBtn === "Delivery") {
        status = "Ready";
      } else if (typeof status === "undefined") {
        status = "Delivered";
      }
      const data = {
        billerId: biller.id,
        orderStatus: status,
        typeofOrder: orderTypeActiveBtn,
        paymentMethod: settlmentMode,
        currentTime: new Date(),
        tablesArray: selectedTable,
        tableNo: recentOrderClickedItem[0]?.tableNo
          ? recentOrderClickedItem[0]?.tableNo
          : tables,
        tableIds: tableIds,
        discountPrice: totalSum.discount,
        package: totalSum.package,
        tips: totalSum.tips,
        productsSubTotal: Number(subTotal),
        grandTotal: grandTotal,
        splittedAmount:
          splittedAmount == grandTotal ? 0 : splittedAmount?.toFixed(2),
        roundOff: totalSum.roundOff,
        gstPercentage: biller.vendor.gstPercentage,
        gstAmount: totalSum.gstAmount?.toFixed(2),
        note: comments,
        vendorId: biller.vendor._id,
        products: items,
        captain: foodAssignerPopupValue,
        deliveryDetails: userData,
        paymentGateway: {
          isPaid: true,
          orderId: razorPay?.razorpay_order_id,
          paymentId: razorPay?.razorpay_payment_id,
          signature: razorPay?.razorpay_signature,
        },
      };

      let res = await placeNewOrderFromBilling(data, ACCESS_TOKEN);

      if (res.status && (status === "Delivered" || status === "Ready")) {
        toast.success(
          switchedLanguage ? "استقر بنجاح" : "Order successfully settled"
        );
        handleReset();
      }
      return res;
    }
  };

  const handlePrint = async () => {
    if (
      orderTypeActiveBtn === "Delivery" &&
      (userData.name === "" ||
        userData.mobile === "" ||
        userData.locality === "")
    ) {
      toast.error(
        switchedLanguage
          ? "الاسم، الهاتف، حقول العنوان إلزامية"
          : "Name, Mobile, Address fields are mandatory!"
      );
      handleOpenUserModal();
    } else if (recentOrderClickedItem.length) {
      const id = recentOrderClickedItem[0]?._id;

      const data = {
        billerId: biller.id,
        orderStatus: recentOrderClickedItem[0].orderStatus,
        typeofOrder: orderTypeActiveBtn,
        paymentMethod: settlmentMode,
        tablesArray: selectedTable,
        tableNo:
          orderTypeActiveBtn === "Dine in"
            ? recentOrderClickedItem[0]?.tableNo
            : "",
        tableIds:
          orderTypeActiveBtn === "Dine in"
            ? recentOrderClickedItem[0]?.tableIds
            : "",
        discountPrice: totalSum.discount,
        package: totalSum.package,
        tips: totalSum.tips,
        productsSubTotal: Number(subTotal),
        grandTotal: grandTotal,
        splittedAmount:
          splittedAmount == grandTotal ? 0 : splittedAmount?.toFixed(2),
        roundOff: totalSum.roundOff,
        gstPercentage: biller.vendor.gstPercentage,
        gstAmount: totalSum.gstAmount?.toFixed(2),
        note: recentOrderClickedItem[0]?.note,
        vendorId: biller.vendor._id,
        products: items,
        captain: recentOrderClickedItem[0]?.captain,
        deliveryDetails: userData,
      };

      const res = await editRecentOrderFromBilling(id, biller.token, data);

      if (res.status) {
        if (res.printerIp) {
          let result = await printInvoice({
            userData: res.userData,
            data: res.orderData,
            ip: res.printerIp,
          });

          if (result.error) {
            toast.error(
              switchedLanguage
                ? "يرجى توصيل الطابعة الخاصة بك"
                : "Please connect your printer!"
            );
          } else {
            toast.success(
              switchedLanguage
                ? "تم إنشاء مشروع القانون"
                : "Invoice has been generated!"
            );
          }
        } else {
          toast.error(
            switchedLanguage
              ? "عنوان بروتوكول الإنترنت الطابعة مفقود"
              : "Printer IP address is missing!"
          );
        }
      } else {
        toast.error(
          switchedLanguage ? "خاطئ في تعديل النظام" : "Failed to edit order!"
        );
      }
    } else {
      let res = await placeNewPosOrder("Ready");

      if (res?.status) {
        dispatch({ type: "recentOrderSingleItem", payload: [res.data] });
        if (res?.printerIp) {
          let result = await printInvoice({
            userData: res.userData,
            data: res.data,
            ip: res.printerIp,
          });

          if (result.error) {
            toast.error(
              switchedLanguage
                ? "يرجى توصيل الطابعة الخاصة بك"
                : "Please connect your printer!"
            );
          } else {
            toast.success(
              switchedLanguage
                ? "تم إنشاء مشروع القانون"
                : "Invoice has been generated!"
            );
          }
        } else {
          toast.error(
            switchedLanguage
              ? "عنوان بروتوكول الإنترنت الطابعة مفقود"
              : "Printer IP address is missing!"
          );
        }
      } else {
        toast.error(
          switchedLanguage
            ? "خطأ في الطلب الجديد"
            : "Failed to place new order!"
        );
      }
    }
  };

  // To handle KOT Order
  const handleKotBtn = async () => {
    if (
      orderTypeActiveBtn === "Delivery" &&
      (userData.name === "" ||
        userData.mobile === "" ||
        userData.locality === "")
    ) {
      toast.error(
        switchedLanguage
          ? "الاسم، الجوال، حقول العنوان إلزامية"
          : "Name, Mobile, Address fields are mandatory!"
      );
      handleOpenUserModal();
    } else if (items.length <= 0) {
      handleOpenChooseProduct();
      // toast.error("Please choose a product!");
    } else {
      const sendToKot = async (order) => {
        const data = {
          billerId: biller.id,
          typeofOrder: orderTypeActiveBtn,
          orderId: order._id,
          orderNo: order?.orderNo,
          invoiceNo: order?.invoiceNo,
          orderStatus: "Preparing",
          paymentMethod: settlmentMode,
          tablesArray: selectedTable,
          tableNo: recentOrderClickedItem[0]?.tableNo
            ? recentOrderClickedItem[0]?.tableNo
            : tables,
          tableIds: recentOrderClickedItem[0]?.tableIds
            ? recentOrderClickedItem[0]?.tableIds
            : tableIds,
          discountPrice: totalSum.discount,
          productsSubTotal: Number(subTotal),
          grandTotal: grandTotal,
          roundOff: totalSum.roundOff,
          gstPercentage: biller.vendor.gstPercentage,
          gstAmount: totalSum.gstAmount?.toFixed(2),
          note: comments,
          vendorId: biller.vendor._id,
          products: items,
          deliveryDetails: userData,
          captain: foodAssignerPopupValue,
          orderTime: order.updatedAt,
        };
        let res = await sendOrderToKot(data, ACCESS_TOKEN);

        console.log(res, "res");
        if (res.status) {
          let result = await printKot(res.kotData);
          console.log(result, "rrrrrreeee");

          if (res.kotData.length) {
            toast.success(
              switchedLanguage
                ? "النظام الجديد هو النجاح"
                : "Order successfully placed!"
            );
            handleReset();
          } else {
            toast.error(
              switchedLanguage
                ? "لا يمكن القيام بهذا الإجراء"
                : "This action cannot be done!"
            );
          }
        }
      };

      if (recentOrderClickedItem.length) {
        const id = recentOrderClickedItem[0]?._id;
        const data = {
          billerId: biller.id,
          orderStatus: recentOrderClickedItem[0].orderStatus,
          typeofOrder: orderTypeActiveBtn,
          paymentMethod: settlmentMode,
          tablesArray: selectedTable,
          tableNo:
            orderTypeActiveBtn === "Dine in"
              ? recentOrderClickedItem[0]?.tableNo
              : "",
          tableIds:
            orderTypeActiveBtn === "Dine in"
              ? recentOrderClickedItem[0]?.tableIds
              : "",
          discountPrice: totalSum.discount,
          package: totalSum.package,
          tips: totalSum.tips,
          grandTotal: grandTotal,
          productsSubTotal: Number(subTotal),
          splittedAmount:
            splittedAmount == grandTotal ? 0 : splittedAmount?.toFixed(2),
          roundOff: totalSum.roundOff,
          gstPercentage: biller.vendor.gstPercentage,
          gstAmount: totalSum.gstAmount?.toFixed(2),
          note: recentOrderClickedItem[0]?.note,
          vendorId: biller.vendor._id,
          products: items,
          captain: recentOrderClickedItem[0]?.captain,
          deliveryDetails: userData,
        };
        const res = await editRecentOrderFromBilling(id, biller.token, data);

        sendToKot(res.orderData);
      } else {
        const order = await placeNewPosOrder("Preparing");
        sendToKot(order.data);
      }
    }
  };

  const handleSettleBtn = () => {
    if (items.length <= 0) {
      handleOpenChooseProduct();
    } else {
      handleOpenSettleModal();
    }
  };

  const handleCancelBtn = async () => {
    const id = recentOrderClickedItem[0]?._id;
    let res = await cancelRecentOrderFromBilling(id, biller.token);
    if (res.status) {
      toast.error(res.message);
      handleReset();
    }
  };

  const handleRecentSaveAndSettle = async (razorPay, orderStatus) => {
    const id = recentOrderClickedItem[0]?._id;

    if (orderTypeActiveBtn === "Delivery") {
      orderStatus = "Ready";
    } else {
      orderStatus = "Delivered";
    }

    const data = {
      billerId: biller.id,
      orderStatus: orderStatus,
      typeofOrder: orderTypeActiveBtn,
      paymentMethod: settlmentMode,
      tableNo:
        orderTypeActiveBtn === "Dine in"
          ? recentOrderClickedItem[0]?.tableNo
          : "",
      tableIds:
        orderTypeActiveBtn === "Dine in"
          ? recentOrderClickedItem[0]?.tableIds
          : "",
      discountPrice: totalSum.discount,
      package: totalSum.package,
      tips: totalSum.tips,
      productsSubTotal: Number(subTotal),
      grandTotal: grandTotal,
      splittedAmount:
        splittedAmount == grandTotal ? 0 : splittedAmount?.toFixed(2),
      roundOff: totalSum.roundOff,
      gstPercentage: biller.vendor.gstPercentage,
      gstAmount: totalSum.gstAmount?.toFixed(2),
      note: recentOrderClickedItem[0]?.note,
      vendorId: biller.vendor._id,
      products: items,
      captain: recentOrderClickedItem[0]?.captain,
      deliveryDetails: userData,
      paymentGateway: {
        isPaid: true,
        orderId: razorPay?.razorpay_order_id,
        paymentId: razorPay?.razorpay_payment_id,
        signature: razorPay?.razorpay_signature,
      },
    };

    const res = await editRecentOrderFromBilling(id, biller.token, data);

    if (res.status) {
      if (splittedAmount == grandTotal) {
        switchedLanguage
          ? toast.success("تم تسوية الطلب بنجاح")
          : toast.success(res.message);
        handleReset();
      } else {
        if (res.printerIp) {
          let result = await printInvoice({
            userData: res.userData,
            data: res.orderData,
            ip: res.printerIp,
          });

          if (result.error) {
            toast.error(
              switchedLanguage
                ? "يرجى توصيل الطابعة الخاصة بك"
                : "Please connect your printer!"
            );
            toast.success(res.message);
            handleReset();
          } else {
            toast.success(
              switchedLanguage
                ? "تم إنشاء مشروع القانون"
                : "Invoice has been generated!"
            );
            toast.success(res.message);
            handleReset();
          }
        } else {
          toast.error(
            switchedLanguage
              ? "عنوان بروتوكول الإنترنت الطابعة مفقود"
              : "Printer IP address is missing!"
          );
          toast.success(res.message);
          handleReset();
        }
      }
    }
  };

  const handleSettlementConfirmBtn = async () => {
    if (recentOrderClickedItem.length > 0) {
      if (settlmentMode === "UPI" || settlmentMode === "Card") {
        loadRazorpay();
      } else {
        handleRecentSaveAndSettle();
      }
    } else {
      if (settlmentMode === "UPI" || settlmentMode === "Card") {
        loadRazorpay();
      } else {
        if (splittedAmount == grandTotal) {
          let res = await placeNewPosOrder();
          if (res.status) {
            handleReset();
            handleCloseSettleModal();
          }
        } else {
          let res = await placeNewPosOrder();

          if (res.status) {
            if (res.printerIp) {
              let result = await printInvoice({
                userData: res.userData,
                data: res.data,
                ip: res.printerIp,
              });

              if (result.error) {
                toast.error(
                  switchedLanguage
                    ? "يرجى توصيل الطابعة الخاصة بك"
                    : "Please connect your printer!"
                );
                handleReset();
                handleCloseSettleModal();
              } else {
                toast.success(
                  switchedLanguage
                    ? "تم إنشاء مشروع القانون"
                    : "Invoice has been generated!"
                );
                handleReset();
                handleCloseSettleModal();
              }
            } else {
              toast.error(
                switchedLanguage
                  ? "عنوان بروتوكول الإنترنت الطابعة مفقود"
                  : "Printer IP address is missing!"
              );
              handleReset();
              handleCloseSettleModal();
            }
            handleReset();
            handleCloseSettleModal();
          }
        }
      }
    }
  };

  const loadRazorpay = () => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.onerror = () => {
      alert("Razorpay SDK failed to load. Are you online?");
    };
    script.onload = async () => {
      try {
        const razorpayKey = await getRazorpayKey(biller.token);

        const result = await paymentGatewayDetails(grandTotal, biller.token);
        const { amount, currency, id } = result;

        if (recentOrderClickedItem.length > 0) {
          const options = {
            key: razorpayKey.key,
            amount: Number(amount) * 100,
            currency: currency,
            name: "Labeeb",
            order_id: id,
            handler: async function (response) {
              const result = await handleRecentSaveAndSettle(response);
            },
            // prefill: {
            //   name: "Labeeb",
            //   email: "Labeeb@gmail.com",
            //   // contact: '1111111111',
            // },
            // notes: {
            //   address: 'example address',
            // },
            theme: {
              color: "#80c0f0",
            },
          };

          const paymentObject = new window.Razorpay(options);
          paymentObject.open();
        } else {
          const options = {
            key: razorpayKey.key,
            amount: Number(amount) * 100,
            currency: currency,
            name: "Labeeb",
            order_id: id,
            handler: async function (response) {
              const result = await placeNewPosOrder(undefined, response);
            },
            // prefill: {
            //   name: "Labeeb",
            //   email: "Labeeb@gmail.com",
            //   // contact: '1111111111',
            // },
            // notes: {
            //   address: 'example address',
            // },
            theme: {
              color: "#80c0f0",
            },
          };

          const paymentObject = new window.Razorpay(options);
          paymentObject.open();
        }
      } catch (err) {
        alert(err);
      }
    };
    document.body.appendChild(script);
  };

  return (
    <>
      {settleBtn && (
        <BillingSettlePopup
          handleOpenSettleModal={handleOpenSettleModal}
          handleCloseSettleModal={handleCloseSettleModal}
          settlmentMode={settlmentMode}
          handleSettlementMode={handleSettlementMode}
          handleSettlementConfirmBtn={handleSettlementConfirmBtn}
          grandTotal={grandTotal}
          splitCount={splitCount}
          setSplitCount={setSplitCount}
          setSplittedAmount={setSplittedAmount}
          splittedAmount={splittedAmount}
        />
      )}

      {chooseProductPopup && (
        <PleaseChooseProductPopup
          handleOpenChooseProduct={handleOpenChooseProduct}
          handleCloseChooseProduct={handleCloseChooseProduct}
          setChooseProductPopup={setChooseProductPopup}
        />
      )}

      <Box
        component="section"
        sx={{
          background: "#fff",
          padding: 2,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "92vh",
          width: "29vw",
        }}
      >
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            component="div"
            sx={{
              display: "flex",
              gap: "10px",
            }}
          >
            <Button
              onClick={() => handleOrderTypeActiveBtn("Dine in")}
              variant="contained"
              sx={{
                boxShadow: "none",
                textTransform: "none",
                color:
                  orderTypeActiveBtn === "Dine in"
                    ? "var(--font-white)"
                    : "var(--font-black)",
                backgroundColor:
                  orderTypeActiveBtn === "Dine in" ? "var(--red)" : "#F5F5F5",
                "&:hover": {
                  boxShadow: "none",
                  backgroundColor:
                    orderTypeActiveBtn === "Dine in" ? "var(--red)" : "#F5F5F5",
                },
                fontFamily: "poppins",
                cursor: "pointer",
                flex: 1,
              }}
            >
              {switchedLanguage ? "على الطاولة" : "Dine-in"}
            </Button>
            <Button
              onClick={() => handleOrderTypeActiveBtn("Pickup")}
              variant="contained"
              sx={{
                boxShadow: "none",
                textTransform: "none",
                color:
                  orderTypeActiveBtn === "Pickup"
                    ? "var(--font-white)"
                    : "var(--font-black)",
                backgroundColor:
                  orderTypeActiveBtn === "Pickup" ? "var(--red)" : "#F5F5F5",
                "&:hover": {
                  boxShadow: "none",
                  backgroundColor:
                    orderTypeActiveBtn === "Pickup" ? "var(--red)" : "#F5F5F5",
                },
                fontFamily: "poppins",
                cursor: "pointer",
                flex: 1,
              }}
            >
              {switchedLanguage ? "أمر التقاط" : "Pickup"}
            </Button>
            <Button
              onClick={() => handleOrderTypeActiveBtn("Delivery")}
              variant="contained"
              sx={{
                boxShadow: "none",
                textTransform: "none",
                color:
                  orderTypeActiveBtn === "Delivery"
                    ? "var(--font-white)"
                    : "var(--font-black)",
                backgroundColor:
                  orderTypeActiveBtn === "Delivery" ? "var(--red)" : "#F5F5F5",
                "&:hover": {
                  boxShadow: "none",
                  backgroundColor:
                    orderTypeActiveBtn === "Delivery"
                      ? "var(--red)"
                      : "#F5F5F5",
                },
                fontFamily: "poppins",
                cursor: "pointer",
                flex: 1,
              }}
            >
              {switchedLanguage ? "التسليم" : "Delivery"}
            </Button>
          </Box>
          <Box
            component="div"
            sx={{
              display: "flex",
              gap: "10px",
              py: 2,
              justifyContent: "space-between",
            }}
          >
            <Button
              onClick={handleOpenUserModal}
              variant="outlined"
              sx={{
                borderColor: "#F5F5F5",
                color: "#000",
                borderRadius: "13px",
              }}
            >
              <PersonIcon />
            </Button>
            <Button
              onClick={() => {
                if (orderTypeActiveBtn === "Dine in") {
                  handleOpenTableModal();
                } else {
                  toast.error(
                    switchedLanguage
                      ? "الجدول غير مطلوب"
                      : "No need to choose table for this order!"
                  );
                }
              }}
              variant="outlined"
              sx={{
                borderColor: "#F5F5F5",
                color: "#000",
                borderRadius: "13px",
              }}
            >
              <TableRestaurantIcon />
            </Button>
            <Button
              onClick={handleOpenAssigneeModal}
              variant="outlined"
              sx={{
                borderColor: "#F5F5F5",
                color: "#000",
                borderRadius: "13px",
              }}
            >
              <RamenDiningIcon />
            </Button>
            <Button
              onClick={handleOpenCommentModal}
              variant="outlined"
              sx={{
                borderColor: "#F5F5F5",
                color: "#000",
                borderRadius: "13px",
              }}
            >
              <ChatIcon />
            </Button>
          </Box>

          <Box
            component="div"
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              overflowX: "scroll",
              "&::-webkit-scrollbar": {
                display: "none", // Hide scrollbar
              },
              height: "100px",
              overflowY: "scroll",
              flexGrow: 1,
            }}
          >
            {products?.map((product, index) => {
              const varientName = [
                product.varientId.value1,
                product.varientId.value2,
                product.varientId.value3,
                product.varientId.value4,
                product.varientId.value5,
              ]
                .filter(Boolean)
                .join(" ");

              return (
                <>
                  <Box
                    key={index}
                    component="div"
                    sx={{
                      background: "#F5F5F5",
                      p: 2,
                      // height: "100%",
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",

                      // overflow: "scroll",
                      // maxHeight:"100px"
                    }}
                  >
                    <Box
                      variant="div"
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        gap: "13px",
                        // height: "90px",
                      }}
                    >
                      <Typography
                        sx={{ fontFamily: "poppins", fontSize: "15px" }}
                        gutterBottom
                      >
                        {product.itemName
                          ? product.itemName
                          : recentOrderClickedItem[0]?.products
                          ? `${product?.productId?.title} ${varientName}`
                          : product.itemName}
                      </Typography>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <button
                          onClick={() => {
                            if (
                              (recentOrderClickedItem[0]?.products.length &&
                                items.length !== 1) ||
                              !recentOrderClickedItem[0]?.products.length ||
                              product.quantity !== 1
                            ) {
                              handleDecrement(product.varientId);
                            }
                          }}
                          variant="div"
                          style={{
                            height: "30px",
                            width: "30px",
                            borderRadius: "50%",
                            backgroundColor: "#D9D9D9",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            cursor: "pointer",
                            border: "none",
                          }}
                          disabled={product._id ? true : false}
                        >
                          <RemoveIcon sx={{ fontSize: "medium" }} />
                        </button>
                        &nbsp;
                        {switchedLanguage
                          ? product.quantity.toLocaleString("ar-EG")
                          : product.quantity}
                        &nbsp;
                        <button
                          onClick={() =>
                            handleIncrement(
                              product.varientId,
                              "mainItem",
                              product
                            )
                          }
                          variant="div"
                          style={{
                            height: "30px",
                            width: "30px",
                            borderRadius: "50%",
                            backgroundColor: "#D9D9D9",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            cursor: "pointer",
                            border: "none",
                          }}
                          disabled={product._id ? true : false}
                        >
                          <AddIcon sx={{ fontSize: "medium" }} />
                        </button>
                      </Box>
                    </Box>

                    <Box
                      variant="div"
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-end",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography sx={{ fontFamily: "poppins" }}>
                        {switchedLanguage
                          ? (
                              product?.quantity * product.salePrice
                            ).toLocaleString("ar-EG")
                          : parseFloat(
                              product?.quantity * product.salePrice
                              // ? product.quantity * product.salePrice
                              // : recentOrderClickedItem[0]?.products
                              // ? product?.quantity * product.salePrice
                              // : product.quantity * product.salePrice
                            )}
                      </Typography>

                      <DeleteIcon
                        onClick={() => {
                          if (
                            (recentOrderClickedItem[0]?.products.length &&
                              items.length !== 1) ||
                            !recentOrderClickedItem[0]?.products.length
                          ) {
                            handleDeleteListedItem(
                              product.varientId,
                              "mainItem",
                              product
                            );
                          }
                        }}
                        style={{
                          fontSize: "20px",
                          color:
                            recentOrderClickedItem[0]?.products.length &&
                            items.length === 1
                              ? "grey"
                              : "red",
                          cursor:
                            recentOrderClickedItem[0]?.products.length &&
                            items.length === 1
                              ? "not-allowed"
                              : "pointer",
                        }}
                      />
                    </Box>
                  </Box>

                  {product?.addOns?.map((val) => {
                    return (
                      <Box
                        key={index}
                        component="div"
                        sx={{
                          background: "#F5F5F5",
                          p: 2,
                          // height: "100px",
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box
                          variant="div"
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-around",
                            gap: "13px",
                          }}
                        >
                          <Typography
                            sx={{ fontFamily: "poppins", fontSize: "15px" }}
                            gutterBottom
                          >
                            {val.name}
                          </Typography>
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Box
                              onClick={() =>
                                handleDecrement(val.valueId, "addons", product)
                              }
                              variant="div"
                              sx={{
                                height: "30px",
                                width: "30px",
                                borderRadius: "50%",
                                backgroundColor: "#D9D9D9",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                cursor: "pointer",
                              }}
                            >
                              <RemoveIcon sx={{ fontSize: "medium" }} />
                            </Box>
                            &nbsp;
                            {switchedLanguage
                              ? val.quantity.toLocaleString("ar-EG")
                              : val.quantity}
                            &nbsp;
                            <button
                              onClick={() =>
                                handleIncrement(val.valueId, "addons", product)
                              }
                              variant="div"
                              style={{
                                height: "30px",
                                width: "30px",
                                borderRadius: "50%",
                                backgroundColor: "#D9D9D9",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                cursor: "pointer",
                                border: "none",
                              }}
                              disabled={product._id ? true : false}
                            >
                              <AddIcon sx={{ fontSize: "medium" }} />
                            </button>
                          </Box>
                        </Box>
                        <Box
                          variant="div"
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-end",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography sx={{ fontFamily: "poppins" }}>
                            {switchedLanguage
                              ? (val.quantity * val.price).toLocaleString(
                                  "ar-EG"
                                )
                              : val.quantity * val.price}
                          </Typography>

                          <DeleteIcon
                            onClick={() =>
                              handleDeleteListedItem(
                                val.valueId,
                                "addOns",
                                product
                              )
                            }
                            style={{
                              fontSize: "20px",
                              color: "red",
                              cursor: "pointer",
                            }}
                          />
                        </Box>
                      </Box>
                    );
                  })}
                </>
              );
            })}
          </Box>
        </Box>

        <Box>
          <Typography>
            {switchedLanguage ? "ملخص الأوامر" : "Order Summary"}
          </Typography>
          <Box sx={{ py: 2 }}>
            <Box
              variant="div"
              sx={{
                bgcolor: "#F5F5F5",
                borderRadius: "7%",
                p: 2,
                display: "flex",
                flexDirection: "column",
                gap: "7px",
              }}
            >
              <Box
                variant="div"
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography sx={{ fontSize: "14px", fontFamily: "poppins" }}>
                  {switchedLanguage ? "المجموع الفرعي" : "Subtotal"}
                </Typography>
                <Typography sx={{ fontSize: "14px" }}>
                  {switchedLanguage ? "" : currency}
                  <span
                    style={{
                      fontFamily: "poppins",
                      fontSize: switchedLanguage ? "16px" : "14px",
                      fontWeight: switchedLanguage ? "bold" : 400,
                    }}
                  >
                    {switchedLanguage
                      ? subTotal.toLocaleString("ar-EG")
                      : subTotal}
                  </span>{" "}
                  {switchedLanguage ? currency : ""}
                </Typography>
              </Box>
              <Box
                variant="div"
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography sx={{ fontSize: "14px", fontFamily: "poppins" }}>
                  {switchedLanguage ? "الخصم" : "Discount"}
                </Typography>
                <input
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    let discountValue;

                    if (inputValue === "") {
                      discountValue = null; // or any default value you prefer
                    } else {
                      discountValue = parseFloat(inputValue);
                      console.log(discountValue);
                      if (isNaN(discountValue)) {
                        console.log(discountValue);
                        discountValue = totalSum.discount; // keep the previous value if the input is invalid
                      }
                    }

                    setTotalSum({
                      ...totalSum,
                      discount: discountValue,
                    });
                  }}
                  // value={switchedLanguage ? totalSum.discount?.toLocaleString("ar-EG") : totalSum.discount}

                  value={totalSum.discount}
                  type="number"
                  min="0"
                  style={{
                    padding: "5px",
                    height: "25px",
                    width: "100px",
                    fontFamily: "poppins",
                    textAlign: "right",
                    outline: "none",
                  }}
                  // placeholder= "&#x20B9; 0.00"
                  placeholder={switchedLanguage ? currency : `${currency} 0.00`}
                />
              </Box>
              <Box
                variant="div"
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography sx={{ fontSize: "14px", fontFamily: "poppins" }}>
                  {switchedLanguage ? "حزمة" : "Package"}
                </Typography>
                <input
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    let discountValue;

                    if (inputValue === "") {
                      discountValue = null; // or any default value you prefer
                    } else {
                      discountValue = parseFloat(inputValue);
                      if (isNaN(discountValue)) {
                        discountValue = totalSum.discount; // keep the previous value if the input is invalid
                      }
                    }

                    setTotalSum({
                      ...totalSum,
                      package: discountValue,
                    });
                  }}
                  value={totalSum.package}
                  type="number"
                  min="0"
                  style={{
                    padding: "5px",
                    height: "25px",
                    width: "100px",
                    fontFamily: "poppins",
                    textAlign: "right",
                    outline: "none",
                  }}
                  // placeholder="&#x20B9; 0.00"
                  placeholder={switchedLanguage ? currency : `${currency} 0.00`}
                />
              </Box>

              <Box
                variant="div"
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography sx={{ fontSize: "14px", fontFamily: "poppins" }}>
                  {switchedLanguage ? "نصائح" : "Tips"}
                </Typography>
                <input
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    let discountValue;

                    if (inputValue === "") {
                      discountValue = null; // or any default value you prefer
                    } else {
                      discountValue = parseFloat(inputValue);
                      if (isNaN(discountValue)) {
                        discountValue = totalSum.discount; // keep the previous value if the input is invalid
                      }
                    }

                    setTotalSum({
                      ...totalSum,
                      tips: discountValue,
                    });
                  }}
                  value={totalSum.tips}
                  type="number"
                  min="0"
                  style={{
                    padding: "5px",
                    height: "25px",
                    width: "100px",
                    fontFamily: "poppins",
                    textAlign: "right",
                    outline: "none",
                  }}
                  // placeholder="&#x20B9; 0.00"
                  placeholder={switchedLanguage ? currency : `${currency} 0.00`}
                />
              </Box>

              <Box
                variant="div"
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography sx={{ fontSize: "14px", fontFamily: "poppins" }}>
                  {switchedLanguage ? "تقريب قبالة" : "Round off"}
                </Typography>
                <input
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    let discountValue;

                    if (inputValue === "") {
                      discountValue = null; // or any default value you prefer
                    } else {
                      discountValue = parseFloat(inputValue);
                      if (isNaN(discountValue)) {
                        discountValue = totalSum.discount; // keep the previous value if the input is invalid
                      }
                    }

                    setTotalSum({
                      ...totalSum,
                      roundOff: discountValue,
                    });
                  }}
                  value={totalSum.roundOff}
                  type="number"
                  min="0"
                  style={{
                    padding: "5px",
                    height: "25px",
                    width: "100px",
                    fontFamily: "poppins",
                    textAlign: "right",
                    outline: "none",
                  }}
                  // placeholder="&#x20B9; 0.00"
                  placeholder={switchedLanguage ? currency : `${currency} 0.00`}
                />
              </Box>
            </Box>
            <Box
              variant="div"
              sx={{
                bgcolor: "#F5F5F5",
                borderRadius: "7%",
                p: 2,
              }}
            >
              <Box
                variant="div"
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "poppins",
                    fontSize: "20px",
                    fontWeight: "400",
                  }}
                >
                  {switchedLanguage ? "المجموع" : "Total"}
                </Typography>
                <Typography sx={{ fontSize: "20px", fontWeight: "400" }}>
                  {switchedLanguage ? "" : currency}
                  <span
                    style={{
                      fontFamily: "poppins",
                      fontSize: "20px",
                      fontWeight: "400",
                    }}
                  >
                    {switchedLanguage
                      ? grandTotal.toLocaleString("ar-EG").replace(/٫/g, ".")
                      : // ? grandTotal.toLocaleString("ar-EG")
                        grandTotal.toFixed(2)}{" "}
                    {switchedLanguage ? currency : ""}
                  </span>
                </Typography>
              </Box>
            </Box>
          </Box>

          {!recentOrderClickedItem.length ? (
            <Box
              component="div"
              sx={{
                display: "flex",
                gap: "10px",
                paddingBottom: 1,
              }}
            >
              <Button
                onClick={handlePrint}
                variant="contained"
                sx={{
                  textTransform: "none",
                  color: "var(--font-white)",
                  backgroundColor: "var(--red)",
                  "&:hover": {
                    backgroundColor: "var(--red)",
                  },
                  fontFamily: "poppins",
                  cursor: "pointer",
                  flex: 1,
                  padding: "0px",
                  height: "47px",
                }}
              >
                {switchedLanguage ? "طباعة" : "Print"}
              </Button>

              <Button
                onClick={() => {
                  if (!navigator.onLine) {
                    toast.error(
                      switchedLanguage
                        ? "الشبكة غير متوفرة!"
                        : "Network not available!"
                    );
                  } else {
                    handleKotBtn();
                  }
                }}
                variant="contained"
                sx={{
                  textTransform: "none",
                  color: "var(--font-white)",
                  backgroundColor: "var(--red)",
                  "&:hover": {
                    backgroundColor: "var(--red)",
                  },
                  fontFamily: "poppins",
                  cursor: "pointer",
                  flex: 1,
                  padding: "0px",
                  height: "47px",
                }}
              >
                {switchedLanguage ? "المطبخ" : "KOT"}
              </Button>

              <Button
                onClick={handleReset}
                variant="outlined"
                sx={{
                  // width: "33%",
                  textTransform: "none",
                  color: "var(--font-black)",
                  borderColor: "var(--border-grey)",
                  backgroundColor: "var(--font-white)",
                  "&:hover": {
                    backgroundColor: "var(--font-white)",
                    borderColor: "var(--border-grey)",
                  },
                  fontFamily: "poppins",
                  cursor: "pointer",
                  flex: 1,
                  padding: "0px",
                  height: "45px",
                }}
              >
                {switchedLanguage ? "إعادة تعيين" : "Reset"}
              </Button>
            </Box>
          ) : (
            <Box
              component="div"
              sx={{
                display: "flex",
                gap: "10px",
                paddingBottom: 1,
              }}
            >
              <Button
                onClick={handlePrint}
                variant="contained"
                sx={{
                  textTransform: "none",
                  color: "var(--font-white)",
                  backgroundColor: "var(--red)",
                  "&:hover": {
                    backgroundColor: "var(--red)",
                  },
                  fontFamily: "poppins",
                  cursor: "pointer",
                  flex: 1,
                  padding: "0px",
                  height: "47px",
                }}
              >
                {switchedLanguage ? "طباعة" : "Print"}
              </Button>

              <Button
                onClick={handleSettleBtn}
                variant="contained"
                sx={{
                  textTransform: "none",
                  color: "var(--font-white)",
                  backgroundColor: "var(--red)",
                  "&:hover": {
                    backgroundColor: "var(--red)",
                  },
                  fontFamily: "poppins",
                  cursor: "pointer",
                  flex: 1,
                  padding: "0px",
                  height: "47px",
                }}
              >
                {switchedLanguage ? "التسوية" : "Settle"}
              </Button>

              <Button
                onClick={handleCancelBtn}
                variant="outlined"
                sx={{
                  // width: "33%",
                  textTransform: "none",
                  color: "var(--font-black)",
                  borderColor: "var(--border-grey)",
                  backgroundColor: "var(--font-white)",
                  "&:hover": {
                    backgroundColor: "var(--font-white)",
                    borderColor: "var(--border-grey)",
                  },
                  fontFamily: "poppins",
                  cursor: "pointer",
                  flex: 1,
                  padding: "0px",
                  height: "45px",
                }}
              >
                {switchedLanguage ? "إلغاء" : "Cancel"}
              </Button>
            </Box>
          )}

          {!recentOrderClickedItem.length ? (
            <Box
              component="div"
              sx={{
                display: "flex",
                gap: "10px",
                paddingBottom: 1,
                justifyContent: "center",
              }}
            >
              <Button
                onClick={handleSettleBtn}
                variant="contained"
                sx={{
                  textTransform: "none",
                  color: "var(--font-white)",
                  backgroundColor: "#4D575D",
                  "&:hover": {
                    backgroundColor: "#4D575D",
                  },
                  fontFamily: "poppins",
                  cursor: "pointer",
                  // flex: 1,
                  width: "130px",
                  padding: "0px",
                  height: "40px",
                }}
              >
                {switchedLanguage ? "التسوية" : "Settle"}
              </Button>

              <Button
                variant="outlined"
                sx={{
                  textTransform: "none",
                  color: "var(--font-white)",
                  backgroundColor: "#4D575D",
                  "&:hover": {
                    backgroundColor: "#4D575D",
                  },
                  fontFamily: "poppins",
                  cursor: "pointer",
                  // flex: 1,
                  padding: "0px",
                  height: "40px",
                  width: "130px",
                }}
              >
                {switchedLanguage ? "انتظر" : "Hold"}
              </Button>
            </Box>
          ) : (
            <Box
              component="div"
              sx={{
                display: "flex",
                gap: "10px",
                paddingBottom: 1,
                justifyContent: "center",
              }}
            >
              <Button
                onClick={handleKotBtn}
                variant="contained"
                sx={{
                  textTransform: "none",
                  color: "var(--font-white)",
                  backgroundColor: "#4D575D",
                  "&:hover": {
                    backgroundColor: "#4D575D",
                  },
                  fontFamily: "poppins",
                  cursor: "pointer",
                  // flex: 1,
                  padding: "0px",
                  height: "40px",
                  width: "130px",
                }}
              >
                {switchedLanguage ? "المطبخ" : "KOT"}
              </Button>

              <Button
                variant="outlined"
                sx={{
                  textTransform: "none",
                  color: "var(--font-white)",
                  backgroundColor: "#4D575D",
                  "&:hover": {
                    backgroundColor: "#4D575D",
                  },
                  fontFamily: "poppins",
                  cursor: "pointer",
                  // flex: 1,
                  padding: "0px",
                  height: "40px",
                  width: "130px",
                }}
              >
                {switchedLanguage ? "انتظر" : "Hold"}
              </Button>
            </Box>
          )}

          {/* {recentOrderClickedItem.length ? (
            <Box
              component="div"
              sx={{
                display: "flex",
                gap: "10px",
                paddingBottom: 1,
              }}
            >
              <Button
                onClick={handleRecentSaveAndSettle}
                variant="contained"
                sx={{
                  textTransform: "none",
                  color: "var(--font-white)",
                  backgroundColor: "var(--red)",
                  "&:hover": {
                    backgroundColor: "var(--red)",
                  },
                  fontFamily: "poppins",
                  cursor: "pointer",
                  flex: 1,
                  padding: "0px",
                  height: "47px",
                }}
              >
                Save & Settle
              </Button>

              <Button
                onClick={handleKotAndPrint}
                variant="contained"
                sx={{
                  textTransform: "none",
                  color: "var(--font-white)",
                  backgroundColor: "#4D575D",
                  "&:hover": {
                    backgroundColor: "#4D575D",
                  },
                  fontFamily: "poppins",
                  cursor: "pointer",
                  flex: 1,
                  padding: "0px",
                  height: "45px",
                }}
              >
                KOT & Print
              </Button>

              <Button
                onClick={handleCancelBtn}
                variant="outlined"
                sx={{
                  // width: "33%",
                  textTransform: "none",
                  color: "var(--font-black)",
                  borderColor: "var(--border-grey)",
                  backgroundColor: "var(--font-white)",
                  "&:hover": {
                    backgroundColor: "var(--font-white)",
                    borderColor: "var(--border-grey)",
                  },
                  fontFamily: "poppins",
                  cursor: "pointer",
                  flex: 1,
                  padding: "0px",
                  height: "45px",
                }}
              >
                Cancel
              </Button>
            </Box>
          ) : (
            <Box
              component="div"
              sx={{
                display: "flex",
                gap: "10px",
                paddingBottom: 1,
              }}
            > */}
          {/* <Button
                onClick={handleSavePrint}
                variant="contained"
                sx={{
                  textTransform: "none",
                  color: "var(--font-white)",
                  backgroundColor: "var(--red)",
                  "&:hover": {
                    backgroundColor: "var(--red)",
                  },
                  fontFamily: "poppins",
                  cursor: "pointer",
                  flex: 1,
                  padding: "0",
                }}
              >
                Save & Print
              </Button> */}
          {/* <Button
                onClick={handleSaveAndSettle}
                variant="contained"
                sx={{
                  textTransform: "none",
                  color: "var(--font-white)",
                  backgroundColor: "var(--red)",
                  "&:hover": {
                    backgroundColor: "var(--red)",
                  },
                  fontFamily: "poppins",
                  cursor: "pointer",
                  flex: 1,
                  padding: "0px",
                  height: "47px",
                }}
              >
                Save & Settle
              </Button>

              <Button
                onClick={handleKotAndPrint}
                variant="contained"
                sx={{
                  textTransform: "none",
                  color: "var(--font-white)",
                  backgroundColor: "#4D575D",
                  "&:hover": {
                    backgroundColor: "#4D575D",
                  },
                  fontFamily: "poppins",
                  cursor: "pointer",
                  flex: 1,
                  padding: "0px",
                  height: "45px",
                }}
              >
                KOT & Print
              </Button>

              <Button
                //onClick={handleReset}
                variant="outlined"
                sx={{
                  // width: "33%",
                  textTransform: "none",
                  color: "var(--font-black)",
                  borderColor: "var(--border-grey)",
                  backgroundColor: "var(--font-white)",
                  "&:hover": {
                    backgroundColor: "var(--font-white)",
                    borderColor: "var(--border-grey)",
                  },
                  fontFamily: "poppins",
                  cursor: "pointer",
                  flex: 1,
                  padding: "0px",
                  height: "45px",
                }}
              >
                Reset
              </Button>
            </Box>
          )}

          {recentOrderClickedItem.length ? (
            ""
          ) : (
            <Box
              component="div"
              sx={{
                display: "flex",
                gap: "10px",
                paddingBottom: 1,
              }}
            > */}
          {/* <Button
                variant="contained"
                sx={{
                  textTransform: "none",
                  color: "var(--font-white)",
                  backgroundColor: "#4D575D",
                  "&:hover": {
                    backgroundColor: "#4D575D",
                  },
                  fontFamily: "poppins",
                  cursor: "pointer",
                  flex: 1,
                  padding: "0px",
                  height: "45px",
                }}
              >
                KOT
              </Button> */}

          {/* <Button
                variant="outlined"
                sx={{
                  textTransform: "none",
                  color: "var(--font-black)",
                  borderColor: "var(--border-grey)",
                  backgroundColor: "var(--font-white)",
                  "&:hover": {
                    backgroundColor: "var(--font-white)",
                    borderColor: "var(--border-grey)",
                  },
                  fontFamily: "poppins",
                  cursor: "pointer",
                  flex: 1,
                  padding: "0px",
                  height: "45px",
                }}
              >
                Hold
              </Button>

              <Button
                variant="contained"
                sx={{
                  textTransform: "none",
                  color: "var(--font-white)",
                  backgroundColor: "#4D575D",
                  "&:hover": {
                    backgroundColor: "#4D575D",
                  },
                  fontFamily: "poppins",
                  cursor: "pointer",
                  flex: 1,
                  padding: "0px",
                  height: "45px",
                }}
              >
                Split
              </Button>

              <Button
                variant="contained"
                sx={{
                  textTransform: "none",
                  color: "var(--font-white)",
                  backgroundColor: "#4D575D",
                  "&:hover": {
                    backgroundColor: "#4D575D",
                  },
                  fontFamily: "poppins",
                  cursor: "pointer",
                  flex: 1,
                  padding: "0px",
                  height: "45px",
                }}
              >
                Merge
              </Button>
            </Box>
          )} */}

          {/* <Box
            component="div"
            sx={{
              display: "flex",
              gap: "10px",
              justifyContent: "center",
              paddingBottom: 1,
              px: 8,
            }}
          >
            <Button
              variant="contained"
              sx={{
                textTransform: "none",
                color: "var(--font-white)",
                backgroundColor: "#4D575D",
                "&:hover": {
                  backgroundColor: "#4D575D",
                },
                fontFamily: "poppins",
                cursor: "pointer",
                flex: 1,
              }}
            >
              Split
            </Button>
            <Button
              variant="contained"
              sx={{
                textTransform: "none",
                color: "var(--font-white)",
                backgroundColor: "#4D575D",
                "&:hover": {
                  backgroundColor: "#4D575D",
                },
                fontFamily: "poppins",
                cursor: "pointer",
                flex: 1,
              }}
            >
              Merge
            </Button>
          </Box> */}
        </Box>
      </Box>
    </>
  );
}
