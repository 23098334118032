import React, { useState, useEffect } from "react";
import { Box, Modal, Paper, Typography, Divider, Button } from "@mui/material";
import TextField from "@mui/material/TextField";
import settleModeSelectedTick from "../../assets/settle_cash_mode_tick.png";
import settleModeCashImage from "../../assets/settle_mode_cash.png";
import settleModeCardImage from "../../assets/settle_mode_card.png";
import settleModePartImage from "../../assets/settle_mode_port.png";
import settleModeUpiImage from "../../assets/settle_mode_upi.png";

import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const BillingSettlePopup = ({
  handleOpenSettleModal,
  handleCloseSettleModal,
  settlmentMode,
  handleSettlementMode,
  handleSettlementConfirmBtn,
  grandTotal,

  splitCount,
  setSplitCount,
  setSplittedAmount,
  splittedAmount,
}) => {
  const { switchedLanguage } = useSelector((state) => state);
  const [balance, setBalance] = useState(0);
  const [inputPrice, setInputPrice] = useState(0);
  const [disableFields, setDisableFields] = useState(false);

  useEffect(() => {
    setBalance(parseFloat(inputPrice - grandTotal));
  }, [inputPrice]);

  useEffect(() => {
    setSplittedAmount(grandTotal / splitCount);
  }, [splitCount]);

  useEffect(() => {
    if (settlmentMode === "UPI" || settlmentMode === "Card") {
      setDisableFields(true);
      setSplitCount(1);
    } else {
      setDisableFields(false);
    }
  }, [settlmentMode]);

  const handleConfirm = () => {
    if (inputPrice < grandTotal && !disableFields) {
      toast.error(
        switchedLanguage
          ? "يرجى إدخال المبلغ الكامل"
          : "Please enter full amount!"
      );
    } else if (!navigator.onLine) {
      toast.error(
        switchedLanguage ? "الشبكة غير متوفرة" : "Network not available!"
      );
    } else {
      handleSettlementConfirmBtn();
    }
  };

  const handleCloseBtn = () => {
    setSplitCount(1);
    handleCloseSettleModal();
  };

  return (
    <>
      {
        <Modal
          open={handleOpenSettleModal}
          onClose={handleCloseBtn}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Paper
            sx={{ border: "none", display: "flex", justifyContent: "center" }}
          >
            <Box
              sx={{
                position: "absolute",
                left: "50%",
                padding: "26px",
                backgroundColor: "background.paper",
                width: "35em",
                borderRadius: "5px",
                top: "50%",
                transform: "translate(-50%,-50%)",
                display: "flex",
                flexDirection: "column",
                gap: "5px",
                borderRadius: "17px",
              }}
            >
              <Box sx={{ marginBottom: "25px" }}>
                <sup
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <CloseIcon
                    onClick={handleCloseBtn}
                    sx={{
                      fontFamily: "poppins",
                      fontSize: "18px",
                      cursor: "pointer",
                      fontWeight: "bold",
                    }}
                  />
                </sup>
                <Typography
                  sx={{
                    fontFamily: "poppins",
                    fontSize: "19px",
                    fontWeight: "600",
                    textAlign: "center",
                  }}
                >
                  {switchedLanguage ? "حفظ وتسوية" : "Save & Settle"}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", gap: "15px", marginBottom: "25px" }}>
                <Button
                  onClick={() => handleSettlementMode("UPI")}
                  variant="contained"
                  sx={{
                    boxShadow: "none",
                    width: "30%",
                    height: "55px",
                    backgroundColor: "#F5F5F5",
                    color: "#4D575D",
                    border: "1px solid #D7D7D7",
                    fontFamily: "poppins",
                    cursor: "pointer",
                    borderRadius: "11px",
                    textTransform: "none",
                    "&:hover": {
                      boxShadow: "none",
                      backgroundColor: "#F5F5F5",
                    },
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {settlmentMode === "UPI" && (
                    <span
                      style={{
                        position: "absolute",
                        top: "5px",
                        right: "5px",
                        width: "10px",
                        height: "10px",
                        backgroundImage: `url(${settleModeSelectedTick})`,
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        borderRadius: "50%",
                      }}
                    ></span>
                  )}
                  <Box
                    sx={{
                      position: "relative",
                      width: "100%",
                      height: "70%",
                    }}
                  >
                    <img
                      src={settleModeUpiImage}
                      alt="Upi"
                      style={{
                        position: "relative",

                        width: "50%",
                        height: "100%",
                        objectFit: "contain",
                      }}
                    />
                  </Box>
                  <Typography
                    variant="body1"
                    sx={{ fontFamily: "poppins", fontSize: "11px" }}
                  >
                    {switchedLanguage ? "بوابة الدفع" : "Upi"}
                  </Typography>
                </Button>

                <Button
                  onClick={() => handleSettlementMode("COD")}
                  variant="contained"
                  sx={{
                    boxShadow: "none",
                    width: "30%",
                    height: "55px",
                    backgroundColor: "#F5F5F5",
                    color: "#4D575D",
                    border: "1px solid #D7D7D7",
                    fontFamily: "poppins",
                    cursor: "pointer",
                    borderRadius: "11px",
                    textTransform: "none",
                    "&:hover": {
                      boxShadow: "none",
                      backgroundColor: "#F5F5F5",
                    },
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {settlmentMode === "COD" && (
                    <span
                      style={{
                        position: "absolute",
                        top: "5px",
                        right: "5px",
                        width: "10px",
                        height: "10px",
                        backgroundImage: `url(${settleModeSelectedTick})`,
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        borderRadius: "50%",
                      }}
                    ></span>
                  )}
                  <Box
                    sx={{
                      position: "relative",
                      width: "100%",
                      height: "70%",
                    }}
                  >
                    <img
                      src={settleModeCashImage}
                      alt="Upi"
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        objectFit: "contain",
                      }}
                    />
                  </Box>
                  <Typography
                    variant="body1"
                    sx={{ fontFamily: "poppins", fontSize: "11px" }}
                  >
                    {switchedLanguage ? "النقدية" : "Cash"}
                  </Typography>
                </Button>

                <Button
                  onClick={() => handleSettlementMode("Card")}
                  variant="contained"
                  sx={{
                    boxShadow: "none",
                    width: "30%",
                    height: "55px",
                    backgroundColor: "#F5F5F5",
                    color: "#4D575D",
                    border: "1px solid #D7D7D7",
                    fontFamily: "poppins",
                    cursor: "pointer",
                    borderRadius: "11px",
                    textTransform: "none",
                    "&:hover": {
                      boxShadow: "none",
                      backgroundColor: "#F5F5F5",
                    },
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {settlmentMode === "Card" && (
                    <span
                      style={{
                        position: "absolute",
                        top: "5px",
                        right: "5px",
                        width: "10px",
                        height: "10px",
                        backgroundImage: `url(${settleModeSelectedTick})`,
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        borderRadius: "50%",
                      }}
                    ></span>
                  )}

                  <Box
                    sx={{
                      position: "relative",
                      width: "100%",
                      height: "70%",
                    }}
                  >
                    <img
                      src={settleModeCardImage}
                      alt="Upi"
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        objectFit: "contain",
                      }}
                    />
                  </Box>
                  <Typography
                    variant="body1"
                    sx={{ fontFamily: "poppins", fontSize: "11px" }}
                  >
                    {switchedLanguage ? "بطاقة" : "Card"}
                  </Typography>
                </Button>

                <Button
                  disabled={true}
                  onClick={() => handleSettlementMode("Part")}
                  variant="contained"
                  sx={{
                    boxShadow: "none",
                    width: "30%",
                    height: "55px",
                    backgroundColor: "#F5F5F5",
                    color: "#4D575D",
                    border: "1px solid #D7D7D7",
                    fontFamily: "poppins",
                    cursor: "pointer",
                    borderRadius: "11px",
                    textTransform: "none",
                    "&:hover": {
                      boxShadow: "none",
                      backgroundColor: "#F5F5F5",
                    },
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {settlmentMode === "Part" && (
                    <span
                      style={{
                        position: "absolute",
                        top: "5px",
                        right: "5px",
                        width: "10px",
                        height: "10px",
                        backgroundImage: `url(${settleModeSelectedTick})`,
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        borderRadius: "50%",
                      }}
                    ></span>
                  )}
                  <Box
                    sx={{
                      position: "relative",
                      width: "100%",
                      height: "70%",
                    }}
                  >
                    <img
                      src={settleModePartImage}
                      alt="Upi"
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        objectFit: "contain",
                      }}
                    />
                  </Box>
                  <Typography
                    variant="body1"
                    sx={{ fontFamily: "poppins", fontSize: "11px" }}
                  >
                    {switchedLanguage ? "الجزء" : "Part"}
                  </Typography>
                </Button>
              </Box>

              <Box>
                <Box sx={{ marginBottom: "15px" }}>
                  <Typography
                    sx={{
                      fontFamily: "poppins",
                      marginBottom: "6px",
                      color: "#4D575D",
                      fontWeight: "bold",
                      fontSize: "16px",
                    }}
                  >
                    {switchedLanguage
                      ? "تم استلام أموال العميل"
                      : "Customer Paid"}
                  </Typography>
                  <TextField
                    variant="standard"
                    onChange={(e) => setInputPrice(parseFloat(e.target.value))}
                    disabled={disableFields ? true : false}
                    InputProps={{
                      disableUnderline: true,
                    }}
                    inputProps={{
                      style: { fontFamily: "poppins" },
                    }}
                    sx={{
                      width: "100%",
                      fontFamily: "poppins",
                      borderRadius: "18px",
                      backgroundColor: "#F2F3F7",
                      border: "1px solid #F0F0F0",
                      outline: "none",
                      height: "45px",
                      display: "flex",
                      justifyContent: "center",
                      padding: "20px",
                    }}
                  />
                </Box>
              </Box>

              <Box>
                <Typography
                  sx={{
                    fontFamily: "poppins",
                    marginBottom: "6px",
                    color: "#4D575D",
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  {switchedLanguage ? "تقسيم" : "Split"}
                </Typography>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      gap: "10px",
                      alignItems: "center",
                      border: "1px solid #F0F0F0",
                      width: "20%",
                      borderRadius: "18px",
                      backgroundColor: "#F2F3F7",
                      // justifyContent:"flex-end"
                    }}
                  >
                    <button
                      onClick={() => setSplitCount(splitCount - 1)}
                      disabled={splitCount === 1 ? true : false}
                      style={{
                        border: "none",
                        height: "38px",
                        width: "48px",
                        borderRadius: "18px",
                        backgroundColor: "#F2F3F7",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                    >
                      <RemoveIcon />
                    </button>
                    <Typography sx={{ fontFamily: "poppins" }}>
                      {switchedLanguage
                        ? splitCount.toLocaleString("ar-EG")
                        : splitCount}
                    </Typography>
                    <button
                      disabled={disableFields ? true : false}
                      onClick={() => setSplitCount(splitCount + 1)}
                      style={{
                        border: "none",
                        fontFamily: "poppins",
                        height: "38px",
                        width: "48px",
                        borderRadius: "18px",
                        backgroundColor: "#F2F3F7",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",

                        cursor: "pointer",
                      }}
                    >
                      <AddIcon />
                    </button>
                  </Box>
                  <Box>
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        fontFamily: "poppins",
                        fontSize: "15px",
                        display: "flex",
                        alignSelf: "flex-end",
                      }}
                    >
                      {switchedLanguage ? "مبلغ مقسم" : "Splitted Amount"}:{" "}
                      {switchedLanguage && splitCount < 2
                        ? Number(0).toLocaleString("ar-EG")
                        : switchedLanguage && splittedAmount
                        ? splittedAmount.toLocaleString("ar-EG", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })
                        : splittedAmount?.toFixed(2)}
                    </Typography>
                  </Box>
                </Box>
              </Box>

              <Box
                sx={{
                  marginTop: "8px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontFamily: "poppins",
                    fontSize: "15px",
                  }}
                >
                  {switchedLanguage ? "المجموع" : "Total"}:{" "}
                  {switchedLanguage
                    ? grandTotal.toLocaleString("ar-EG", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : grandTotal.toFixed(2)}
                </Typography>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontFamily: "poppins",
                    fontSize: "15px",
                  }}
                >
                  {switchedLanguage ? "الرصيد" : "Balance"}:{" "}
                  {switchedLanguage
                    ? balance.toLocaleString("ar-EG", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : balance.toFixed(2)}
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  gap: "15px",
                  justifyContent: "center",
                  marginTop: "20px",
                }}
              >
                <Button
                  onClick={handleCloseBtn}
                  variant="contained"
                  sx={{
                    boxShadow: "none",
                    width: "35%",
                    height: "50px",
                    backgroundColor: "#FFFFFF",
                    color: "#4D575D",
                    border: "1px solid #F0F0F0",
                    fontFamily: "poppins",
                    cursor: "pointer",
                    borderRadius: "11px",
                    textTransform: "none",
                    "&:hover": {
                      boxShadow: "none",
                      backgroundColor: "#FFFFFF",
                    },
                  }}
                >
                  {switchedLanguage ? "إلغاء" : "Cancel"}
                </Button>
                <Button
                  onClick={handleConfirm}
                  variant="contained"
                  sx={{
                    boxShadow: "none",
                    width: "35%",
                    height: "50px",
                    backgroundColor: "#f40000",
                    color: "#f2f3f8",
                    border: "none",
                    fontFamily: "poppins",
                    cursor: "pointer",
                    borderRadius: "11px",
                    textTransform: "none",
                    "&:hover": {
                      boxShadow: "none",
                      backgroundColor: "#f40000",
                    },
                  }}
                >
                  {switchedLanguage ? "التسوية" : "Settle"}
                </Button>
              </Box>
            </Box>
          </Paper>
        </Modal>
      }
    </>
  );
};

export default BillingSettlePopup;
