export const ItemReducer = (initialState = [], action) => {
  switch (action.type) {
    case "recentOrderSingleItem":
      return action.payload;

    case "reset":
      return action.payload;

    default:
      return initialState;
  }
};
