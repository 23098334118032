import { Button, TextField, Typography } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Box } from "@mui/system";
import { useFormik } from "formik";
import React from "react";
import { addPickupandDeliverySchema } from "../../schemas/index";
import { useSelector } from "react-redux";
import { createPickupAndDelivaeyPrinter, createPickupAndDeliveryPrinter } from "../../service/printerConfiguration";
import { toast } from "react-toastify";

const initialValues = {
  printerType: "Pickup",
  printerIp: "",
};
export default function PickupAndDelivery({ setValue }) {
  const { biller, switchedLanguage  } = useSelector((state) => state);
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: addPickupandDeliverySchema,
    onSubmit: async (values, action) => {
      console.log(values);
      const data = await createPickupAndDeliveryPrinter(values, biller.token);
      if (data.status) {
       switchedLanguage? toast.success("يتم إنشاء الطابعة"): toast.success(data.message);
        setValue("1");
      }
    },
  });

  return (
    <div>
      <Box>
        <Typography sx={{ fontFamily: "poppins" }}>{switchedLanguage ? "إضافة الفواتير": "Add Billing"}</Typography>
        <form onSubmit={formik.handleSubmit}>
          <Box sx={{ display: "flex", gap: "10px" }}>
            <Box sx={{ mt: "20px", width: "50%" }}>
              <Typography sx={{ my: "10px" }} style={{ fontSize: "13px" }}>
              {switchedLanguage ? "نوع الطابعة": "Printer Type"}
              </Typography>
              <FormControl fullWidth>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="printerType"
                  value={formik.values.printerType}
                  onChange={formik.handleChange}
                  error={
                    !!formik.touched.printerType && !!formik.errors.printerType
                  }
                  helperText={
                    formik.touched.printerType && formik.errors.printerType
                  }
                >
                  <MenuItem value={"Pickup"}>{switchedLanguage ? "أمر التقاط" : "Pickup"}</MenuItem>
                  <MenuItem value={"Delivery"}>{switchedLanguage ? "التسليم": "Delivery"}</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box sx={{ my: "20px", width: "50%" }}>
              <Typography sx={{ my: "10px" }} style={{ fontSize: "13px" }}>
              {switchedLanguage ? "بروتوكول الإنترنت للطابعة": "Printer IP"}
              </Typography>
              <TextField
                id="printerIp"
                placeholder={switchedLanguage ? "بروتوكول الإنترنت للطابعة": "Printer IP"}
                variant="outlined"
                name="printerIp"
                style={{ width: "100%" }}
                value={formik.values.printerIp}
                onChange={formik.handleChange}
                error={!!formik.touched.printerIp && !!formik.errors.printerIp}
                helperText={formik.touched.printerIp && formik.errors.printerIp}
              />
            </Box>
          </Box>

          <Box sx={{ display: "flex", justifyContent: "end" }}>
            <Button
              sx={{
                height: "100%",
                fontSize: "16px",
                mt: "3px",
                textTransform: "none",
                color: "var(--font-black)",
                mr: "10px",
                background: "var(--button-gray)",
                "&:hover": {
                  backgroundColor: "var(--button-gray)",
                },
              }}
              variant="text"
            >
              {switchedLanguage ? "إلغاء": "Cancel"}
            </Button>
            <Button
              type="submit"
              sx={{
                height: "100%",
                fontSize: "16px",
                mt: "3px",
                textTransform: "none",
                color: "var(--bg-white)",
                background: "var(--red)",
                "&:hover": {
                  backgroundColor: "var(--red)",
                },
              }}
              variant="text"
            >
              {switchedLanguage ? "حفظ": "Save"}
            </Button>
          </Box>
        </form>
      </Box>
    </div>
  );
}
