import axios from "axios";
const host = process.env.REACT_APP_BACKEND_URL;

export const fetchPendingOrders = async (token) => {
  try {
    const { data } = await axios.get(`${host}/billingFetchPendingOrders`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};
export const fetchPreparingOrders = async (token) => {
  try {
    const { data } = await axios.get(`${host}/billingFetchPreparingOrders`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};
export const fetchReadyOrders = async (token) => {
  try {
    const { data } = await axios.get(`${host}/billingFetchReadyOrders`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};
export const fetchDeliveredOrders = async (token) => {
  try {
    const { data } = await axios.get(`${host}/billingFetchDeliveredOrders`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const fetchDeliveryBoys = async (token) => {
  try {
    const { data } = await axios.get(`${host}/billingFetchDeliveryBoys`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const changeOrderStatus = async (value, id, token) => {
  try {
    const { data } = await axios.put(
      `${host}/billingChangeOrderStatus/${id}`,
      value,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const billingRePrintKotProducts = async (id, token) => {
  try {
    const { data } = await axios.get(
      `${host}/billingRePrintKotProducts/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};
