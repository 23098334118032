import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import AdminSubNavbar from "../../src/components/common/SubNavbar";
import Order from "../../src/components/order/Order";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import { Grid } from "@mui/material";
import { Button } from "@mui/material";
import SearchBar from "../components/common/SearchBar";
import {
  billingRePrintKotProducts,
  changeOrderStatus,
  fetchDeliveredOrders,
  fetchPendingOrders,
  fetchPreparingOrders,
  fetchReadyOrders,
} from "../../src/service/order";
import { useSelector } from "react-redux";
import PendingOrderCard from "../components/order/PendingOrderCard";
import PreparingOrderCard from "../components/order/PreparingOrderCard";
import ReadyOrderCard from "../components/order/ReadyOrderCard";
import DeliveredOrderCard from "../components/order/DeliveredOrderCard";
import { toast, ToastContainer } from "react-toastify";
import { Link } from "react-router-dom";
function AllOrders() {
  const { biller,switchedLanguage  } = useSelector((state) => state);

  const [updateState, setUpdateState] = useState(false);
  const [value, setValue] = useState("1");
  const [pendingOrders, setPendingOrders] = useState([]);
  const [preparingOrders, setPreparingOrders] = useState([]);
  const [readyOrders, setReadyOrders] = useState([]);
  const [deliveredOrders, setDeliveredOrders] = useState([]);
  const navBarItems = [
    {
      label:switchedLanguage ? "الكل": "All",
      value: "1",
    },
    {
      label:switchedLanguage ? "على الطاولة": "Dine in",
      value: "2",
    },
    {
      label:switchedLanguage ? "التسليم": "Delivery",
      value: "3",
    },
    {
      label:switchedLanguage ? "أمر التقاط": "Pickup",
      value: "4",
    },
  ];

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    getAllPendingOrder();
    getAllPreparingOrder();
    getAllReadyOrder();
    getAllDeliveredOrder();
  }, [updateState, value]);

  const getAllPendingOrder = async () => {
    const data = await fetchPendingOrders(biller.token);
    setPendingOrders(data);
  };
  const getAllPreparingOrder = async () => {
    const data = await fetchPreparingOrders(biller.token);
    setPreparingOrders(data);
  };
  const getAllReadyOrder = async () => {
    const data = await fetchReadyOrders(biller.token);
    setReadyOrders(data);
  };
  const getAllDeliveredOrder = async () => {
    const data = await fetchDeliveredOrders(biller.token);
    setDeliveredOrders(data);
  };
  const message = (data) => {
    setUpdateState(!updateState);
    toast(data.message);
  };

  const changeOrderStatusAction = async (id, value) => {
    const values = { orderStatus: value };
    const data = await changeOrderStatus(values, id, biller.token);
    if (data.status) {
      message(data);
    }
  };

  return (
    <Box sx={{ display: "flex", width: "100%" }}>
      <ToastContainer />
      <Box sx={{ width: "100%" }}>
        <Box sx={{ height: "100%" }}>
          <Box>
            <TabContext value={value}>
              <AdminSubNavbar
                handleChange={handleChange}
                items={navBarItems}
                value={value}
              />
              <TabPanel value="1">
                {/* <Grid container>
                  <Grid item xs={3}>
                    <PendingOrderCard
                      orders={pendingOrders}
                      message={message}
                      changeOrderStatusAction={changeOrderStatusAction}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <PreparingOrderCard
                      orders={preparingOrders}
                      changeOrderStatusAction={changeOrderStatusAction}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <ReadyOrderCard
                      orders={readyOrders}
                      changeOrderStatusAction={changeOrderStatusAction}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <DeliveredOrderCard orders={deliveredOrders} />
                  </Grid>
                </Grid> */}
                <TabPanelBody
                  changeOrderStatusAction={changeOrderStatusAction}
                  pendingOrders={pendingOrders}
                  preparingOrders={preparingOrders}
                  readyOrders={readyOrders}
                  deliveredOrders={deliveredOrders}
                  message={message}
                  orderType={"All"}
                />
              </TabPanel>
              <TabPanel value="2">
                <TabPanelBody
                  changeOrderStatusAction={changeOrderStatusAction}
                  pendingOrders={pendingOrders}
                  preparingOrders={preparingOrders}
                  readyOrders={readyOrders}
                  deliveredOrders={deliveredOrders}
                  message={message}
                  orderType={"Dine in"}
                />
              </TabPanel>
              <TabPanel value="3">
                <TabPanelBody
                  changeOrderStatusAction={changeOrderStatusAction}
                  pendingOrders={pendingOrders}
                  preparingOrders={preparingOrders}
                  readyOrders={readyOrders}
                  message={message}
                  deliveredOrders={deliveredOrders}
                  orderType={"Delivery"}
                />
              </TabPanel>
              <TabPanel value="4">
                <TabPanelBody
                  changeOrderStatusAction={changeOrderStatusAction}
                  pendingOrders={pendingOrders}
                  preparingOrders={preparingOrders}
                  message={message}
                  readyOrders={readyOrders}
                  deliveredOrders={deliveredOrders}
                  orderType={"Pickup"}
                />
              </TabPanel>
            </TabContext>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default AllOrders;

function TabPanelBody({
  changeOrderStatusAction,
  pendingOrders,
  preparingOrders,
  readyOrders,
  deliveredOrders,
  message,
  orderType,
}) {
  const { switchedLanguage  } = useSelector((state) => state);
  const [query, setQuery] = useState("");
  const keys = ["orderId", "tableNo", "invoiceNo"];
  return (
    <Box>
      <Box
        sx={{ mx: 0.5, mb: 1 }}
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <SearchBar
          searchBarWidth={"200px"}
          placeholder={switchedLanguage ? "رقم فاتورة البحث": "Search Invoice No."}
          onChange={(e) => setQuery(e.target.value.toLowerCase())}
        />
        <Button
          variant="contained"
          sx={{
            textTransform: "none",
            background: "var(--red)",
            "&:hover": {
              backgroundColor: "var(--red)",
            },
          }}
          component={Link}
          to={"/kot"}
        >
          {switchedLanguage ? "عرض أمر المطبخ": "Kot View"}
        </Button>
      </Box>
      <Grid container>
        <Grid item xs={3}>
          <PendingOrderCard
            changeOrderStatusAction={changeOrderStatusAction}
            orders={pendingOrders}
            message={message}
            type={orderType}
            query={query}
            keys={keys}
          />
        </Grid>
        <Grid item xs={3}>
          <PreparingOrderCard
            changeOrderStatusAction={changeOrderStatusAction}
            orders={preparingOrders}
            type={orderType}
            query={query}
            keys={keys}
          />
        </Grid>
        <Grid item xs={3}>
          <ReadyOrderCard
            changeOrderStatusAction={changeOrderStatusAction}
            orders={readyOrders}
            type={orderType}
            query={query}
            keys={keys}
          />
        </Grid>
        <Grid item xs={3}>
          <DeliveredOrderCard
            changeOrderStatusAction={changeOrderStatusAction}
            orders={deliveredOrders}
            type={orderType}
            query={query}
            keys={keys}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
