import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { Route, Routes } from "react-router-dom";
import LoggedInRoutes from "././routes/LoggedInRoutes";
import NotLoggedInRoutes from "././routes/NotLoggedInRoutes";
import LoginPage from "./pages/LoginPage";
import PrinterConfiguration from "./pages/PrinterConfiguration";
import AllOrders from "./pages/AllOrders";
import NavabrSetting from "./components/navBar/NavabrSetting";
import PosTable from "./pages/PosTable";
import PosBilling from "./pages/PosBilling";
import Kot from "./pages/Kot";
import DayEndSummary from "./pages/DayEndSummary";
import Box from "@mui/material/Box";
import { useMediaQuery } from "@mui/material";
import MobileViewErrorMessage from "./pages/MobileViewErrorMessage";
import { useSelector } from "react-redux";

function App() {
  const { switchedLanguage } = useSelector((state) => state);
  // const isSmallScreen = useMediaQuery("(max-width: 600px)");
  const isLargeScreen = useMediaQuery("(min-width: 1100px)");
  return (
    <Box>
      {isLargeScreen ? (
        <Box
          style={{
            display: "flex",
          }}
        >
          <NavabrSetting />
          <ToastContainer />
          <Routes>
            <Route element={<LoggedInRoutes />}>
              <Route path="/" exact element={<PosTable />} />
              <Route path="/billing" exact element={<PosBilling />} />
              <Route path="/kot" exact element={<Kot />} />
              <Route
                path="/printerconfiguration"
                exact
                element={<PrinterConfiguration />}
              />
              <Route path="/dayendsummary" exact element={<DayEndSummary />} />
              <Route path="/orders" exact element={<AllOrders />} />
            </Route>
            <Route element={<NotLoggedInRoutes />}>
              <Route path="/login" element={<LoginPage />} />
            </Route>
          </Routes>
        </Box>
      ) : (
        <MobileViewErrorMessage />
      )}
    </Box>
  );
}
export default App;
