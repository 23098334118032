import {
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { addBillingSchema } from "../../schemas/index";
import { useSelector } from "react-redux";
import { getAllTables } from "../../service/admin";
import { createBilling, getBillings } from "../../service/printerConfiguration";
import { toast } from "react-toastify";

const initialValues = {
  areaName: "",
  printerIp: "",
  tables: [],
};
export default function Billing({ setValue }) {
  const { biller,switchedLanguage  } = useSelector((state) => state);
  const [allTables, setAllTables] = useState();
  const [seletedTables, setSeletedTables] = useState([]);
  const fetchData = async () => {
    const data = await getAllTables(biller.token, biller.vendor._id);
    setAllTables(data);
  };
  const fetchSelectedTable = async () => {
    let table = [];
    const data = await getBillings(biller.token);
    data.map((item) => {
      item.tables.map((tab) => {
        table.push(tab.table);
      });
    });
    setSeletedTables(table);
  };
  useEffect(() => {
    fetchData();
    fetchSelectedTable();
  }, []);
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: addBillingSchema,
    onSubmit: async (values, action) => {
      let tables = [];
      if (values.tables.length > 0) {
        tables = values.tables.map((table) => {
          return { table: table };
        });
      }
      let billerData = {
        areaName: values.areaName,
        printerIp: values.printerIp,
        tables: tables,
      };
      const data = await createBilling(billerData, biller.token);
      if (data.status) {
        switchedLanguage? toast.success("يتم إضافة الفواتير"):toast.success(data.message);
        setValue("1");
      }
    },
  });
  const selectTable = (id) => {
    if (formik.values.tables.includes(id)) {
      formik.setValues({
        ...formik.values,
        tables: formik.values.tables.filter((item) => {
          return item !== id;
        }),
      });
    } else {
      formik.setValues({
        ...formik.values,
        tables: [...formik.values.tables, id],
      });
    }
  };
  const selectAllTable = (allTables, cheked) => {
    let tableId = [];
    if (cheked) {
      allTables.map((item) => {
        if (!seletedTables.includes(item._id)) {
          tableId.push(item._id);
        }
      });
    }
    formik.setFieldValue("tables", tableId);
  };


  // Function to convert a number to Arabic numerals
  function toArabicNumerals(number) {
    const arabicNumerals = ["٠", "١", "٢", "٣", "٤", "٥", "٦", "٧", "٨", "٩"];
    return String(number).replace(/\d/g, (digit) => arabicNumerals[digit]);
  }

  return (
    <div>
      <Box>
        <Typography sx={{ fontFamily: "poppins" }}>{switchedLanguage ? "إضافة الفواتير": "Add Billing"}</Typography>
        <form onSubmit={formik.handleSubmit}>
          <Box sx={{ display: "flex", gap: "10px" }}>
            <Box sx={{ mt: "20px", width: "50%" }}>
              <Typography sx={{ my: "10px" }} style={{ fontSize: "13px" }}>
                {switchedLanguage ? "اسم المنطقة": "Area Name"}
              </Typography>
              <TextField
                id="areaName"
                placeholder={switchedLanguage ? "اسم المنطقة": "Area Name"}
                variant="outlined"
                name="areaName"
                style={{ width: "100%" }}
                value={formik.values.areaName}
                onChange={formik.handleChange}
                error={!!formik.touched.areaName && !!formik.errors.areaName}
                helperText={formik.touched.areaName && formik.errors.areaName}
              />
            </Box>
            <Box sx={{ my: "20px", width: "50%" }}>
              <Typography
                sx={{ my: "10px",  }}
                style={{ fontSize: "13px" }}
              >
                {switchedLanguage ? "بروتوكول الإنترنت للطابعة": "Printer IP"}
              </Typography>
              <TextField
                id="printerIp"
                placeholder={switchedLanguage ? "بروتوكول الإنترنت للطابعة": "Printer IP"}
                variant="outlined"
                name="printerIp"
                style={{ width: "100%" }}
                value={formik.values.printerIp}
                onChange={formik.handleChange}
                error={!!formik.touched.printerIp && !!formik.errors.printerIp}
                helperText={formik.touched.printerIp && formik.errors.printerIp}
              />
            </Box>
          </Box>
          <Box>
            <Box
              sx={{
                width: "100%",
                border: "solid var(--border-gray) 1px",
                height: "40px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                background: "var(--table-head)",
                px: "30px",
              }}
            >
              <Typography sx={{ fontSize: "14px", fontWeight: "bold" }}>
                {switchedLanguage ? "الجدول": "Table"}
              </Typography>
              <FormControlLabel
                control={<Checkbox />}
                label={switchedLanguage ? "اختر الكل": "Select All"}
                labelPlacement="end"
                onChange={(e) => selectAllTable(allTables, e.target.checked)}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: "30px",
                my: "20px",
              }}
            >
              {allTables?.map((item) => (
                <FormControlLabel
                  disabled={seletedTables.includes(item._id)}
                  checked={formik.values.tables.includes(item._id)}
                  control={<Checkbox />}
                  label={
                    <Typography style={{ fontFamily: "poppins" }}>
                      {switchedLanguage ? "الجدول": "Table"}-{switchedLanguage ? toArabicNumerals(item.tableNo):  item.tableNo}
                    </Typography>
                  }
                  labelPlacement={item.groupName}
                  onChange={(e) => selectTable(item._id)}
                />
              ))}
            </Box>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "end" }}>
            <Button
              sx={{
                height: "100%",
                fontSize: "16px",
                mt: "3px",
                textTransform: "none",
                color: "var(--font-black)",
                mr: "10px",
                background: "var(--button-gray)",
                "&:hover": {
                  backgroundColor: "var(--button-gray)",
                },
              }}
              variant="text"
            >
              {switchedLanguage ? "إلغاء": "Cancel"}
            </Button>
            <Button
              type="submit"
              sx={{
                height: "100%",
                fontSize: "16px",
                mt: "3px",
                textTransform: "none",
                color: "var(--bg-white)",
                background: "var(--red)",
                "&:hover": {
                  backgroundColor: "var(--red)",
                },
              }}
              variant="text"
            >
              {switchedLanguage ? "حفظ": "Save"}
            </Button>
          </Box>
        </form>
      </Box>
    </div>
  );
}
