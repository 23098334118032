import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React, { useState, useEffect } from "react";
import { getAllOrders } from "../../service/admin";
import { useSelector, useDispatch } from "react-redux";
import { getAllKotProducts } from "../../service/kot";
import { useNavigate } from "react-router-dom";

const RecentOrderTablist = ({
  activeRecentBtn,
  setOpenRecentOrderModal,
  clickedOrderTypeActiveBtn,
  recentSearchTerm,
}) => {
  const [recentOrder, setRecentOrder] = useState([]);
  const { biller, switchedLanguage } = useSelector((state) => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleRecentOrderItems = (item) => {
    navigate("/billing");
    const newArray = [item];

    dispatch({ type: "recentOrderSingleItem", payload: newArray });
    dispatch({
      type: "clickedOrderTypeActiveBtn",
      payload: item.typeofOrder ? item.typeofOrder : clickedOrderTypeActiveBtn,
    });
    setOpenRecentOrderModal(false);
  };

  // Currency
  const currency =
    switchedLanguage && biller.vendor.country == "UAE"
      ? "الدرهم"
      : switchedLanguage && biller.vendor.country == "KSA"
      ? "ريال"
      : !switchedLanguage && biller.vendor.country == "IND"
      ? "₹"
      : "";

  useEffect(() => {
    handleRecentOrder();
  }, [activeRecentBtn]);

  const handleRecentOrder = async () => {
    if (activeRecentBtn === "KOT") {
      let res = await getAllKotProducts(biller.token);

      setRecentOrder(res.reverse());
    } else {
      let res = await getAllOrders(biller.token, activeRecentBtn);
      setRecentOrder(res.getAllOrders);
    }
  };

  return (
    <>
      <TableContainer
        sx={{
          height: "75vh",
          overflowX: "scroll",
          "&::-webkit-scrollbar": {
            display: "none", // Hide scrollbar
          },
        }}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow sx={{ backgroundColor: "#4D575D" }}>
              <TableCell
                sx={{
                  textAlign: "center",
                  fontFamily: "poppins",
                  color: "var(--font-white)",
                  fontSize: "14px",
                  backgroundColor: "#4D575D",
                }}
              >
                {switchedLanguage ? "رقم الفاتورة" : "Invoice No."}
              </TableCell>

              {activeRecentBtn !== "Pickup" &&
                activeRecentBtn !== "Delivery" && (
                  <TableCell
                    sx={{
                      textAlign: "center",
                      fontFamily: "poppins",
                      color: "var(--font-white)",
                      fontSize: "14px",
                      backgroundColor: "#4D575D",
                    }}
                  >
                    {switchedLanguage ? "الجدول" : "Table"}
                  </TableCell>
                )}

              <TableCell
                sx={{
                  textAlign: "center",
                  fontFamily: "poppins",
                  color: "var(--font-white)",
                  fontSize: "14px",
                  backgroundColor: "#4D575D",
                }}
              >
                {switchedLanguage ? "السعر" : "Price"}
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {recentOrder

              ?.filter((item) => {
                if (activeRecentBtn === "KOT") {
                  return item?.orderId?.invoiceNo
                    ?.toLowerCase()
                    ?.includes(recentSearchTerm);
                } else {
                  return item?.invoiceNo
                    ?.toLowerCase()
                    ?.includes(recentSearchTerm);
                }
              })
              .map((item, index) => {
                const latinNumber = item.tableNo;
                const arabicNumber = latinNumber
                  ?.toString()
                  .replace(/[0-9]/g, function (match) {
                    const arabicDigits = [
                      "٠",
                      "١",
                      "٢",
                      "٣",
                      "٤",
                      "٥",
                      "٦",
                      "٧",
                      "٨",
                      "٩",
                    ];
                    return arabicDigits[parseInt(match)];
                  });

                //For invoice number
                const latinNumber1 = item?.invoiceNo?.invoiceNo
                  ? item.invoiceNo.invoiceNo?.toString()
                  : null;
                const arabicNumber1 = latinNumber1
                  ? latinNumber1.replace(
                      /[0-9]/g,
                      (match) => "٠١٢٣٤٥٦٧٨٩"[parseInt(match)]
                    )
                  : null;

                const latinNumber2 = item?.invoiceNo
                  ? item.invoiceNo?.toString()
                  : null;
                const arabicNumber2 = latinNumber2
                  ? latinNumber2.replace(
                      /[0-9]/g,
                      (match) => "٠١٢٣٤٥٦٧٨٩"[parseInt(match)]
                    )
                  : null;
                return (
                  <TableRow
                    key={index}
                    sx={{
                      backgroundColor: "#D9D9D9",
                      cursor: activeRecentBtn === "KOT" ? "" : "pointer",
                      "&:hover": {
                        backgroundColor:
                          activeRecentBtn === "KOT" ? "#D9D9D9" : "#F2F3F8",
                      },
                    }}
                    onClick={() => {
                      if (activeRecentBtn !== "KOT") {
                        handleRecentOrderItems(item);
                      }
                    }}
                  >
                    <TableCell
                      sx={{
                        textAlign: "center",
                        fontFamily: "Poppins",
                        color: "var(--font-black)",
                        fontSize: "14px",
                      }}
                    >
                      {switchedLanguage
                        ? latinNumber1
                          ? arabicNumber1
                          : arabicNumber2
                        : item?.invoiceNo?.invoiceNo
                        ? item?.invoiceNo?.invoiceNo
                        : item?.invoiceNo}
                    </TableCell>
                    {activeRecentBtn !== "Pickup" &&
                      activeRecentBtn !== "Delivery" && (
                        <TableCell
                          sx={{
                            textAlign: "center",
                            fontFamily: "poppins",
                            color: "var(--font-black)",
                            fontSize: "14px",
                          }}
                        >
                          {switchedLanguage ? arabicNumber : item.tableNo}
                        </TableCell>
                      )}

                    <TableCell
                      sx={{
                        textAlign: "center",
                        fontFamily: "poppins",
                        color: "var(--font-black)",
                        fontSize: "14px",
                      }}
                    >
                      {switchedLanguage ? "" : currency}
                      {switchedLanguage
                        ? item?.orderId?.grandTotal
                          ? item?.orderId?.grandTotal.toLocaleString("ar-EG")
                          : item?.grandTotal.toLocaleString("ar-EG")
                        : item?.orderId?.grandTotal
                        ? item?.orderId?.grandTotal
                        : item?.grandTotal}{" "}
                      {switchedLanguage ? currency : ""}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default RecentOrderTablist;
