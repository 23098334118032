import React from "react";
import TableContainer from "../components/table/TableContainer";

export default function PosTable() {
  return (
    <>
      <div
        style={{
          marginTop: "65px",
          display: "flex",
        }}
      >
        <TableContainer />
      </div>
    </>
  );
}
