import React, { useEffect, useState, useRef } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

import { Divider, Modal, Paper } from "@mui/material";
// import { getAllOrders } from "../../service/admin";
// import { getAllKotProducts } from "../../service/kot";

import { useSelector } from "react-redux";
import RecentOrderTablist from "./RecentOrderTablist";

const RecentOrderModal = ({
  openRecentOrderModal,
  setOpenRecentOrderModal,
}) => {
  const { switchedLanguage } = useSelector((state) => state);
  const [activeRecentBtn, setActiveRecentBtn] = useState("Dine in");
  const [recentSearchTerm, setRecentSearchTerm] = useState("");

  const inputRef = useRef(null); // Create a ref for the input field

  const handleOpenRecentModal = () => {
    setOpenRecentOrderModal(true);
  };
  const handleCloseRecentModal = () => {
    setRecentSearchTerm("");
    setOpenRecentOrderModal(false);
  };

  useEffect(() => {
    setActiveRecentBtn("Dine in");
    // let allOrders = async () => {
    //   let res = await getAllOrders(biller.token, activeRecentBtn);
    //   console.log(res,"3")
    //   setRecentOrder(res.getAllOrders);
    // };
    // allOrders();
  }, [openRecentOrderModal]);

  useEffect(() => {
    inputRef.current?.focus();
  }, [recentSearchTerm]);

  const handleActiveRecentBtn = (event, newValue) => {
    setRecentSearchTerm("");
    setActiveRecentBtn(newValue);
  };

  return (
    <Modal
      open={handleOpenRecentModal}
      onClose={handleCloseRecentModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Paper sx={{ border: "none" }}>
        <Box
          sx={{
            position: "absolute",
            right: "0",
            bottom: 0,
            height: "93%",
            backgroundColor: "background.paper",
            width: "25em",
            borderRadius: "5px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                fontFamily: "poppins",
                fontSize: "18px",
                padding: "15px",
              }}
            >
              {switchedLanguage ? "النظام الأخير" : "Recent Order"}
            </Typography>

            <input
              ref={inputRef} // Assign the ref to the input field
              onChange={(e) => {
                setRecentSearchTerm(e.target.value);
              }}
              value={recentSearchTerm}
              style={{
                height: "40px",
                padding: "10px",
                borderRadius: "15px",
                border: "1px solid #8B8B8B",
                outline: "none",
                marginRight: "10px",
                marginLeft: "10px",
              }}
              type="text"
              placeholder={switchedLanguage ? "رقم الفاتورة" : "Invoice No."}
            />
          </Box>
          <Divider />
          <Box>
            <TabContext value={activeRecentBtn}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                  TabIndicatorProps={{
                    style: {
                      background: "none",
                    },
                  }}
                  onChange={handleActiveRecentBtn}
                  aria-label="lab API tabs example"
                >
                  <Tab
                    onClick={() => {
                      setActiveRecentBtn("Dine in");
                    }}
                    sx={{
                      "&.Mui-selected": {
                        background: "var(--red)",
                        color: "var(--font-white)",
                      },
                      boxShadow: "none",
                      fontWeight: "400",

                      fontFamily: "poppins",
                      fontSize: "14px",
                      width: "25%",
                      borderRadius: "0px",
                      height: "51px",
                      background:
                        activeRecentBtn === "Dine in"
                          ? "var(--red)"
                          : "#D9D9D9",
                      "&:hover": {
                        boxShadow: "none",
                        fontWeight: "400",
                        backgroundColor:
                          activeRecentBtn === "Dine in"
                            ? "var(--red)"
                            : "#D9D9D9",
                      },
                      textTransform: "none",
                      color:
                        activeRecentBtn === "Dine in"
                          ? "var(font-white)"
                          : "var(--font-black)",
                    }}
                    label={switchedLanguage ? "على الطاولة" : "Dine in"}
                    value="Dine in"
                  ></Tab>
                  <Tab
                    onClick={() => {
                      setActiveRecentBtn("Pickup");
                    }}
                    sx={{
                      "&.Mui-selected": {
                        background: "var(--red)",
                        color: "var(--font-white)",
                      },
                      boxShadow: "none",
                      fontWeight: "400",
                      fontFamily: "poppins",
                      fontSize: "14px",
                      width: "25%",
                      borderRadius: "0px",
                      height: "51px",
                      background:
                        activeRecentBtn === "Pickup" ? "var(--red)" : "#D9D9D9",
                      "&:hover": {
                        boxShadow: "none",
                        fontWeight: "400",
                        backgroundColor:
                          activeRecentBtn === "Pickup"
                            ? "var(--red)"
                            : "#D9D9D9",
                      },
                      textTransform: "none",
                      color:
                        activeRecentBtn === "Pickup"
                          ? "var(font-white)"
                          : "var(--font-black)",
                    }}
                    label={switchedLanguage ? "أمر التقاط" : "Pickup"}
                    value="Pickup"
                  />

                  <Tab
                    onClick={() => {
                      setActiveRecentBtn("Delivery");
                    }}
                    sx={{
                      "&.Mui-selected": {
                        background: "var(--red)",
                        color: "var(--font-white)",
                      },

                      boxShadow: "none",
                      fontWeight: "400",
                      fontFamily: "poppins",
                      fontSize: "14px",
                      width: "25%",
                      borderRadius: "0px",
                      height: "51px",
                      background:
                        activeRecentBtn === "Delivery"
                          ? "var(--red)"
                          : "#D9D9D9",
                      "&:hover": {
                        boxShadow: "none",
                        fontWeight: "400",
                        backgroundColor:
                          activeRecentBtn === "Delivery"
                            ? "var(--red)"
                            : "#D9D9D9",
                      },
                      textTransform: "none",
                      color:
                        activeRecentBtn === "Delivery"
                          ? "var(font-white)"
                          : "var(--font-black)",
                    }}
                    label={switchedLanguage ? "التسليم" : "Delivery"}
                    value="Delivery"
                  />
                  <Tab
                    onClick={() => {
                      setActiveRecentBtn("KOT");
                    }}
                    sx={{
                      "&.Mui-selected": {
                        background: "var(--red)",
                        color: "var(--font-white)",
                      },
                      fontWeight: "400",
                      boxShadow: "none",

                      fontFamily: "poppins",
                      fontSize: "14px",
                      width: "25%",
                      borderRadius: "0px",
                      height: "51px",
                      background:
                        activeRecentBtn === "KOT" ? "var(--red)" : "#D9D9D9",
                      "&:hover": {
                        boxShadow: "none",
                        fontWeight: "400",
                        backgroundColor:
                          activeRecentBtn === "KOT" ? "var(--red)" : "#D9D9D9",
                      },
                      textTransform: "none",
                      color:
                        activeRecentBtn === "KOT"
                          ? "var(font-white)"
                          : "var(--font-black)",
                    }}
                    label={switchedLanguage ? "المطبخ" : "KOT"}
                    value="KOT"
                  />
                </TabList>
              </Box>
              <TabPanel value="Dine in" sx={{ padding: "0" }}>
                <RecentOrderTablist
                  activeRecentBtn={activeRecentBtn}
                  setActiveRecentBtn={setActiveRecentBtn}
                  setOpenRecentOrderModal={setOpenRecentOrderModal}
                  recentSearchTerm={recentSearchTerm}
                />
              </TabPanel>
              <TabPanel value="Pickup" sx={{ padding: "0" }}>
                <RecentOrderTablist
                  activeRecentBtn={activeRecentBtn}
                  setActiveRecentBtn={setActiveRecentBtn}
                  setOpenRecentOrderModal={setOpenRecentOrderModal}
                  recentSearchTerm={recentSearchTerm}
                />
              </TabPanel>
              <TabPanel value="Delivery" sx={{ padding: "0" }}>
                <RecentOrderTablist
                  activeRecentBtn={activeRecentBtn}
                  setActiveRecentBtn={setActiveRecentBtn}
                  setOpenRecentOrderModal={setOpenRecentOrderModal}
                  recentSearchTerm={recentSearchTerm}
                />
              </TabPanel>
              <TabPanel value="KOT" sx={{ padding: "0" }}>
                <RecentOrderTablist
                  activeRecentBtn={activeRecentBtn}
                  setActiveRecentBtn={setActiveRecentBtn}
                  setOpenRecentOrderModal={setOpenRecentOrderModal}
                  recentSearchTerm={recentSearchTerm}
                />
              </TabPanel>
            </TabContext>
          </Box>
        </Box>
      </Paper>
    </Modal>
  );
};

export default RecentOrderModal;
