import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { Button, Card, Divider, Modal, Paper } from "@mui/material";

const HoldOrderModal = ({ handleOpenHoldModal, handleCloseHoldModal }) => {
  return (
    <>
      <Modal
        open={handleOpenHoldModal}
        onClose={handleCloseHoldModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Paper sx={{ border: "none" }}>
          <Box
            sx={{
              position: "absolute",
              right: "0",
              bottom: 0,
              height: "93%",
              padding: "10px",
              backgroundColor: "background.paper",
              width: "25em",
              borderRadius: "5px",
            }}
          >
            <Typography
              sx={{
                fontFamily: "poppins",
                fontSize: "18px",
                padding: "15px",
              }}
            >
              On Hold
            </Typography>
            <Divider />
            <Card
              sx={{
                marginTop: "10px",
                display: "flex",
                justifyContent: "space-between",
                padding: "15px",
              }}
            >
              <Box
                sx={{
                  // padding: "15px",
                  display: "flex",
                  gap: "10px",
                  flexDirection: "column",
                  width: "50%",
                  textAlign: "start",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "poppins",
                    fontSize: "14px",
                    fontWeight: "400",
                  }}
                >
                  Hold No: 1
                </Typography>
                <Button
                  variant="contained"
                  sx={{
                    textTransform: "none",
                    color: "var(--font-white)",
                    backgroundColor: "#FD7A7A",
                    "&:hover": {
                      backgroundColor: "#FD7A7A",
                    },
                    fontFamily: "poppins",
                    cursor: "pointer",
                    textAlign: "center",
                    width: "50%",
                  }}
                >
                  Dine-in
                </Button>
                <Box>
                  <ul
                    style={{
                      listStyle: "none",
                      margin: "0",
                      padding: "0",
                      fontFamily: "poppins",
                      fontSize: "14px",
                    }}
                  >
                    <li>1x Burger</li>
                    <li>2x Shawarma</li>
                  </ul>

                  <Typography sx={{ cursor: "pointer" }}>...</Typography>
                </Box>
              </Box>

              <Box
                sx={{
                  width: "50%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <Box
                  sx={{
                    gap: "10px",
                    textAlign: "end",
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: "400",
                      color: "#868899",
                    }}
                  >
                    16 Mar 2023 14:09:44
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: "400",
                      color: "#868899",
                    }}
                  >
                    Table: 1
                  </Typography>
                </Box>
                <Button
                  variant="contained"
                  sx={{
                    textTransform: "none",
                    backgroundColor: "#D9D9D9",
                    "&:hover": {
                      backgroundColor: "#D9D9D9",
                    },
                    fontFamily: "poppins",
                    cursor: "pointer",
                    textAlign: "center",
                    width: "100%",
                    color: "#878787",
                  }}
                >
                  Discard
                </Button>
              </Box>
            </Card>
          </Box>
        </Paper>
      </Modal>
    </>
  );
};

export default HoldOrderModal;
