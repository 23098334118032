import React, { useState, useRef, useEffect } from "react";
import { Box, Modal, Paper, Typography, Button } from "@mui/material";
import TextareaAutosize from "@mui/base/TextareaAutosize";
import { useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";

const BillingCommentPopup = ({
  setComments,
  handleOpenCommentModal,
  handleCloseCommentModal,
  comments,
}) => {
  const [instructions, setInstructions] = useState(comments ? comments : "");
  const handleConfirmCommentPopup = () => {
    setComments(instructions);
    handleCloseCommentModal();
  };

  const { switchedLanguage } = useSelector((state) => state);

  return (
    <>
      {
        <Modal
          open={handleOpenCommentModal}
          onClose={handleCloseCommentModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Paper
            sx={{ border: "none", display: "flex", justifyContent: "center" }}
          >
            <Box
              sx={{
                position: "absolute",
                left: "50%",
                padding: "26px",
                backgroundColor: "background.paper",
                width: "25em",
                borderRadius: "5px",
                top: "50%",
                transform: "translate(-50%,-50%)",
                display: "flex",
                flexDirection: "column",
                gap: "5px",
                borderRadius: "17px",
              }}
            >
              <Box sx={{ marginBottom: "25px" }}>
                <sup
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <CloseIcon
                    onClick={handleCloseCommentModal}
                    sx={{
                      fontFamily: "poppins",
                      fontSize: "18px",
                      cursor: "pointer",
                      fontWeight: "bold",
                    }}
                  />
                </sup>
                <Typography
                  sx={{
                    fontFamily: "poppins",
                    fontSize: "17px",
                    fontWeight: "600",
                    textAlign: "center",
                  }}
                >
                  {switchedLanguage ? "التعليقات": "Comment"}
                </Typography>
              </Box>

              <Box>
                <Box sx={{ marginBottom: "5px" }}>
                  <Typography
                    sx={{
                      fontFamily: "poppins",
                      marginBottom: "6px",
                      color: "#4D575D",
                    }}
                  >
                    {switchedLanguage ? "تعليمات": "Instructions"}
                  </Typography>
                  <TextareaAutosize
                    // value={comments}
                    value={instructions}
                    onChange={(e) => setInstructions(e.target.value)}
                    // onChange={(e) => setComments(e.target.value)}
                    placeholder= {switchedLanguage ? "إضافة تعليمات أو أي شيء ...": "Add Instructions or anything..."}
                    style={{
                      height: "180px",
                      width: "100%",
                      padding: "15px",
                      resize: "none",
                      outline: "none",
                      border: "1px solid #F0F0F0",
                      backgroundColor: "#F2F3F7",
                      borderRadius: "18px",
                    }}
                  />
                </Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  gap: "10px",
                  justifyContent: "space-between",
                  //   marginTop: "30px",
                }}
              >
                <Button
                  onClick={handleCloseCommentModal}
                  variant="contained"
                  sx={{
                    boxShadow: "none",
                    width: "48%",
                    height: "43px",
                    backgroundColor: "#FFFFFF",
                    color: "#4D575D",
                    border: "1px solid #F0F0F0",
                    fontFamily: "poppins",
                    cursor: "pointer",
                    borderRadius: "11px",
                    textTransform: "none",
                    "&:hover": {
                      boxShadow: "none",
                      backgroundColor: "#FFFFFF",
                    },
                  }}
                >
                  {switchedLanguage ? "إلغاء": "Cancel"}
                </Button>
                <Button
                  onClick={handleConfirmCommentPopup}
                  variant="contained"
                  sx={{
                    boxShadow: "none",
                    width: "48%",
                    height: "43px",
                    backgroundColor: "#f40000",
                    color: "#f2f3f8",
                    border: "none",
                    fontFamily: "poppins",
                    cursor: "pointer",
                    borderRadius: "11px",
                    textTransform: "none",
                    "&:hover": {
                      boxShadow: "none",
                      backgroundColor: "#f40000",
                    },
                  }}
                >
                  {switchedLanguage ? "تأكيد": "Confirm"}
                </Button>
              </Box>
            </Box>
          </Paper>
        </Modal>
      }
    </>
  );
};

export default BillingCommentPopup;
