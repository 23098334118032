import axios from "axios";
const host = process.env.REACT_APP_BACKEND_URL;

export const getIncomeBasedOnPaymentMethod = async (token) => {
  try {
    const { data } = await axios.get(`${host}/billerGetIncomeBasedOnPaymentMethod`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};
export const getSalesByGroup = async (token) => {
  try {
    const { data } = await axios.get(`${host}/billerGetSalesByGroup`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};
export const getPurchaseSummary = async (token) => {
  try {
    const { data } = await axios.get(`${host}/billerGetPurchaseSummary`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};
