import { Box, Button, Modal, Paper, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import { useSelector } from "react-redux";

export default function FoodVariantModal({
  handleOpenFoodVariPopup,
  handleCloseFoodVariPopup,
  showFoodVariPopup,
  setShowFoodVariPopup,
  addOrder,
  storeOrder,
  setStoreOrder,
  items,
  setItems,
  updation,
  setUpdation,

  varientValueBtn,
  setVarientValueBtn,

  addOnsBtn,
  setAddOnsBtn,
}) {
  const { switchedLanguage } = useSelector((state) => state);
  const [showOptions, setShowOptions] = useState(false);
  const [addOnsList, setAddOnsList] = useState([]);

  const [addOnOptions, setAddOnOptions] = useState([]);

  const [uniqueValues, setUniqueValues] = useState({
    value1: [],
    value2: [],
    value3: [],
    value4: [],
    value5: [],
  });

  useEffect(() => {
    const uniqueValue1 = [
      ...new Set(
        addOrder.varients?.map((item) => item.varientId?.value1).filter(Boolean)
      ),
    ];

    const uniqueValue2 = [
      ...new Set(
        addOrder.varients?.map((item) => item.varientId?.value2).filter(Boolean)
      ),
    ];

    const uniqueValue3 = [
      ...new Set(
        addOrder.varients?.map((item) => item.varientId?.value3).filter(Boolean)
      ),
    ];

    const uniqueValue4 = [
      ...new Set(
        addOrder.varients?.map((item) => item.varientId?.value4).filter(Boolean)
      ),
    ];

    const uniqueValue5 = [
      ...new Set(
        addOrder.varients?.map((item) => item.varientId?.value5).filter(Boolean)
      ),
    ];

    setUniqueValues({
      ...uniqueValues,
      value1: uniqueValue1,
      value2: uniqueValue2,
      value3: uniqueValue3,
      value4: uniqueValue4,
      value5: uniqueValue5,
    });
  }, [addOrder.varients]);

  useEffect(() => {
    setVarientValueBtn({
      value1: addOrder.varients[0]?.varientId?.value1,
      value2: addOrder.varients[0]?.varientId?.value2,
      value3: addOrder.varients[0]?.varientId?.value3,
      value4: addOrder.varients[0]?.varientId?.value4,
      value5: addOrder.varients[0]?.varientId?.value5,
    });
  }, []);

  useEffect(() => {
    if (addOrder.varients?.length > 0) {
      const { value1, value2, value3, value4, value5 } = varientValueBtn;

      const newName = [value1, value2, value3, value4, value5]
        .filter(Boolean)
        .join(" ");

      const selectedVariant = addOrder?.varients?.find(
        (variant) =>
          [
            variant.varientId?.value1,
            variant.varientId?.value2,
            variant.varientId?.value3,
            variant.varientId?.value4,
            variant.varientId?.value5,
          ]
            .filter(Boolean)
            .join(" ") === newName
      );

      if (selectedVariant) {
        setStoreOrder({
          ...storeOrder,
          productId: addOrder._id,
          itemName: `${addOrder.title
            .toLowerCase()
            .replace(/\b\w/g, (c) => c.toUpperCase())} ${newName
            .toLowerCase()
            .replace(/\b\w/g, (c) => c.toUpperCase())}`,
          varientId: selectedVariant.varientId._id,
          salePrice: selectedVariant.varientId.salePrice,
        });
      }
    }
  }, [addOrder, varientValueBtn]);

  const handleOrderedItem = () => {
    const initialProduct = [...items];
    const { varientId, addOns, salePrice, quantity } = storeOrder;
    const addOnsExist = addOns.length > 0;

    // Find the index of the product in the array
    const index = initialProduct.findIndex(
      (item) => item.varientId === varientId
    );

    if (index !== -1) {
      // If the product already exists, update the add-on quantities or add new add-ons
      if (addOnsExist) {
        addOns.forEach((newAddOn) => {
          const existingAddOnIndex = initialProduct[index].addOns.findIndex(
            (existingAddOn) => existingAddOn.valueId === newAddOn.valueId
          );

          if (existingAddOnIndex !== -1) {
            // If the add-on already exists, update the quantity
            initialProduct[index].addOns[existingAddOnIndex].quantity +=
              newAddOn.quantity;
          } else {
            // If the add-on doesn't exist, add it to the array
            initialProduct[index].addOns.push(newAddOn);
          }
        });
      }

      // Update the quantity and calculate the new sub-total for the product
      initialProduct[index].quantity += quantity;
      initialProduct[index].subTotal = calcSubTotal(
        salePrice,
        initialProduct[index].quantity,
        initialProduct[index].addOns
      );
    } else {
      // If the product doesn't exist, add a new entry to the array
      const subTotal = calcSubTotal(salePrice, quantity, addOns);
      initialProduct.push({
        ...storeOrder,
        subTotal: subTotal,
      });
    }

    setItems(initialProduct);
    setUpdation(!updation);

    handleCloseFoodVariPopup();
    setUniqueValues({
      value1: [],
      value2: [],
      value3: [],
      value4: [],
      value5: [],
    });

    setVarientValueBtn({
      btnValue1: null,
      btnValue2: null,
      btnValue3: null,
      btnValue4: null,
      btnValue5: null,
    });

    setAddOnsBtn(null);
    setAddOnOptions([]);
    setStoreOrder({
      itemName: "",
      varientId: "",
      salePrice: "",
      quantity: 1,
      addOns: [],
    });
  };

  const calcSubTotal = (salePrice, quantity, addOns) => {
    let subTotal = salePrice * quantity;

    if (addOns.length > 0) {
      addOns.forEach((addOn) => {
        subTotal += addOn.price * addOn.quantity;
      });
    }

    return subTotal;
  };

  // To handle showing addOns
  const handleAddOns = (addOn, btn) => {
    setAddOnsBtn(btn);
    setAddOnsList(addOn);
    setShowOptions(true);
  };

  const handleOptionsBtn = (item, index, btn) => {
    setAddOnOptions((prevOptions) => [...prevOptions, btn]);

    const existingAddOnIndex = storeOrder.addOns.findIndex(
      (addOn) =>
        addOn.addOnId === addOnsList.addOnId._id &&
        addOn.valueId === item.item._id
    );

    if (existingAddOnIndex !== -1) {
      // If the add-on already exists, update the quantity
      const newAddOns = [...storeOrder.addOns];
      newAddOns[existingAddOnIndex].quantity += 1;
      setStoreOrder({
        ...storeOrder,
        addOns: newAddOns,
      });
    } else {
      // If the add-on doesn't exist, add it to the array
      setStoreOrder({
        ...storeOrder,
        addOns: [
          ...storeOrder.addOns,
          {
            addOnId: addOnsList.addOnId._id,
            addOnName: addOnsList.addOnId.title,
            name: item.item.title,
            price: item.item.price,
            valueId: item.item._id,
            quantity: 1,
          },
        ],
      });
    }
  };

  return (
    <>
      {showFoodVariPopup && (
        <Modal
          open={handleOpenFoodVariPopup}
          onClose={handleCloseFoodVariPopup}
        >
          <Paper
            sx={{ border: "none", display: "flex", justifyContent: "center" }}
          >
            <Box
              sx={{
                position: "absolute",
                left: "50%",
                padding: "25px",
                backgroundColor: "background.paper",
                minWidth: "40em",
                // maxWidth:"50em",
                borderRadius: "5px",
                top: "50%",
                transform: "translate(-50%,-50%)",
                display: "flex",
                flexDirection: "column",
                gap: "5px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "poppins",
                    fontWeight: "400",
                    fontSize: "17px",
                  }}
                >
                  {addOrder.title}
                </Typography>
                <Box
                  sx={{ display: "flex", gap: "10px", alignItems: "center" }}
                >
                  <button
                    disabled={storeOrder.quantity === 1 ? true : false}
                    onClick={() =>
                      setStoreOrder({
                        ...storeOrder,
                        quantity: storeOrder.quantity - 1,
                      })
                    }
                    style={{
                      border: "none",
                      height: "30px",
                      width: "30px",
                      borderRadius: "50%",
                      backgroundColor: "#D9D9D9",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <RemoveIcon />
                  </button>
                  <Typography sx={{ fontFamily: "poppins" }}>
                    {switchedLanguage ? storeOrder.quantity.toLocaleString("ar-EG") : storeOrder.quantity}
                  </Typography>
                  <button
                    onClick={() =>
                      setStoreOrder({
                        ...storeOrder,
                        quantity: storeOrder.quantity + 1,
                      })
                    }
                    style={{
                      border: "none",
                      fontFamily: "poppins",
                      height: "30px",
                      width: "30px",
                      borderRadius: "50%",
                      backgroundColor: "#F40000",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      color: "#fff",
                      cursor: "pointer",
                    }}
                  >
                    <AddIcon />
                  </button>
                </Box>
              </Box>

              {addOrder.varients?.length > 0 && (
                <>
                  <Typography sx={{ fontFamily: "poppins", fontWeight: "400" }}>
                    {switchedLanguage ? "البنود البديلة": "Varients"}
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      gap: "10px",
                      flexWrap: "wrap",
                      // justifyContent: "center",
                    }}
                  >
                    {uniqueValues?.value1?.map((val, index) => {
                      return (
                        <Button
                          onClick={() => {
                            setVarientValueBtn({
                              ...varientValueBtn,
                              value1: val,
                            });
                          }}
                          key={index}
                          variant="outlined"
                          sx={{
                            fontFamily: "poppins",
                            color:
                              varientValueBtn.value1 === val ? "red" : "#000",
                            borderColor:
                              varientValueBtn.value1 === val
                                ? "red"
                                : "#AFAFAF",
                            "&:hover": {
                              borderColor: "red",
                              color: "red",
                            },
                          }}
                        >
                          {val}
                        </Button>
                      );
                    })}
                  </Box>

                  {uniqueValues?.value2?.length > 0 && (
                    <Box
                      sx={{
                        display: "flex",
                        gap: "10px",
                        flexWrap: "wrap",
                        // justifyContent: "center",
                      }}
                    >
                      {uniqueValues?.value2?.map((val, index) => {
                        return (
                          <Button
                            onClick={() => {
                              setVarientValueBtn({
                                ...varientValueBtn,
                                value2: val,
                              });
                            }}
                            key={index}
                            variant="outlined"
                            sx={{
                              fontFamily: "poppins",
                              color:
                                varientValueBtn.value2 === val ? "red" : "#000",
                              borderColor:
                                varientValueBtn.value2 === val
                                  ? "red"
                                  : "#AFAFAF",
                              "&:hover": {
                                borderColor: "red",
                                color: "red",
                              },
                            }}
                          >
                            {val}
                          </Button>
                        );
                      })}
                    </Box>
                  )}

                  {uniqueValues?.value3?.length > 0 && (
                    <Box
                      sx={{
                        display: "flex",
                        gap: "10px",
                        flexWrap: "wrap",
                        // justifyContent: "center",
                      }}
                    >
                      {uniqueValues?.value3?.map((val, index) => {
                        return (
                          <Button
                            onClick={() => {
                              setVarientValueBtn({
                                ...varientValueBtn,
                                value3: val,
                              });
                            }}
                            key={index}
                            variant="outlined"
                            sx={{
                              fontFamily: "poppins",
                              color:
                                varientValueBtn.value3 === val ? "red" : "#000",
                              borderColor:
                                varientValueBtn.value3 === val
                                  ? "red"
                                  : "#AFAFAF",
                              "&:hover": {
                                borderColor: "red",
                                color: "red",
                              },
                            }}
                          >
                            {val}
                          </Button>
                        );
                      })}
                    </Box>
                  )}

                  {uniqueValues?.value4?.length > 0 && (
                    <Box
                      sx={{
                        display: "flex",
                        gap: "10px",
                        flexWrap: "wrap",
                        // justifyContent: "center",
                      }}
                    >
                      {uniqueValues?.value4?.map((val, index) => {
                        return (
                          <Button
                            onClick={() => {
                              setVarientValueBtn({
                                ...varientValueBtn,
                                value4: val,
                              });
                            }}
                            key={index}
                            variant="outlined"
                            sx={{
                              fontFamily: "poppins",
                              color:
                                varientValueBtn.value4 === val ? "red" : "#000",
                              borderColor:
                                varientValueBtn.value4 === val
                                  ? "red"
                                  : "#AFAFAF",
                              "&:hover": {
                                borderColor: "red",
                                color: "red",
                              },
                            }}
                          >
                            {val}
                          </Button>
                        );
                      })}
                    </Box>
                  )}

                  {uniqueValues.value5?.length > 0 && (
                    <Box
                      sx={{
                        display: "flex",
                        gap: "10px",
                        flexWrap: "wrap",
                        // justifyContent: "center",
                      }}
                    >
                      {uniqueValues?.value5?.map((val, index) => {
                        return (
                          <Button
                            onClick={() => {
                              setVarientValueBtn({
                                ...varientValueBtn,
                                value5: val,
                              });
                            }}
                            key={index}
                            variant="outlined"
                            sx={{
                              fontFamily: "poppins",
                              color:
                                varientValueBtn.value5 === val ? "red" : "#000",
                              borderColor:
                                varientValueBtn.value5 === val
                                  ? "red"
                                  : "#AFAFAF",
                              "&:hover": {
                                borderColor: "red",
                                color: "red",
                              },
                            }}
                          >
                            {val}
                          </Button>
                        );
                      })}
                    </Box>
                  )}
                </>
              )}

              {addOrder.addOns.length > 0 && (
                <>
                  <Typography sx={{ fontFamily: "poppins" }}>
                    {switchedLanguage ? "إضافات": "Add ons"}
                  </Typography>

                  <Box sx={{ display: "flex", gap: "10px", flexWrap: "wrap" }}>
                    {addOrder.addOns.map((item, index) => {
                      return (
                        <Button
                          onClick={() => handleAddOns(item, item.addOnId.title)}
                          key={index}
                          variant="outlined"
                          sx={{
                            fontFamily: "poppins",
                            color:
                              addOnsBtn === item.addOnId.title ? "red" : "#000",
                            borderColor:
                              addOnsBtn === item.addOnId.title
                                ? "red"
                                : "#AFAFAF",
                            "&:hover": {
                              borderColor: "red",
                              color: "red",
                            },
                          }}
                        >
                          {item.addOnId.title}
                        </Button>
                      );
                    })}
                  </Box>
                </>
              )}

              {showOptions && (
                <>
                  <Typography sx={{ fontFamily: "poppins" }}>
                    {switchedLanguage ? "الخيارات": "Options"}
                  </Typography>

                  <Box
                    sx={{
                      display: "flex",
                      gap: "10px",
                      flexWrap: "wrap",

                      "& > Button:focus": {
                        borderColor: "red",
                        color: "red",
                      },
                    }}
                  >
                    {addOnsList.addOnId.items.map((item, index) => {
                      // const isFocused = focusedButtons.includes(index);

                      return (
                        <Button
                          onClick={() => {
                            handleOptionsBtn(item, index, item.item.title);
                          }}
                          key={index}
                          variant="outlined"
                          sx={{
                            fontFamily: "poppins",
                            color: addOnOptions.includes(item?.item?.title)
                              ? "red"
                              : "#000",
                            borderColor: addOnOptions.includes(
                              item?.item?.title
                            )
                              ? "red"
                              : "#AFAFAF",

                            "&:hover": {
                              color: "red",
                              borderColor: "red",
                            },
                          }}
                        >
                          {item.item.title}
                        </Button>
                      );
                    })}
                  </Box>
                </>
              )}

              <Box
                sx={{
                  display: "flex",
                  gap: "10px",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  onClick={handleCloseFoodVariPopup}
                  variant="outlined"
                  sx={{
                    fontFamily: "poppins",
                    borderColor: "#AFAFAF",
                    color: "#000",
                    "&:hover": {
                      borderColor: "red",
                    },
                  }}
                >
                  {switchedLanguage ? "إلغاء": "Cancel"}
                </Button>
                <Button
                  onClick={handleOrderedItem}
                  variant="contained"
                  sx={{
                    fontFamily: "poppins",
                    backgroundColor: "red",
                    "&:hover": {
                      backgroundColor: "red",
                    },
                  }}
                >
                  {switchedLanguage ? "تم": "Done"}
                </Button>
              </Box>
            </Box>
          </Paper>
        </Modal>
      )}
    </>
  );
}
