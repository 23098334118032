import * as Yup from "yup";

export const loginSchema = Yup.object({
  phone: Yup.string().matches(
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
    "Phone number is not valid"
  ),
  password: Yup.string().required("Password is required"),
});

export const addKotSchema = Yup.object({
  kotName: Yup.string().required("Kot Name is required"),
  printer: Yup.string().required("Printer is required"),
});

export const addBillingSchema = Yup.object({
  areaName: Yup.string().required("Printer Name is required"),
  printerIp: Yup.string().required("PrinterIp is required"),
});

export const addPickupandDeliverySchema = Yup.object({
  printerType: Yup.string().required("Printer Type is required"),
  printerIp: Yup.string().required("PrinterIp is required"),
});

export const changeOrderStatusSchema = Yup.object({
  DeliveryBoyId: Yup.string().required("Delivery Boy is required"),
  DeliveryTime: Yup.number().required("Time is required"),
});
