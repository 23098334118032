import React from "react";
import { Box, Modal, Paper, Typography, Button } from "@mui/material";
import { useSelector } from "react-redux";

const PleaseChooseProductPopup = ({
  handleOpenChooseProduct,
  handleCloseChooseProduct,
}) => {
  const { switchedLanguage } = useSelector((state) => state);
  const handleOkBtn = () => {
    handleCloseChooseProduct();
  };
  return (
    <>
      {
        <Modal
          open={handleOpenChooseProduct}
          onClose={handleCloseChooseProduct}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Paper
            sx={{ border: "none", display: "flex", justifyContent: "center" }}
          >
            <Box
              sx={{
                position: "absolute",
                left: "50%",
                padding: "26px",
                backgroundColor: "background.paper",
                width: "25em",
                borderRadius: "5px",
                top: "50%",
                transform: "translate(-50%,-50%)",
                display: "flex",
                flexDirection: "column",
                gap: "5px",
                borderRadius: "17px",
                paddingTop: "35px ",
              }}
            >
              <Box sx={{ marginBottom: "25px" }}>
                <Typography
                  sx={{
                    fontFamily: "poppins",
                    fontSize: "20px",
                    fontWeight: "600",
                    textAlign: "center",
                  }}
                >
                  {switchedLanguage ? "الرجاء اختيار منتج!": "Please choose a product!"}
                </Typography>
              </Box>

              <Box>
                <Box
                  sx={{
                    marginBottom: "5px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "15px",
                  }}
                ></Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  gap: "10px",
                  justifyContent: "center",
                }}
              >
                <Button
                  onClick={handleOkBtn}
                  variant="contained"
                  sx={{
                    boxShadow: "none",
                    width: "30%",
                    height: "43px",
                    backgroundColor: "#f40000",
                    color: "#f2f3f8",
                    border: "none",
                    fontFamily: "poppins",
                    cursor: "pointer",
                    borderRadius: "11px",
                    textTransform: "none",
                    "&:hover": {
                      boxShadow: "none",
                      backgroundColor: "#f40000",
                    },
                  }}
                >
                  {switchedLanguage ? "حسنا": "Ok"}
                </Button>
              </Box>
            </Box>
          </Paper>
        </Modal>
      }
    </>
  );
};

export default PleaseChooseProductPopup;
