import axios from "axios";

export const printInvoice = async (datas) => {
  try {
    const { data } = await axios.post(
      "http://localhost:2361/printInvoice",
      datas
    );
    console.log(data);
    return data;
  } catch (error) {
    return { error, status: false };
  }
};

export const printKot = async (datas) => {
  try {
    const { data } = await axios.post("http://localhost:2361/printKot", {
      data: datas,
    });
    return data;
  } catch (error) {
    return { error, status: false };
  }
};
