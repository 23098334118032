import React, { useEffect, useState } from "react";
import { Box, Modal, Paper, Typography, Divider, Button } from "@mui/material";
import TextField from "@mui/material/TextField";
import { useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopTimePicker } from "@mui/x-date-pickers/DesktopTimePicker";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import dayjs from "dayjs";
import {
  getAllTables,
  getReservedTables,
  reserveTable,
} from "../../service/admin";
import { toast } from "react-toastify";

const ReserveTableModal = ({
  handleOpenReserveTableModal,
  handleCloseReserveTableModal,
  guests,
  setGuests,
  handleSetReservedDate,
  handleSetReservedTime,
  tableReservation,
}) => {
  const { biller, recentOrderClickedItem, switchedLanguage } = useSelector(
    (state) => state
  );

  const [allTables, setAllTables] = useState([]);
  const [tableList, setTableList] = useState([]);
  const [filteredTables, setFilteredTables] = useState([]);
  const [reservedData, setReservedData] = useState([]);
  const [reservedTables, setReservedTables] = useState([]);
  // const [guests, setGuests] = useState(1);
  const [reservationDetails, setReservedDetails] = useState({
    name: null,
    mobile: null,
  });

  useEffect(() => {
    fetchAllTables();
    checkReservedTables();
  }, []);

  useEffect(() => {
    fetchFilteredTables();
  }, [tableList]);

  useEffect(() => {
    checkReservedTables();
    allReservedTables();
  }, [tableReservation.reservedTime, tableReservation.reservedDate]);

  const fetchAllTables = async () => {
    let res = await getAllTables(biller.token, biller.vendor._id);
    setAllTables(res);
    setFilteredTables(res);
  };

  const fetchFilteredTables = () => {
    let filteredTables = allTables.filter(
      (tbl) => !tableList?.some((selectedTbl) => selectedTbl._id === tbl._id)
    );
    setFilteredTables(filteredTables);
  };

  const allReservedTables = async () => {
    let res = await getReservedTables(biller.token);
    setReservedData(res);
  };

  const checkReservedTables = () => {
    if (tableReservation.reservedDate || tableReservation.reservedTime) {
      const notReservedTables = reservedData.filter((tbls) => {
        if (tbls.reservedDate == tableReservation.reservedDate) {
          if (
            tbls.bufferTime.split(" - ")[0] < tableReservation.reservedTime &&
            tbls.bufferTime.split(" - ")[1] > tableReservation.reservedTime
          ) {
            return tbls;
          }
        }
      });
      let selectTable = [];
      notReservedTables.map((tbl) => {
        tbl.tables.map((item) => {
          selectTable.push(item.table._id);
        });
      });
      setReservedTables(selectTable);
    }
  };
  const handleAddTable = (tbl) => {
    if (!tableReservation.reservedTime || !tableReservation.reservedDate) {
      toast.error(
        switchedLanguage
          ? "يرجى تحديد التاريخ أو الوقت"
          : "Please select date or time!"
      );
    } else {
      setTableList([...tableList, tbl]);
    }
  };

  const handleDeleteTable = (tbl) => {
    let filterTable = tableList.filter((t) => t._id !== tbl._id);
    setTableList(filterTable);
  };

  const handleReserveTableConfirm = async () => {
    if (!reservationDetails.name || !reservationDetails.mobile) {
      toast.error(
        switchedLanguage
          ? "الاسم أو حقول الهاتف المحمول إلزامية"
          : "Name or mobile fields are mandatory!"
      );
    } else if (
      reservationDetails.mobile.length < 10 ||
      isNaN(reservationDetails.mobile)
    ) {
      toast.error(
        switchedLanguage
          ? "يرجى إدخال رقم الهاتف المحمول صالح"
          : "Please enter valid mobile number!"
      );
    } else if (!tableList.length) {
      toast.error(
        switchedLanguage
          ? "يرجى اختيار الجداول لحجز"
          : "Please select tables to reserve!"
      );
    } else {
      // Convert time strings to Date objects
      const reservedTime = convertTo24HourFormat(
        tableReservation?.reservedTime
      );
      const businessStartTime = convertTo24HourFormat(
        biller.vendor.businessStartTime
      );
      const businessEndTime = convertTo24HourFormat(
        biller.vendor.businessEndTime
      );
      if (businessEndTime < businessStartTime) {
        if (
          reservedTime >= businessStartTime ||
          reservedTime <= businessEndTime
        ) {
          const tbl = tableList.map((tb) => {
            return tb._id;
          });

          const data = {
            name: reservationDetails.name,
            mobile: reservationDetails.mobile,
            vendorId: biller.vendor._id,
            billerId: biller.id,
            isReserved: true,
            reservedTime: tableReservation.reservedTime,
            reservedDate: tableReservation.reservedDate,
            bufferTime: tableReservation.bufferTime,
            tables: tbl,
            noOfGuests: guests,
          };

          const res = await reserveTable(biller.token, data);

          if (res.status) {
            switchedLanguage
              ? toast.success("يتم حجز الجدول بنجاح")
              : toast.success(res.message);

            handleCloseReserveTableModal();
          } else {
            toast.error(res.message);
          }
        } else {
          toast.error(
            switchedLanguage
              ? "وقت الحجز خارج ساعات العمل"
              : "Reservation time is outside the business hours!"
          );
        }
      } else {
        if (
          reservedTime >= businessStartTime &&
          reservedTime <= businessEndTime
        ) {
          const tbl = tableList.map((tb) => {
            return tb._id;
          });

          const data = {
            name: reservationDetails.name,
            mobile: reservationDetails.mobile,
            vendorId: biller.vendor._id,
            billerId: biller.id,
            isReserved: true,
            reservedTime: tableReservation.reservedTime,
            reservedDate: tableReservation.reservedDate,
            bufferTime: tableReservation.bufferTime,
            tables: tbl,
            noOfGuests: guests,
          };

          const res = await reserveTable(biller.token, data);

          if (res.status) {
            switchedLanguage
              ? toast.success("يتم حجز الجدول بنجاح")
              : toast.success(res.message);

            handleCloseReserveTableModal();
          } else {
            toast.error(res.message);
          }
        } else {
          toast.error(
            switchedLanguage
              ? "وقت الحجز خارج ساعات العمل"
              : "Reservation time is outside the business hours!"
          );
        }
      }
    }
    //  else {
    //   toast.error("Reservation time is outside the business hours!")
    // }
  };

  // Function to convert AM/PM format to 24-hour format
  function convertTo24HourFormat(timeString) {
    const timeParts = timeString?.split(":");
    let hours = parseInt(timeParts[0]);
    const minutes = parseInt(timeParts[1]);

    return new Date().setHours(hours, minutes, 0, 0);
  }

  // Function to convert a number to Arabic numerals
  function toArabicNumerals(number) {
    const arabicNumerals = ["٠", "١", "٢", "٣", "٤", "٥", "٦", "٧", "٨", "٩"];
    return String(number).replace(/\d/g, (digit) => arabicNumerals[digit]);
  }

  return (
    <>
      {
        <Modal
          open={handleOpenReserveTableModal}
          onClose={handleCloseReserveTableModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Paper
            sx={{ border: "none", display: "flex", justifyContent: "center" }}
          >
            <Box
              sx={{
                position: "absolute",
                left: "50%",
                padding: "26px",
                backgroundColor: "background.paper",
                width: "35em",
                borderRadius: "5px",
                top: "50%",
                transform: "translate(-50%,-50%)",
                display: "flex",
                flexDirection: "column",
                gap: "5px",
                borderRadius: "17px",
              }}
            >
              <Box sx={{ marginBottom: "25px" }}>
                <sup
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <CloseIcon
                    onClick={handleCloseReserveTableModal}
                    sx={{
                      fontFamily: "poppins",
                      fontSize: "18px",
                      cursor: "pointer",
                      fontWeight: "bold",
                    }}
                  />
                </sup>
                <Typography
                  sx={{
                    fontFamily: "poppins",
                    fontSize: "19px",
                    fontWeight: "600",
                    textAlign: "center",
                  }}
                >
                  {switchedLanguage ? "الجدول الاحتياطي" : "Reserve Table"}
                </Typography>
              </Box>

              <Box>
                <Box
                  sx={{
                    display: "flex",
                    gap: "20px",
                    justifyContent: "space-between",
                  }}
                >
                  <Box sx={{ marginBottom: "20px" }}>
                    <Typography
                      sx={{
                        fontFamily: "poppins",
                        marginBottom: "6px",
                      }}
                    >
                      {switchedLanguage ? "الاسم" : "Name"}
                    </Typography>
                    <TextField
                      variant="standard"
                      value={tableReservation.name}
                      onChange={(e) =>
                        setReservedDetails({
                          ...reservationDetails,
                          name: e.target.value,
                        })
                      }
                      InputProps={{
                        disableUnderline: true,
                      }}
                      sx={{
                        width: "100%",
                        fontFamily: "poppins",
                        borderRadius: "18px",
                        backgroundColor: "#F2F3F7",
                        border: "1px solid #F0F0F0",
                        outline: "none",
                        height: "45px",
                        display: "flex",
                        justifyContent: "center",
                        padding: "20px",
                        overflow: "scroll",
                        "&::-webkit-scrollbar": {
                          display: "none", // Hide scrollbar
                        },
                      }}
                    ></TextField>
                  </Box>

                  <Box sx={{ marginBottom: "20px" }}>
                    <Typography
                      sx={{
                        fontFamily: "poppins",
                        marginBottom: "6px",
                      }}
                    >
                      {switchedLanguage ? "الهاتف المحمول" : "Mobile"}
                    </Typography>
                    <TextField
                      value={tableReservation.mobile}
                      variant="standard"
                      type="number"
                      onChange={(e) =>
                        setReservedDetails({
                          ...reservationDetails,
                          mobile: e.target.value,
                        })
                      }
                      InputProps={{
                        disableUnderline: true,
                      }}
                      sx={{
                        width: "100%",
                        fontFamily: "poppins",
                        borderRadius: "18px",
                        backgroundColor: "#F2F3F7",
                        border: "1px solid #F0F0F0",
                        outline: "none",
                        height: "45px",
                        display: "flex",
                        justifyContent: "center",
                        padding: "20px",
                        overflow: "scroll",
                        "&::-webkit-scrollbar": {
                          display: "none", // Hide scrollbar
                        },
                      }}
                    ></TextField>
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    gap: "20px",
                    justifyContent: "space-between",
                  }}
                >
                  <Box sx={{ marginBottom: "25px" }}>
                    <Typography
                      sx={{
                        fontFamily: "poppins",
                        marginBottom: "6px",
                      }}
                    >
                      {switchedLanguage ? "التاريخ" : "Date"}
                    </Typography>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        value={tableReservation.reservedDate}
                        onChange={handleSetReservedDate}
                        sx={{
                          width: "100%",
                          fontFamily: "poppins",
                          borderRadius: "18px",
                          backgroundColor: "#F2F3F7",
                          border: "1px solid #F0F0F0",
                          outline: "none",
                          height: "45px",
                          display: "flex",
                          justifyContent: "center",
                          cursor: "pointer",
                          padding: "0",
                          scrollBehavior: "none",
                          "&:focus": {
                            border: "1px solid #F0F0F0",
                          },
                          ".MuiOutlinedInput-input": {
                            fontFamily: "poppins",
                            height: "9px",
                            borderRadius: "18px",
                          },
                          ".MuiInputBase-root": {
                            fontFamily: "poppins",
                            borderRadius: "18px",
                            "&.MuiInputBase-colorPrimary": {
                              "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                {
                                  border: "1px solid #F0F0F0",
                                },
                            },
                          },
                          ".MuiIconButton-root": {
                            color: "red",
                          },
                        }}
                        renderInput={(props) => (
                          <input
                            {...props}
                            value={tableReservation.reservedDate || ""}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Box>

                  <Box sx={{ marginBottom: "25px" }}>
                    <Typography
                      sx={{
                        fontFamily: "poppins",
                        marginBottom: "6px",
                      }}
                    >
                      {switchedLanguage ? "الوقت" : "Time"}
                    </Typography>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopTimePicker
                        value={tableReservation.reservedTime}
                        onChange={handleSetReservedTime}
                        sx={{
                          width: "100%",
                          fontFamily: "poppins",
                          borderRadius: "18px",
                          backgroundColor: "#F2F3F7",
                          border: "1px solid #F0F0F0",
                          outline: "none",
                          height: "45px",
                          display: "flex",
                          justifyContent: "center",
                          cursor: "pointer",
                          padding: "0",
                          "&:focus": {
                            border: "1px solid #F0F0F0",
                          },
                          ".MuiOutlinedInput-input": {
                            fontFamily: "poppins",
                            height: "9px",
                            borderRadius: "18px",
                          },
                          ".MuiInputBase-root": {
                            fontFamily: "poppins",
                            borderRadius: "18px",
                            "&.MuiInputBase-colorPrimary": {
                              "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                {
                                  border: "1px solid #F0F0F0",
                                },
                            },
                          },
                          ".MuiIconButton-root": {
                            color: "red",
                          },
                          "& .MuiMultiSectionDigitalClock-root": {
                            "&::-webkit-scrollbar": {
                              display: "none",
                            },
                          },
                        }}
                        renderInput={(props) => (
                          <input
                            {...props}
                            value={tableReservation.reservedTime || ""}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    gap: "20px",
                    justifyContent: "space-between",
                  }}
                >
                  <Box sx={{ marginBottom: "20px", width: "100%" }}>
                    <Typography
                      sx={{
                        fontFamily: "poppins",
                        marginBottom: "6px",
                      }}
                    >
                      {switchedLanguage ? "إضافة الجداول" : "Add Tables"}
                    </Typography>
                    <FormControl fullWidth sx={{ height: "40px" }}>
                      <Select
                        sx={{
                          width: "100%",
                          height: "45px",
                          border: "1px solid #F0F0F0",
                          borderRadius: "18px",
                        }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        onChange={(e) => handleAddTable(e.target.value)}
                      >
                        {filteredTables?.map((tbl, index) => (
                          <MenuItem
                            key={index}
                            sx={{ fontFamily: "poppins" }}
                            value={tbl}
                            disabled={reservedTables.includes(tbl._id)}
                          >
                            {`T-${
                              switchedLanguage
                                ? toArabicNumerals(tbl.tableNo)
                                : tbl.tableNo
                            }`}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                  <Box
                    sx={{
                      marginBottom: "20px",
                      width: "100%",
                      maxWidth: "100%",
                      overflowX: "scroll",
                      "&::-webkit-scrollbar": {
                        display: "none", // Hide scrollbar
                      },
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "poppins",
                        marginBottom: "6px",
                      }}
                    >
                      {switchedLanguage ? "الجداول" : "Tables"}
                    </Typography>
                    <TextField
                      variant="standard"
                      InputProps={{
                        disableUnderline: true,

                        startAdornment: (
                          <div style={{ display: "flex", gap: "10px" }}>
                            {tableList.map((table) => (
                              <button
                                key={table}
                                variant="contained"
                                style={{
                                  display: "flex",
                                  borderRadius: "4px",
                                  alignItems: "center",
                                  gap: "8px",
                                  backgroundColor: "#FFFFFF",
                                  border: "none",
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontFamily: "poppins",
                                    fontSize: "14px",
                                    whiteSpace: "nowrap",
                                  }}
                                >{`T-${
                                  switchedLanguage
                                    ? toArabicNumerals(table.tableNo)
                                    : table.tableNo
                                }`}</Typography>

                                <CloseIcon
                                  onClick={() => handleDeleteTable(table)}
                                  sx={{
                                    fontFamily: "poppins",
                                    fontSize: "13px",
                                    cursor: "pointer",
                                    fontWeight: "bold",
                                  }}
                                />
                              </button>
                            ))}
                          </div>
                        ),
                      }}
                      sx={{
                        width: "100%",
                        fontFamily: "poppins",
                        borderRadius: "18px",
                        backgroundColor: "#F2F3F7",
                        border: "1px solid #F0F0F0",
                        outline: "none",
                        height: "45px",
                        display: "flex",
                        justifyContent: "center",
                        padding: "20px",
                        overflow: "scroll",
                        "&::-webkit-scrollbar": {
                          display: "none", // Hide scrollbar
                        },
                      }}
                    ></TextField>
                  </Box>
                </Box>

                <Box>
                  <Typography
                    sx={{
                      fontFamily: "poppins",
                      marginBottom: "6px",
                    }}
                  >
                    {switchedLanguage ? "ضيف" : "Guest"}
                  </Typography>

                  <Box
                    sx={{ display: "flex", gap: "8px", alignItems: "center" }}
                  >
                    <button
                      onClick={() => setGuests(guests - 1)}
                      disabled={guests === 1 ? true : false}
                      style={{
                        border: "1px solid #F0F0F0",
                        height: "38px",
                        width: "48px",
                        borderRadius: "18px",
                        backgroundColor: "#FFFFFF",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                    >
                      <RemoveIcon />
                    </button>
                    <Typography sx={{ fontFamily: "poppins" }}>
                      {switchedLanguage
                        ? guests.toLocaleString("ar-EG")
                        : guests}
                    </Typography>
                    <button
                      onClick={() => setGuests(guests + 1)}
                      style={{
                        border: "1px solid #F0F0F0",
                        fontFamily: "poppins",
                        height: "38px",
                        width: "48px",
                        borderRadius: "18px",
                        backgroundColor: "#FFFFFF",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",

                        cursor: "pointer",
                      }}
                    >
                      <AddIcon />
                    </button>
                  </Box>
                </Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  gap: "10px",
                  justifyContent: "space-between",
                  marginTop: "15px",
                }}
              >
                <Button
                  onClick={handleCloseReserveTableModal}
                  variant="contained"
                  sx={{
                    boxShadow: "none",
                    width: "48%",
                    height: "48px",
                    backgroundColor: "#FFFFFF",
                    color: "#4D575D",
                    border: "1px solid #F0F0F0",
                    fontFamily: "poppins",
                    cursor: "pointer",
                    borderRadius: "11px",
                    textTransform: "none",
                    "&:hover": {
                      boxShadow: "none",
                      backgroundColor: "#FFFFFF",
                    },
                  }}
                >
                  {switchedLanguage ? "إلغاء" : "Cancel"}
                </Button>
                <Button
                  onClick={handleReserveTableConfirm}
                  variant="contained"
                  sx={{
                    boxShadow: "none",
                    width: "48%",
                    height: "48px",
                    backgroundColor: "#f40000",
                    color: "#f2f3f8",
                    border: "none",
                    fontFamily: "poppins",
                    cursor: "pointer",
                    borderRadius: "11px",
                    textTransform: "none",
                    "&:hover": {
                      boxShadow: "none",
                      backgroundColor: "#f40000",
                    },
                  }}
                >
                  {switchedLanguage ? "تأكيد" : "Confirm"}
                </Button>
              </Box>
            </Box>
          </Paper>
        </Modal>
      }
    </>
  );
};

export default ReserveTableModal;
