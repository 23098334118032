import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Icon from "../../assets/ShopyWellpos.png";
import MenuIcon from "@mui/icons-material/Menu";
import { useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";
import { List, ListItem, ListItemText, Modal, Paper } from "@mui/material";

const SidebarModal = ({ handleOpenSidebarModal, handleCloseSidebarModal }) => {
  const navigate = useNavigate();
  const { switchedLanguage } = useSelector((state) => state);
  return (
    <>
      <Modal
        open={handleOpenSidebarModal}
        onClose={handleCloseSidebarModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Paper sx={{ border: "none" }}>
          <Box
            sx={{
              position: "absolute",
              left: switchedLanguage ? "" : 0,
              right:switchedLanguage ? 0 :"",
              bottom: 0,
              height: "100%",
              backgroundColor: "background.paper",
              width: "15em",
              padding: "10px 0 0 0",
            }}
          >
            <Typography
              variant="h6"
              component="div"
              sx={{
                marginTop: 1,
                color: "var(--font-black)",
                display: "flex",

                justifyContent: "space-around",
              }}
            >
              <MenuIcon
                onClick={handleCloseSidebarModal}
                sx={{ cursor: "pointer" }}
              />
              <img style={{ width: "130px" }} src={Icon} alt="shopywell-icon" />
            </Typography>
            <Box>
              <List>
                <ListItem
                  onClick={() => {
                    navigate("/");
                    handleCloseSidebarModal();
                  }}
                  sx={{
                    "&:hover": {
                      backgroundColor: "#F2F3F8",
                    },
                  }}
                >
                  <ListItemText
                    primary={switchedLanguage ? "قسم الفواتير": "Billing"}
                    sx={{
                      fontFamily: "Poppins",
                      cursor: "pointer",
                    }}
                  />
                </ListItem>
                <ListItem
                  onClick={() => {
                    navigate("/orders");
                    handleCloseSidebarModal();
                  }}
                  sx={{
                    "&:hover": {
                      backgroundColor: "#F2F3F8",
                    },
                  }}
                >
                  <ListItemText
                    primary={switchedLanguage ? "أوامر": "Order"}
                    sx={{ fontFamily: "poppins", cursor: "pointer" }}
                  />
                </ListItem>
                <ListItem
                  onClick={() => {
                    navigate("/printerconfiguration");
                    handleCloseSidebarModal();
                  }}
                  sx={{
                    "&:hover": {
                      backgroundColor: "#F2F3F8",
                    },
                  }}
                >
                  <ListItemText
                    primary={switchedLanguage ? "الإعدادات": "Configuration"}
                    sx={{ fontFamily: "poppins", cursor: "pointer" }}
                  />
                </ListItem>
                <ListItem
                  onClick={() => {
                    navigate("/dayendsummary");
                    handleCloseSidebarModal();
                  }}
                  sx={{
                    "&:hover": {
                      backgroundColor: "#F2F3F8",
                    },
                  }}
                >
                  <ListItemText
                    primary={switchedLanguage ? "ملخص نهاية اليوم": "Day End Summary"}
                    sx={{ fontFamily: "poppins", cursor: "pointer" }}
                  />
                </ListItem>
              </List>
            </Box>
          </Box>
        </Paper>
      </Modal>
    </>
  );
};

export default SidebarModal;
