import { Toolbar } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import SubNavbar from "../components/common/SubNavbar";
import { TabContext, TabPanel } from "@mui/lab";
import AllPrintes from "../components/PrinterConfiguration/AllPrintes";
import AddKot from "../components/PrinterConfiguration/AddKot";
import Billing from "../components/PrinterConfiguration/Billing";
import PickupAndDelivery from "../components/PrinterConfiguration/PickupAndDelivery";
import { useSelector } from "react-redux";

export default function PrinterConfiguration() {
  const { switchedLanguage } = useSelector((state) => state);
  const [value, setValue] = useState("1");

  const navBarItems = [
    {
      label: switchedLanguage ? "الكل": "All",
      value: "1",
    },
    {
      label: switchedLanguage ? "المطبخ": "Kot",
      value: "2",
    },
    {
      label: switchedLanguage ? "الفواتير": "Billing",
      value: "3",
    },
    {
      label: switchedLanguage ? "التقاط / التسليم": "Pickup/Delivery",
      value: "4",
    },
  ];
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div style={{ height: "100%", width: "100%" }}>
      <div>
        <TabContext value={value}>
          <SubNavbar
            sx={{
              mx: "auto",
              width: "90%",
            }}
            items={navBarItems}
            handleChange={handleChange}
            value={value}
          />
          <Box sx={{ mx: "auto", width: "90%" }}>
            <TabPanel value="1">
              <AllPrintes setValue={setValue} />
            </TabPanel>
            <TabPanel value="2">
              <AddKot setValue={setValue} />
            </TabPanel>
            <TabPanel value="3">
              <Billing setValue={setValue} />
            </TabPanel>
            <TabPanel value="4">
              <PickupAndDelivery setValue={setValue} />
            </TabPanel>
          </Box>
        </TabContext>
      </div>
    </div>
  );
}
