import React, { useState } from "react";
import { Box, Modal, Paper, Typography, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

const BillingUserDetailsPopup = ({
  setUserData,
  handleOpenUserModal,
  handleCloseUserModal,
  userData,
  orderTypeActiveBtn,
}) => {
  const { switchedLanguage } = useSelector((state) => state);

  const [inputs, setInputs] = useState({
    name: userData?.name ? userData.name : "",
    mobile: userData?.mobile ? userData.mobile : "",
    locality: userData?.locality ? userData.locality : "",
    gstNum: userData?.gstNum ? userData.gstNum : "",
  });

  const handleUserDetailsConfirmBtn = () => {
    if (
      orderTypeActiveBtn === "Delivery" &&
      (inputs.name === "" || inputs.mobile === "" || inputs.locality === "")
    ) {
      toast.error(switchedLanguage ? "الاسم، الجوال، حقول العناوين إلزامية": "Name, Mobile, Address fields are mandotary!");
    } else {
      setUserData({
        name: inputs?.name,
        mobile: inputs?.mobile,
        locality: inputs?.locality,
        gstNum: inputs?.gstNum,
      });

      handleCloseUserModal();
    }
  };
  return (
    <>
      {
        <Modal
          open={handleOpenUserModal}
          onClose={handleCloseUserModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Paper
            sx={{ border: "none", display: "flex", justifyContent: "center" }}
          >
            <Box
              sx={{
                position: "absolute",
                left: "50%",
                padding: "26px",
                backgroundColor: "background.paper",
                width: "25em",
                borderRadius: "5px",
                top: "50%",
                transform: "translate(-50%,-50%)",
                display: "flex",
                flexDirection: "column",
                gap: "5px",
                borderRadius: "17px",
              }}
            >
              <Box sx={{ marginBottom: "15px" }}>
                <sup
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <CloseIcon
                    onClick={handleCloseUserModal}
                    sx={{
                      fontFamily: "poppins",
                      fontSize: "18px",
                      cursor: "pointer",
                      fontWeight: "bold",
                    }}
                  />
                </sup>
                <Typography
                  sx={{
                    fontFamily: "poppins",
                    fontSize: "17px",
                    fontWeight: "600",
                    textAlign: "center",
                  }}
                >
                  {switchedLanguage ? "تفاصيل المستخدم": "User Details"}
                </Typography>
              </Box>

              <Box>
                <Box
                  sx={{
                    marginBottom: "5px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "15px",
                  }}
                >
                  <TextField
                    value={inputs?.name}
                    onChange={(e) =>
                      setInputs({ ...inputs, name: e.target.value })
                    }
                    variant="standard"
                    placeholder={switchedLanguage ? "الاسم": "Name"}
                    // disabled={true}
                    InputProps={{
                      disableUnderline: true,
                    }}
                    inputProps={{
                      style: { fontFamily: "poppins" },
                    }}
                    sx={{
                      width: "100%",
                      fontFamily: "poppins",
                      borderRadius: "18px",
                      backgroundColor: "#F2F3F7",
                      border: "1px solid #F0F0F0",
                      outline: "none",
                      height: "50px",
                      display: "flex",
                      justifyContent: "center",
                      padding: "20px",
                    }}
                  />

                  <TextField
                    value={inputs?.mobile}
                    onChange={(e) =>
                      setInputs({ ...inputs, mobile: e.target.value })
                    }
                    variant="standard"
                    placeholder={switchedLanguage ? "الهاتف المحمول": "Mobile"}
                    // disabled={true}
                    InputProps={{
                      disableUnderline: true,
                    }}
                    inputProps={{
                      style: { fontFamily: "poppins" },
                    }}
                    sx={{
                      width: "100%",
                      fontFamily: "poppins",
                      borderRadius: "18px",
                      backgroundColor: "#F2F3F7",
                      border: "1px solid #F0F0F0",
                      outline: "none",
                      height: "50px",
                      display: "flex",
                      justifyContent: "center",
                      padding: "20px",
                    }}
                  />

                  <TextField
                    value={inputs?.locality}
                    onChange={(e) =>
                      setInputs({ ...inputs, locality: e.target.value })
                    }
                    variant="standard"
                    placeholder={switchedLanguage ? "عنوان المنزل": "Address"}
                    // disabled={true}
                    InputProps={{
                      disableUnderline: true,
                    }}
                    inputProps={{
                      style: { fontFamily: "poppins" },
                    }}
                    sx={{
                      width: "100%",
                      fontFamily: "poppins",
                      borderRadius: "18px",
                      backgroundColor: "#F2F3F7",
                      border: "1px solid #F0F0F0",
                      outline: "none",
                      height: "50px",
                      display: "flex",
                      justifyContent: "center",
                      padding: "20px",
                    }}
                  />

                  <TextField
                    value={inputs?.gstNum}
                    onChange={(e) =>
                      setInputs({ ...inputs, gstNum: e.target.value })
                    }
                    variant="standard"
                    // disabled={true}
                    placeholder={switchedLanguage ? "رقم ضريبة القيمة المضافة": "GST Number"}
                    InputProps={{
                      disableUnderline: true,
                    }}
                    inputProps={{
                      style: { fontFamily: "poppins" },
                    }}
                    sx={{
                      width: "100%",
                      fontFamily: "poppins",
                      borderRadius: "18px",
                      backgroundColor: "#F2F3F7",
                      border: "1px solid #F0F0F0",
                      outline: "none",
                      height: "50px",
                      display: "flex",
                      justifyContent: "center",
                      padding: "20px",
                    }}
                  />
                </Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  gap: "10px",
                  justifyContent: "space-between",
                  marginTop: "25px",
                }}
              >
                <Button
                  onClick={handleCloseUserModal}
                  variant="contained"
                  sx={{
                    boxShadow: "none",
                    width: "48%",
                    height: "43px",
                    backgroundColor: "#FFFFFF",
                    color: "#4D575D",
                    border: "1px solid #F0F0F0",
                    fontFamily: "poppins",
                    cursor: "pointer",
                    borderRadius: "11px",
                    textTransform: "none",
                    "&:hover": {
                      boxShadow: "none",
                      backgroundColor: "#FFFFFF",
                    },
                  }}
                >
                  {switchedLanguage ? "إلغاء": "Cancel"}
                </Button>
                <Button
                  onClick={handleUserDetailsConfirmBtn}
                  variant="contained"
                  sx={{
                    boxShadow: "none",
                    width: "48%",
                    height: "43px",
                    backgroundColor: "#f40000",
                    color: "#f2f3f8",
                    border: "none",
                    fontFamily: "poppins",
                    cursor: "pointer",
                    borderRadius: "11px",
                    textTransform: "none",
                    "&:hover": {
                      boxShadow: "none",
                      backgroundColor: "#f40000",
                    },
                  }}
                >
                  {switchedLanguage ? "تأكيد": "Confirm"}
                </Button>
              </Box>
            </Box>
          </Paper>
        </Modal>
      }
    </>
  );
};

export default BillingUserDetailsPopup;
