import { Card } from "@mui/material";
import OrderView from "./OrderView";

function Order({ status, orders }) {
  return (
    <Card
      sx={{
        margin: 0.5,
        p: 1,
        background: "var(--bg-forth)",
        height: "100%",
      }}
    >
      <span style={{ marginTop: 10 }}>
        <b>{status}</b>
      </span>
      {orders?.map((item,index) => (
        <OrderView item={item} />
      ))}
    </Card>
  );
}

export default Order;
