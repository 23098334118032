import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import BillingMenuSection from "../components/pos/BillingMenuSection";
import BillingOrderSection from "../components/pos/BillingOrderSection";
import FoodVarientModal from "../components/pos/FoodVarientModal";
import { useSelector } from "react-redux";
import { getAllCaptains } from "../service/admin";
import BillingCommentPopup from "../components/pos/BillingCommentPopup";
import BillingAssignerPopup from "../components/pos/BillingAssignerPopup";
import BillingSettlePopup from "../components/pos/BillingSettlePopup";
import BillingUserDetailsPopup from "../components/pos/BillingUserDetailsPopup";
import BillingTablePopup from "../components/pos/BillingTablePopup";

export default function PosBilling() {
  const {
    biller,
    selectedTable,
    clickedOrderTypeActiveBtn,
    recentOrderClickedItem,
  } = useSelector((state) => state);

  const [showFoodVariPopup, setShowFoodVariPopup] = useState(false);
  const [userBtn, setUserBtn] = useState(false);
  const [tableBtn, setTableBtn] = useState(false);
  const [foodAssignerBtn, setFoodAssignerBtn] = useState(false);
  const [commentBtn, setCommentBtn] = useState(false);
  const [settleBtn, setSettleBtn] = useState(false);

  const [addOrder, setAddOrder] = useState();
  const [items, setItems] = useState([]);
  const [updation, setUpdation] = useState(true);
  const [storeOrder, setStoreOrder] = useState({
    productId: "",
    itemName: "",
    varientId: "",
    salePrice: "",
    quantity: 1,
    addOns: [],
  });

  const [tablePopupValue, setTablePopupValue] = useState("");
  const [foodAssignerPopupValue, setFoodAssignerPopupValue] = useState("");
  const [comments, setComments] = useState("");
  const [userData, setUserData] = useState({
    name: "",
    mobile: "",
    locality: "",
    gstNum: "",
  });

  const [settlmentMode, setSettlementMode] = useState("COD");
  const [settlementInput, setSettlementInput] = useState("");

  const navigate = useNavigate();
  const handleOpenTableModal = () => setTableBtn(true);
  const handleCloseTableModal = () => setTableBtn(false);

  const handleOpenAssigneeModal = () => setFoodAssignerBtn(true);
  const handleCloseAssigneeModal = () => setFoodAssignerBtn(false);

  const handleOpenCommentModal = () => setCommentBtn(true);
  const handleCloseCommentModal = () => setCommentBtn(false);

  const handleOpenSettleModal = () => setSettleBtn(true);
  const handleCloseSettleModal = () => setSettleBtn(false);

  const handleOpenUserModal = () => setUserBtn(true);
  const handleCloseUserModal = () => setUserBtn(false);

  const [varientValueBtn, setVarientValueBtn] = useState({
    btnValue1: null,
    btnValue2: null,
    btnValue3: null,
    btnValue4: null,
    btnValue5: null,
  });

  const [addOnsBtn, setAddOnsBtn] = useState(null);

  const [products, setProducts] = useState([]);

  const [orderTypeActiveBtn, setOrderTypeActiveBtn] = useState(
    clickedOrderTypeActiveBtn ? clickedOrderTypeActiveBtn : "Dine in"
  );

  const calculateSubTotal = (salePrice, quantity, addOns) => {
    let subTotal = salePrice * quantity;

    if (addOns.length > 0) {
      addOns.forEach((addOn) => {
        subTotal += addOn.price * addOn.quantity;
      });
    }

    return subTotal;
  };

  const [singleVarientUpdate, setSingleVarientUpdate] = useState(false);
  const handleCloseFoodVariPopup = () => setShowFoodVariPopup(false);

  const handleOpenFoodVariPopup = (food) => {
    if (food.varients.length !== 1 || food.addOns.length) {
      setStoreOrder({
        productId: food._id,
        itemName: `${food.varients[0]?.varientId?.productName} ${
          food.varients[0]?.varientId?.value1
            ? food.varients[0]?.varientId?.value1
            : ""
        }`,
        varientId: food.varients[0]?.varientId._id,
        salePrice: food.varients[0]?.varientId.salePrice,
        quantity: 1,
        addOns: [],
      });
      // setVarientBtn(food.varients[0]?.varientId.productName);

      setAddOrder(food);
      setShowFoodVariPopup(true);
    } else {
      const existingItem = items.find(
        (item) => item.varientId === food.varients[0]?.varientId._id
      );

      if (existingItem && singleVarientUpdate) {
        // Item already exists, increase the quantity
        const updatedItems = items.map((item) => {
          if (item.varientId === food.varients[0]?.varientId._id) {
            const subTotal = calculateSubTotal(
              item.salePrice,
              item.quantity + 1,
              item.addOns
            );
            return {
              ...item,
              quantity: item.quantity + 1,
              subTotal: subTotal,
            };
          }
          return item;
        });
        setItems(updatedItems);
      } else {
        // Item does not exist, add a new item to the array
        const subTotal = calculateSubTotal(
          food.varients[0].varientId.salePrice,
          1,
          []
        );

        setItems([
          ...items,
          {
            productId: food._id,
            itemName: `${food.varients[0]?.varientId?.productName} ${
              food.varients[0]?.varientId?.value1
                ? food.varients[0]?.varientId?.value1
                : ""
            }`,
            varientId: food.varients[0]?.varientId._id,
            salePrice: food.varients[0].varientId.salePrice,
            subTotal: subTotal,
            quantity: 1,
            addOns: [],
          },
        ]);

        setSingleVarientUpdate(true);
      }
    }
  };

  useEffect(() => {
    setProducts(items);
  });

  useEffect(() => {
    if (
      !selectedTable.length &&
      clickedOrderTypeActiveBtn !== "Delivery" &&
      clickedOrderTypeActiveBtn !== "Pickup" &&
      !recentOrderClickedItem.length
    ) {
      navigate("/");
    }

    // allCaptains();
  }, []);

  // const allCaptains = async () => {
  //   let res = await getAllCaptains(biller.token);
  //   setFoodAssignerPopupValue(res.allCaptains[0]?.userName);
  // };

  const handleSettlementMode = (mode) => {
    setSettlementMode(mode);
  };


  return (
    <>
      <div
        style={{
          // marginTop: "65px",
          marginTop: "8vh",
          display: "flex",
        }}
      >
        {userBtn && (
          <BillingUserDetailsPopup
            setUserData={setUserData}
            handleOpenUserModal={handleOpenUserModal}
            handleCloseUserModal={handleCloseUserModal}
            userData={userData}
            orderTypeActiveBtn={orderTypeActiveBtn}
          />
        )}

        {tableBtn && (
          <BillingTablePopup
            handleOpenTableModal={handleOpenTableModal}
            handleCloseTableModal={handleCloseTableModal}
          />
        )}

        {commentBtn && (
          <BillingCommentPopup
            setComments={setComments}
            handleOpenCommentModal={handleOpenCommentModal}
            handleCloseCommentModal={handleCloseCommentModal}
            comments={comments}
          />
        )}

        {foodAssignerBtn && (
          <BillingAssignerPopup
            foodAssignerPopupValue={foodAssignerPopupValue}
            setFoodAssignerPopupValue={setFoodAssignerPopupValue}
            handleOpenAssigneeModal={handleOpenAssigneeModal}
            handleCloseAssigneeModal={handleCloseAssigneeModal}
          />
        )}

        {showFoodVariPopup && (
          <FoodVarientModal
            handleOpenFoodVariPopup={handleOpenFoodVariPopup}
            handleCloseFoodVariPopup={handleCloseFoodVariPopup}
            showFoodVariPopup={showFoodVariPopup}
            setShowFoodVariPopup={setShowFoodVariPopup}
            addOrder={addOrder}
            storeOrder={storeOrder}
            setStoreOrder={setStoreOrder}
            items={items}
            setItems={setItems}
            updation={updation}
            setUpdation={setUpdation}
            varientValueBtn={varientValueBtn}
            setVarientValueBtn={setVarientValueBtn}
            products={products}
            setProducts={setProducts}
            addOnsBtn={addOnsBtn}
            setAddOnsBtn={setAddOnsBtn}
          />
        )}

        <BillingMenuSection handleOpenFoodVariPopup={handleOpenFoodVariPopup} />
        <BillingOrderSection
          items={items}
          setItems={setItems}
          products={products}
          setProducts={setProducts}
          handleOpenCommentModal={handleOpenCommentModal}
          handleCloseCommentModal={handleCloseCommentModal}
          comments={comments}
          setComments={setComments}
          handleOpenAssigneeModal={handleOpenAssigneeModal}
          foodAssignerPopupValue={foodAssignerPopupValue}
          setFoodAssignerPopupValue={setFoodAssignerPopupValue}
          handleOpenSettleModal={handleOpenSettleModal}
          handleCloseSettleModal={handleCloseSettleModal}
          settlmentMode={settlmentMode}
          handleSettlementMode={handleSettlementMode}
          settleBtn={settleBtn}
          settlementInput={settlementInput}
          setSettlementInput={setSettlementInput}
          handleOpenUserModal={handleOpenUserModal}
          userData={userData}
          setUserData={setUserData}
          handleOpenTableModal={handleOpenTableModal}

          orderTypeActiveBtn={orderTypeActiveBtn}
          setOrderTypeActiveBtn={setOrderTypeActiveBtn}
        />
      </div>
    </>
  );
}
