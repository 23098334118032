import { combineReducers } from "redux";
import { BillerReducer } from "./reducers/BillerReducer";
import { ItemReducer } from "./reducers/ItemReducer";
import { OrderTypeActiveBtnReducer } from "./reducers/orderTypeActiveBtn";
import { TableReducer } from "./reducers/tableReducer";
import { GuestReducer } from "./reducers/guestReducer";
import { languageReducer } from "./reducers/languageReducer";

const rootReducer = combineReducers({
  biller: BillerReducer,
  recentOrderClickedItem: ItemReducer,
  clickedOrderTypeActiveBtn: OrderTypeActiveBtnReducer,
  selectedTable: TableReducer,
  noOfGuests: GuestReducer,
  switchedLanguage: languageReducer,
});

export default rootReducer;
