import {
  Box,
  Card,
  FormControl,
  MenuItem,
  Select,
  Button,
  Input,
  Typography,
  Divider,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { useEffect, useState } from "react";
import styled from "@emotion/styled";
import "moment/locale/ar";
import {
  changeOrderStatus,
  fetchDeliveryBoys,
  fetchPendingOrders,
} from "../../service/order";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import { changeOrderStatusSchema } from "../../schemas/index";
import ShowMoreText from "react-show-more-text";
import Moment from "react-moment";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { ExpandMore } from "@mui/icons-material";
import { printKot } from "../../service/printServer";

import moment from "moment";
const BuyIcon = styled(Box)({
  backgroundColor: "var(--red)",
  color: "var(--font-white)",
  width: "100px",
  height: "32px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "5px",
});

const initialValues = {
  DeliveryBoyId: "",
  DeliveryTime: 0,
  orderStatus: "Preparing",
};

function PendingOrderCard({
  orders,
  message,
  changeOrderStatusAction,
  type,
  query,
  keys,
}) {
  const { biller, switchedLanguage } = useSelector((state) => state);

  const [deliveryBoys, setDeliveryBoys] = useState([]);

  useEffect(() => {
    getDeliveryBoys();
  }, []);
  const getDeliveryBoys = async () => {
    const data = await fetchDeliveryBoys(biller.token);
    // console.log(data, "boyyyyyyyyyyy");
    setDeliveryBoys(data);
  };

  return (
    <Card
      elevation={0}
      sx={{
        margin: 0.5,
        p: 1,

        background: "var(--bg-forth)",
        height: { lg: "76vh", md: "72vh" },
      }}
    >
      <span style={{}}>
        <b>{switchedLanguage ? "أمر الانتظار" : "Pending"}</b>
      </span>
      <Box
        sx={{
          mt: 1,

          height: "100%",
          overflow: "hidden",
          overflowY: "scroll",
          "&::-webkit-scrollbar": {
            display: "none", // Hide scrollbar
          },
        }}
      >
        {orders
          ?.filter((item) =>
            keys.some((key) => item[key]?.toLowerCase()?.includes(query))
          )
          ?.map((item, index) => (
            <Box key={item._id}>
              {type === "All" ? (
                <CardBody
                  item={item}
                  deliveryBoys={deliveryBoys}
                  message={message}
                  changeOrderStatusAction={changeOrderStatusAction}
                />
              ) : (
                item.typeofOrder == type && (
                  <CardBody
                    item={item}
                    deliveryBoys={deliveryBoys}
                    message={message}
                    changeOrderStatusAction={changeOrderStatusAction}
                  />
                )
              )}
            </Box>
          ))}
      </Box>
    </Card>
  );
}

export default PendingOrderCard;

function CardBody({ item, deliveryBoys, message, changeOrderStatusAction }) {
  const { switchedLanguage } = useSelector((state) => state);

  // Function to convert a number to Arabic numerals
  function toArabicNumerals(number) {
    const arabicNumerals = ["٠", "١", "٢", "٣", "٤", "٥", "٦", "٧", "٨", "٩"];
    return String(number).replace(/\d/g, (digit) => arabicNumerals[digit]);
  }

  return (
    <Card
      elevation={0}
      sx={{
        mb: 2,
        p: 1.5,
      }}
    >
      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <BuyIcon sx={{ width: { sm: "70px", md: "70px", lg: "100px" } }}>
            <Typography
              sx={{ fontSize: { sm: "13px", md: "13px", lg: "15px" } }}
            >
              {/* {item?.typeofOrder} */}
              {item?.typeofOrder == "Dine in" && switchedLanguage
                ? "على الطاولة"
                : item?.typeofOrder == "Delivery" && switchedLanguage
                ? "التسليم"
                : item?.typeofOrder == "Pickup" && switchedLanguage
                ? "أمر التقاط"
                : item?.typeofOrder}
            </Typography>
          </BuyIcon>
          <Box
            sx={{
              display: "flex",
              alignContent: "end",
              textAlign: "end",
            }}
          >
            <Typography
              sx={{ fontSize: { sm: "13px", md: "12px", lg: "15px" } }}
            >
              <Moment format="MM/D/YY,h:mm" withTitle locale={switchedLanguage ? "ar" : "en"}>
                {item?.createdAt}
              </Moment>
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mt: "8px",
          }}
        >
          <Typography
            sx={{
              fontSize: { sm: "13px", md: "13px", lg: "14px", display: "flex" },
            }}
          >
            <span style={{ fontWeight: "bolder" }}>
              {switchedLanguage ? "رقم الفاتورة" : "Invoice No"} :{" "}
            </span>

            <span
              style={{
                fontWeight: switchedLanguage ? "bolder" : "",
                fontSize: switchedLanguage ? "18px" : "",
              }}
            >
              {switchedLanguage
                ? toArabicNumerals(item?.invoiceNo)
                : item?.invoiceNo}
            </span>
          </Typography>
          {item?.typeofOrder == "Delivery" || item?.typeofOrder == "Pickup" ? (
            ""
          ) : (
            <Box
              sx={{
                display: "flex",
                alignContent: "end",
                textAlign: "end",
              }}
            >
              <Typography
                sx={{
                  fontSize: { sm: "13px", md: "13px", lg: "14px" },
                }}
              >
                <span style={{ fontWeight: "bolder" }}>
                  {switchedLanguage ? "الجدول رقم" : "Table No"}
                </span>
                :
                {switchedLanguage
                  ? toArabicNumerals(item?.tableNo)
                  : item?.tableNo}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
      <Box sx={{ mt: "15px" }}>
        <Products
          item={item}
          deliveryBoys={deliveryBoys}
          message={message}
          changeOrderStatusAction={changeOrderStatusAction}
        />
      </Box>
    </Card>
  );
}

function Products({ item, deliveryBoys, message, changeOrderStatusAction }) {
  const { biller } = useSelector((state) => state);

  function increment(id) {
    const counter = document.getElementById(`DeliveryTime${id}`);
    const value = Number(counter.value) + 1;
    document.getElementById(`DeliveryTime${id}`).value = value > 0 ? value : 0;
  }
  function decrement(id) {
    const counter = document.getElementById(`DeliveryTime${id}`);
    const value = Number(counter.value) - 1;
    document.getElementById(`DeliveryTime${id}`).value = value > 0 ? value : 0;
  }
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: changeOrderStatusSchema,
    // onSubmit: async (values, action) => {
    //   const data = await changeOrderStatus(values, biller.token);
    // },
  });
  async function handleSubmit(event) {
    event.preventDefault();
    const id = event.target.id;
    // const { DeliveryTime, DeliveryBoyId } = event.target.elements;
    let obj = {
      // DeliveryTime: DeliveryTime.value,
      orderStatus: "Preparing",
    };
    // if (DeliveryBoyId.value !== "") {
    //   obj = {
    //     ...obj,
    //     DeliveryBoyId: DeliveryBoyId.value,
    //   };
    // }
    const data = await changeOrderStatus(obj, id, biller.token);
    console.log(data);
    if (data.status) {
      await printKot(data.kotProductData);
      message(data);
      document.getElementById(id).reset();
    }
  }

  const [open, setOpen] = useState(false);
  const { switchedLanguage } = useSelector((state) => state);
  // Currency
  const currency =
    switchedLanguage && biller.vendor.country == "UAE"
      ? "الدرهم"
      : switchedLanguage && biller.vendor.country == "KSA"
      ? "ريال"
      : !switchedLanguage && biller.vendor.country == "IND"
      ? "₹"
      : "";

  return (
    <>
      {open ? (
        <>
          <Box sx={{ display: "flex", justifyContent: "end" }}>
            <ExpandLessIcon onClick={() => setOpen(false)} />
          </Box>
          {item?.products?.map((product) => (
            <>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography sx={{ fontSize: "14px" }}>
                  {switchedLanguage
                    ? `${(product?.quantity).toLocaleString("ar-EG")} ${
                        product?.varientId?.productName
                      } x`
                    : `${product?.quantity} x ${product?.varientId?.productName}`}

                  <br />
                </Typography>
                <Typography sx={{ fontSize: "14px" }}>
                  {switchedLanguage ? "" : currency}{" "}
                  {switchedLanguage
                    ? (product?.quantity * product?.salePrice).toLocaleString(
                        "ar-EG"
                      )
                    : product?.quantity * product?.salePrice}{" "}
                  {switchedLanguage ? currency : ""}
                  <br />
                </Typography>
              </Box>
              <Box sx={{ ml: "10px" }}>
                {product?.addOns.length > 0 &&
                  product?.addOns?.map((addOn) => (
                    <Box
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <Typography sx={{ fontSize: "14px" }} value={addOn?._id}>
                        {switchedLanguage
                          ? `${(addOn?.quantity).toLocaleString("ar-EG")} ${
                              addOn?.name
                            } x`
                          : `${addOn?.quantity} x ${addOn?.name}`}

                        <br />
                      </Typography>
                      <Typography sx={{ fontSize: "14px" }} value={addOn?._id}>
                        {switchedLanguage ? "" : currency}{" "}
                        {switchedLanguage
                          ? addOn?.price.toLocaleString("ar-EG")
                          : addOn?.price}
                        {switchedLanguage ? currency : ""}
                        <br />
                      </Typography>
                    </Box>
                  ))}
              </Box>
            </>
          ))}
          <Box sx={{ display: "flex", justifyContent: "end" }}>
            <Divider style={{ width: "100px", color: "black" }} />
          </Box>
          <Box sx={{ display: "flex", justifyContent: "end" }}>
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "bold",
              }}
            >
              {switchedLanguage ? "" : currency}{" "}
              {switchedLanguage
                ? item.products
                    .reduce((accumulator, curValue) => {
                      return accumulator + curValue.subTotal;
                    }, 0)
                    .toLocaleString("ar-EG")
                : item.products.reduce((accumulator, curValue) => {
                    return accumulator + curValue.subTotal;
                  }, 0)}{" "}
              {switchedLanguage ? currency : ""}
            </Typography>
          </Box>
          <form
            id={item._id}
            onSubmit={handleSubmit}
            // onSubmit={formik.handleSubmit}
          >
            {/* <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                mt: "15px",
              }}
            >
              <Box sx={{ width: "49%" }}>
                <Box>
                  <FormControl fullWidth>
                    <Select
                      defaultValue={null}
                      labelId="demo-simple-select-label"
                      id="DeliveryBoyId"
                      name="DeliveryBoyId"
                      size="small"
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      <MenuItem value={null}>
                        <em>Auto Assign</em>
                      </MenuItem>
                      {deliveryBoys.map((item) => (
                        <MenuItem value={item._id}>{item.userName}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
                {formik.touched.DeliveryBoyId &&
                  formik.errors.DeliveryBoyId && (
                    <span
                      style={{
                        color: "#d32f2f",

                        fontSize: "11px",
                      }}
                    >
                      {formik.errors.DeliveryBoyId}
                    </span>
                  )}
              </Box>
              <Box
                sx={{
                  width: "48%",
                }}
              >
                <Box
                  sx={{
                    border: "solid var(--border-black) 1px",
                    display: "flex",
                    justifyContent: "space-around",
                    alignItems: "center",
                    borderRadius: "5px",
                    px: "3px",
                    height: "40px",
                  }}
                >
                  <Box
                    sx={{
                      padding: "0px",
                      // backgroundColor: "var(--red)",
                      "&:hover": {
                        backgroundColor: "var(--button-gray)",
                        cursor: "pointer",
                      },
                      color: "var(--font-black)",
                      width: "25%",
                      borderRadius: "5px",
                      height: "30px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    onClick={() => {
                      decrement(item._id);
                    }}
                  >
                    <RemoveIcon sx={{ width: "10px", height: "10px" }} />
                  </Box>

                  <Input
                    id={`DeliveryTime${item._id}`}
                    label="DeliveryTime"
                    defaultValue={0}
                    name="DeliveryTime"
                    type="number"
                    sx={{
                      width: "100px",
                      "& input": {
                        textAlign: "center",
                      },
                    }}
                    disableUnderline
                  />
                  <Box
                    sx={{
                      padding: "0px",
                      // backgroundColor: "var(--red)",
                      "&:hover": {
                        backgroundColor: "var(--button-gray)",
                        cursor: "pointer",
                      },
                      color: "var(--font-black)",
                      width: "25%",
                      borderRadius: "5px",
                      height: "30px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    onClick={() => increment(item._id)}
                  >
                    <AddIcon sx={{ width: "10px", height: "10px" }} />
                  </Box>
                </Box>
                {formik.touched.DeliveryTime && formik.errors.DeliveryTime && (
                  <span
                    style={{
                      color: "#d32f2f",

                      fontSize: "11px",
                    }}
                  >
                    {formik.errors.DeliveryTime}
                  </span>
                )}
              </Box>
            </Box> */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-around",
                mt: "15px",
              }}
            >
              <Button
                variant="contained"
                type="submit"
                sx={{
                  background: "var(--red)",
                  width: "130px",
                  p: "0px",
                  // height: "45px",
                  mr: "10px",
                  textTransform: "none",
                  borderRadius: "5px",
                  fontSize: "12px",
                  "&:hover": {
                    backgroundColor: "var(--red)",
                  },
                  display: "block",
                }}
              >
                <Moment
                  element="span"
                  style={{
                    width: "100%",
                    fontSize: "11px",
                  }}
                  fromNow
                  ago
                  locale={switchedLanguage ? "ar" : "en"}
                >
                  {item.updatedAt}
                </Moment>

                {/* <Moment
                  element="span"
                  style={{
                    width: "100%",
                    fontSize: "11px",
                  }}
                  fromNow
                  ago
                >
                  {item.updatedAt}
                </Moment> */}

                {/* {moment(new Date(item.updatedAt)).fromNow() ===
                "a few seconds ago"
                  ? "1 minutes ago"
                  : moment(new Date(item.updatedAt)).fromNow() ===
                    "a minute ago"
                  ? "1 minutes ago"
                  : moment(new Date(item.updatedAt)).fromNow() === "a hour ago"
                  ? ""
                  : moment(new Date(item.updatedAt)).fromNow() === "a day ago"
                  ? "1 day ago"
                  : moment(new Date(item.updatedAt)).fromNow()} */}
                <div style={{ fontSize: "13.5px" }}>
                  {switchedLanguage ? "قبول النظام" : "Accept"}
                </div>
                {/* {console.log(moment(new Date(item.updatedAt)).fromNow())} */}
              </Button>
              {/* <Button
                variant="contained"
                sx={{
                  color: "var(--font-black)",
                  background: "var(--button-gray)",
                  width: "130px",
                  mr: "10px",
                  p: "0px",

                  textTransform: "none",
                  borderRadius: "5px",
                  "&:hover": {
                    backgroundColor: "var(--button-gray)",
                  },
                }}
                // onClick={() => changeOrderStatusAction(item._id, "outOfStok")}
              >
                Save & Print
              </Button> */}
              <Button
                variant="outlined"
                sx={{
                  width: "130px",
                  borderColor: "black",
                  color: "var(--font-black)",
                  textTransform: "none",
                }}
                onClick={() => changeOrderStatusAction(item._id, "Cancelled")}
              >
                {switchedLanguage ? "إلغاء" : "Cancel"}
              </Button>
            </Box>
          </form>
          {/* <Box sx={{ display: "flex", justifyContent: "space-evenly" }}>
            <Button
              variant="text"
              sx={{ color: "var(--font-black)", textTransform: "none" }}
              onClick={() => changeOrderStatusAction(item._id, "Cancelled")}
            >
              Cancel
            </Button>
          </Box> */}
        </>
      ) : (
        <Box sx={{ display: "flex", justifyContent: "end" }}>
          <ExpandMore onClick={() => setOpen(true)} />
        </Box>
      )}
    </>
  );
}
