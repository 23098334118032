import React, { useState, useEffect } from "react";
import { Box, Modal, Paper, Typography, Divider, Button } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useSelector } from "react-redux";
import { getAllCaptains } from "../../service/admin";

import CloseIcon from "@mui/icons-material/Close";

const BillingAssignerPopup = ({
  foodAssignerPopupValue,
  setFoodAssignerPopupValue,
  handleOpenAssigneeModal,
  handleCloseAssigneeModal,
}) => {
  const handleConfirmAssignerPopup = () => {
    handleCloseAssigneeModal();
  };

  const [assignee, setAssignee] = useState([]);
  const { biller,switchedLanguage  } = useSelector((state) => state);

  const allCaptains = async () => {
    let res = await getAllCaptains(biller.token);
    //setFoodAssignerPopupValue(res.allCaptains[0]?.userName);
    setAssignee(res.allCaptains);
  };

  useEffect(() => {
    allCaptains();
  }, []);

  return (
    <>
      {
        <Modal
          open={handleOpenAssigneeModal}
          onClose={handleCloseAssigneeModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Paper
            sx={{ border: "none", display: "flex", justifyContent: "center" }}
          >
            <Box
              sx={{
                position: "absolute",
                left: "50%",
                padding: "26px",
                backgroundColor: "background.paper",
                width: "25em",
                borderRadius: "5px",
                top: "50%",
                transform: "translate(-50%,-50%)",
                display: "flex",
                flexDirection: "column",
                gap: "5px",
                borderRadius: "17px",
              }}
            >
              <Box sx={{ marginBottom: "25px" }}>
                <sup
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <CloseIcon
                    onClick={handleCloseAssigneeModal}
                    sx={{
                      fontFamily: "poppins",
                      fontSize: "18px",
                      cursor: "pointer",
                      fontWeight: "bold",
                    }}
                  />
                </sup>
                <Typography
                  sx={{
                    fontFamily: "poppins",
                    fontSize: "17px",
                    fontWeight: "600",
                    textAlign: "center",
                  }}
                >
                  {switchedLanguage ? "تعيين إلى": "Assign to"}
                </Typography>
              </Box>

              <Box>
                <Box sx={{ marginBottom: "5px" }}>
                  <Typography
                    sx={{
                      fontFamily: "poppins",
                      marginBottom: "6px",
                      color: "#4D575D",
                    }}
                  >
                    {switchedLanguage ? "اختر الكابتن": "Choose Captain"}
                  </Typography>
                  <FormControl fullWidth sx={{ height: "40px" }}>
                    <Select
                      sx={{
                        height: "40px",
                        border: "1px solid #F0F0F0",
                        borderRadius: "18px",
                      }}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      onChange={(e) =>
                        setFoodAssignerPopupValue(e.target.value)
                      }
                      value={foodAssignerPopupValue}
                    >
                      {assignee?.map((assigner, index) => (
                        <MenuItem
                          key={index}
                          sx={{ fontFamily: "poppins" }}
                          value={assigner.userName}
                        >
                          {assigner?.userName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  gap: "10px",
                  justifyContent: "space-between",
                  marginTop: "30px",
                }}
              >
                <Button
                  onClick={handleCloseAssigneeModal}
                  variant="contained"
                  sx={{
                    boxShadow: "none",
                    width: "48%",
                    height: "43px",
                    backgroundColor: "#FFFFFF",
                    color: "#4D575D",
                    border: "1px solid #F0F0F0",
                    fontFamily: "poppins",
                    cursor: "pointer",
                    borderRadius: "11px",
                    textTransform: "none",
                    "&:hover": {
                      boxShadow: "none",
                      backgroundColor: "#FFFFFF",
                    },
                  }}
                >
                  {switchedLanguage ? "إلغاء": "Cancel"}
                </Button>
                <Button
                  onClick={handleConfirmAssignerPopup}
                  variant="contained"
                  sx={{
                    boxShadow: "none",
                    width: "48%",
                    height: "43px",
                    backgroundColor: "#f40000",
                    color: "#f2f3f8",
                    border: "none",
                    fontFamily: "poppins",
                    cursor: "pointer",
                    borderRadius: "11px",
                    textTransform: "none",
                    "&:hover": {
                      boxShadow: "none",
                      backgroundColor: "#f40000",
                    },
                  }}
                >
                  {switchedLanguage ? "تأكيد": "Confirm"}
                </Button>
              </Box>
            </Box>
          </Paper>
        </Modal>
      }
    </>
  );
};

export default BillingAssignerPopup;
