import Cookies from "js-cookie";

export function languageReducer(
  state = Cookies.get("selectedLanguage")
    ? JSON.parse(Cookies.get("selectedLanguage"))
    : false,
  action
) {
  switch (action.type) {
    case "isArabicLanguage":
      return action.payload;

    default:
      return state;
  }
}
