import {
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
  Grid,
} from "@mui/material";
import { Box } from "@mui/system";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { addKotSchema } from "../../schemas/index";
import {
  fetchAllVarientProduct,
  getAllGroups,
  getAllKot,
  updateKot,
} from "../../service/printerConfiguration";
import { ToastContainer, toast } from "react-toastify";
import { useSelector } from "react-redux";

export default function EditKot({ editItem, initialValues, handleClose }) {
  const { biller,switchedLanguage  } = useSelector((state) => state);
  const [allGroup, setGroup] = useState([]);
  const [allProducts, setallProducts] = useState([]);
  const [allKotProducts, setAllKotProducts] = useState([]);
  const [allKotGroup, setAllKotGroup] = useState([]);
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: addKotSchema,
    onSubmit: async (values, action) => {
      let kotValues = {
        kotName: "",
        groups: [],
        products: [],
        printer: "",
      };
      if (values.products.length > 0) {
        let proData = values?.products.map((item) => {
          return { item: item };
        });
        kotValues = { ...kotValues, products: proData };
      }
      if (values.groups.length > 0) {
        let groupData = values?.groups.map((item) => {
          return { item: item };
        });
        kotValues = { ...kotValues, groups: groupData };
      }
      kotValues = {
        ...kotValues,
        kotName: values.kotName,
        printer: values.printer,
      };
      const data = await updateKot(editItem._id, kotValues, biller.token);
      if (data.status) {
        switchedLanguage ? toast.success("يتم وضع أمر المطبخ"):toast.success(data.message);
        handleClose();
      }
    },
  });

  useEffect(() => {
    fetchAllGroup();
    fetchAllProducts();
    fetchAllKot();
  }, []);

  const fetchAllKot = async () => {
    let proidArray = [];
    let groupidArray = [];
    const data = await getAllKot(biller.token);
    data.map((item) => {
      item.products.map((proItem) => {
        if (!initialValues.products.includes(proItem.item)) {
          proidArray.push(proItem.item);
        }
      });
      item.groups.map((groupItem) => {
        if (!initialValues.groups.includes(groupItem.item)) {
          groupidArray.push(groupItem.item);
        }
      });
    });

    setAllKotProducts([...proidArray]);
    setAllKotGroup([...groupidArray]);
  };
  const fetchAllGroup = async () => {
    const data = await getAllGroups(biller.token);
    setGroup(data);
  };
  const fetchAllProducts = async () => {
    const data = await fetchAllVarientProduct(biller.token);
    setallProducts(data);
  };

  const selectProduct = (e) => {
    if (formik.values.products.includes(e.target.value)) {
      formik.setValues({
        ...formik.values,
        products: formik.values.products.filter((item) => {
          return item !== e.target.value;
        }),
      });
    } else {
      formik.setValues({
        ...formik.values,
        products: [...formik.values.products, e.target.value],
      });
    }
  };
  const selectGroup = (items, e) => {
    let products = [];
    if (!formik.values.groups.includes(e)) {
      items.map((value) => {
        if (
          !formik.values.products.includes(value?._id) &&
          value?._id !== undefined
        ) {
          products.push(value._id);
        }
      });
      formik.setValues({
        ...formik.values,
        groups: [...formik.values.groups, e],
        products: [...formik.values.products, ...products],
      });
      products = [];
    } else {
      products = formik.values.products;
      items.map((value) => {
        if (products.includes(value?._id)) {
          let data = products.filter((item) => {
            return item !== value?._id;
          });
          products = data;
        }
      });
      formik.setValues({
        ...formik.values,
        groups: formik.values.groups.filter((item) => {
          return item !== e;
        }),
        products: [...products],
      });
      products = [];
    }
  };
  const selectAllGroup = (cheked, data) => {
    let allProducts = [];
    let group = [];
    if (cheked) {
      allProducts = formik.values.products;
      data.map((item) => {
        item.products.map((value) => {
          if (!allKotProducts.includes(value._id)) {
            allProducts.push(value._id);
          }
        });
        if (!allKotGroup.includes(item._id)) {
          group.push(item._id);
        }
      });
    }
    formik.setValues({
      ...formik.values,
      groups: group,
      products: [...allProducts],
    });
  };
  const selectAllProducts = (cheked, product, group) => {
    let allProduct = [];
    let allGroup = [];
    if (cheked) {
      product.map((item) => {
        if (!allKotProducts.includes(item._id)) {
          allProduct.push(item._id);
        }
        return item._id;
      });
      const groupId = group.map((item) => {
        if (!allKotGroup.includes(item._id)) {
          return item._id;
        }
      });
      allGroup = groupId;
    }
    formik.setValues({
      ...formik.values,
      groups: allGroup,
      products: allProduct,
    });
  };
  return (
    <div>
      <ToastContainer />
      <Box>
        <Typography sx={{ fontFamily: "poppins" }}>{switchedLanguage ? "تحرير أمر المطبخ": "Edit KOT"}</Typography>
        <form onSubmit={formik.handleSubmit}>
          <Box sx={{ display: "flex", gap: "10px" }}>
            <Box sx={{ mt: "20px", width: "50%" }}>
              <Typography sx={{ my: "10px" }} style={{ fontSize: "13px" }}>
                {switchedLanguage ? "اسم المطبخ": "Kot Name"}
              </Typography>
              <TextField
                id="kotName"
                placeholder={switchedLanguage ? "اسم المطبخ": "Kot Name"}
                variant="outlined"
                name="kotName"
                style={{ width: "100%" }}
                value={formik.values.kotName}
                onChange={formik.handleChange}
                error={!!formik.touched.kotName && !!formik.errors.kotName}
                helperText={formik.touched.kotName && formik.errors.kotName}
              />
            </Box>
            <Box sx={{ my: "20px", width: "50%" }}>
              <Typography sx={{ my: "10px" }} style={{ fontSize: "13px" }}>
                {switchedLanguage ? "بروتوكول الإنترنت للطابعة": "Printer IP"}
              </Typography>
              <TextField
                placeholder={switchedLanguage ? "بروتوكول الإنترنت للطابعة": "Printer IP"}
                variant="outlined"
                name="printer"
                style={{ width: "100%" }}
                value={formik.values.printer}
                onChange={formik.handleChange}
                error={!!formik.touched.printer && !!formik.errors.printer}
                helperText={formik.touched.printer && formik.errors.printer}
              />
            </Box>
          </Box>

          <Box>
            <Box
              sx={{
                width: "100%",
                border: "solid var(--border-gray) 1px",
                height: "40px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                background: "var(--table-head)",
                px: "30px",
              }}
            >
              <Typography sx={{ fontSize: "14px", fontWeight: "bold" }}>
                {switchedLanguage ? "المجموعات": "Groups"}
              </Typography>
              <FormControlLabel
                // value="end"
                control={<Checkbox />}
                label={switchedLanguage ? "اختر الكل": "Select All"}
                labelPlacement="end"
                // checked={product.pickup}
                onChange={(e) => selectAllGroup(e.target.checked, allGroup)}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: "30px",
                my: "20px",
              }}
            >
              {allGroup?.map((item) => (
                <FormControlLabel
                  disabled={
                    allKotGroup.includes(item._id) &&
                    !initialValues.groups.includes(item._id)
                  }
                  value={item._id}
                  control={<Checkbox />}
                  label={
                    <Typography style={{ fontFamily: "poppins" }}>
                      {item.groupName}
                    </Typography>
                  }
                  labelPlacement={item.groupName}
                  checked={formik.values.groups.includes(item._id)}
                  onChange={(e) => selectGroup(item.products, e.target.value)}
                />
              ))}
            </Box>
          </Box>
          <Box>
            <Box
              sx={{
                width: "100%",
                border: "solid var(--border-gray) 1px",
                height: "40px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                background: "var(--table-head)",
                px: "30px",
              }}
            >
              <Typography sx={{ fontSize: "14px", fontWeight: "bold" }}>
                {switchedLanguage ? "البنود": "Items"}
              </Typography>
              <FormControlLabel
                // value="end"
                control={<Checkbox />}
                label={switchedLanguage ? "اختر الكل": "Select All"}
                labelPlacement="end"
                onChange={(e) =>
                  selectAllProducts(e.target.checked, allProducts, allGroup)
                }
              />
            </Box>
            <Box sx={{ ml: "30px", mt: "30px" }}>
              <Grid container spacing={{ xs: 2, md: 3 }}>
                {allProducts?.map((item) => (
                  <Grid xs={2} sm={4} md={3} lg={2} key={item._id}>
                    <FormControlLabel
                      disabled={
                        allKotProducts.includes(item._id) &&
                        !initialValues.products.includes(item._id)
                      }
                      value={item._id}
                      control={<Checkbox />}
                      label={
                        <Typography style={{ fontFamily: "poppins" }}>
                          {item.title}
                        </Typography>
                      }
                      labelPlacement="end"
                      checked={formik.values.products.includes(item._id)}
                      onChange={(e) => selectProduct(e)}
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "end" }}>
            <Button
              sx={{
                height: "100%",
                fontSize: "16px",
                mt: "3px",
                textTransform: "none",
                color: "var(--font-black)",
                mr: "10px",
                background: "var(--button-gray)",
                "&:hover": {
                  backgroundColor: "var(--button-gray)",
                },
              }}
              variant="text"
              // onClick={() => handleCloseGroup()}
              onClick={handleClose}
            >
              {switchedLanguage ? "إلغاء": "Cancel"}
            </Button>
            <Button
              type="submit"
              sx={{
                height: "100%",
                fontSize: "16px",
                mt: "3px",
                textTransform: "none",
                color: "var(--bg-white)",
                background: "var(--red)",
                "&:hover": {
                  backgroundColor: "var(--red)",
                },
              }}
              variant="text"
            >
              {switchedLanguage ? "حفظ": "Save"}
            </Button>
          </Box>
        </form>
      </Box>
    </div>
  );
}
