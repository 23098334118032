import axios from "axios";
const host = process.env.REACT_APP_BACKEND_URL;

export const fetchAllVarientProduct = async (token) => {
  try {
    const { data } = await axios.get(`${host}/billingFetchAllVarientProduct`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const getAllGroups = async (token) => {
  try {
    const { data } = await axios.get(`${host}/billingGetAllGroups`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};
export const getSingleGroup = async (id, token) => {
  try {
    const { data } = await axios.get(`${host}/billingGetSingleGroup/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const createKot = async (value, token) => {
  try {
    const { data } = await axios.post(`${host}/billingCreateKot`, value, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};
export const updateKot = async (id, value, token) => {
  try {
    const { data } = await axios.put(`${host}/billingUpdateKot/${id}`, value, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};
export const deleteKot = async (id, token) => {
  try {
    const { data } = await axios.delete(`${host}/billingDeleteKot/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};
export const getAllKot = async (token) => {
  try {
    const { data } = await axios.get(`${host}/billingGetAllKot`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};
export const createBilling = async (value, token) => {
  try {
    const { data } = await axios.post(`${host}/createBilling`, value, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};
export const updateBilling = async (id, value, token) => {
  try {
    const { data } = await axios.put(`${host}/updateBilling/${id}`, value, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};
export const deleteBilling = async (id, token) => {
  try {
    const { data } = await axios.delete(`${host}/deleteBilling/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};
export const getBillings = async (token) => {
  try {
    const { data } = await axios.get(`${host}/getBillings`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const createPickupAndDeliveryPrinter = async (printerData, token) => {
  try {
    const { data } = await axios.post(
      `${host}/createPickupAndDeliveryPrinter`,
      printerData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const allPickupAndDeliveryPrinter = async (token) => {
  try {
    const { data } = await axios.get(`${host}/allPickupAndDeliveryPrinter`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const editPickupAndDeliveryPrinter = async (value, id, token) => {
  try {
    const { data } = await axios.put(
      `${host}/editPickupAndDeliveryPrinter/${id}`,
      value,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};
export const deletepickupAndDeliveryPrinter = async (id, token) => {
  try {
    const { data } = await axios.delete(
      `${host}/deletepickupAndDeliveryPrinter/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};
