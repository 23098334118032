import React, { useState, useEffect } from "react";
import { Box, Modal, Paper, Typography, Button } from "@mui/material";
import { getAllTables, getReservedTables } from "../../service/admin";
import { useSelector } from "react-redux";
import dayjs from "dayjs";

export default function TableStyles({
  handleTableClick,
  query,
  reserveTableBtn,
  reservedTableBtn,
}) {
  const { biller,switchedLanguage  } = useSelector((state) => state);
  const [tables, setAllTables] = useState([]);
  const [todayDateAndTime, setTodayDateAndTime] = useState({
    todayDate: dayjs(new Date(Date.now())).format("D MMM YY"),
    todayTime: dayjs(new Date(Date.now())).format("h:mm A"),
  });

  const [popupTableValue, setPopupTableValue] = useState(null);
  const [reservedTables, setReservedTables] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const handleOpenPopup = () => setShowPopup(true);
  const handleClosePopup = () => setShowPopup(false);

  useEffect(() => {
    allTables();
    allReservedTables();
  }, [todayDateAndTime.todayTime, reserveTableBtn, reservedTableBtn]);

  useEffect(() => {
    let previousMinute = null;

    const updateDateAndTime = () => {
      const currentDate = new Date(Date.now());
      const formattedDate = dayjs(currentDate).format("D MMM YY");
      const formattedTime = dayjs(currentDate).format("h:mm A");
      const currentMinute = currentDate.getMinutes();

      if (currentMinute !== previousMinute) {
        setTodayDateAndTime({
          ...todayDateAndTime,
          todayDate: formattedDate,
          todayTime: formattedTime,
        });

        previousMinute = currentMinute;
      }
    };

    // Call the updateDateAndTime function immediately
    updateDateAndTime();

    // Set up the interval to call updateDateAndTime every second
    const interval = setInterval(() => {
      updateDateAndTime();
    }, 1000);

    // Clean up the interval on component unmount
    return () => {
      clearInterval(interval);
    };
  }, []);

  const allTables = async () => {
    let res = await getAllTables(biller.token, biller.vendor._id);
    setAllTables(res);
  };

  const allReservedTables = async () => {
    let res = await getReservedTables(biller.token);
    let reservedData = res.filter((item) => {
      if (item.reservedDate === todayDateAndTime.todayDate) {
        const [startTime, endTime] = item.bufferTime.split(" - ");
        const comparisonDate = new Date(); // Use a consistent date for comparison

        const targetTime = new Date(
          comparisonDate.toDateString() + " " + todayDateAndTime.todayTime
        );
        const rangeStartTime = new Date(
          comparisonDate.toDateString() + " " + startTime
        );
        const rangeEndTime = new Date(
          comparisonDate.toDateString() + " " + endTime
        );

        if (targetTime > rangeStartTime && targetTime < rangeEndTime) {
          return true;
        }
      }
      return false;
    });

    let reservedTableIds = [];
    reservedData?.forEach((tbls) => {
      tbls?.tables?.forEach((tbl) => {
        reservedTableIds.push(tbl.table._id);
      });
    });

    setReservedTables(reservedTableIds);
  };

  const handlePopupCancelBtn = () => {
    handleClosePopup();
  };

  const handlePopupConfirmBtn = () => {
    handleTableClick(popupTableValue);
    handleClosePopup();
  };

  // Function to convert a number to Arabic numerals
  function toArabicNumerals(number) {
    const arabicNumerals = ["٠", "١", "٢", "٣", "٤", "٥", "٦", "٧", "٨", "٩"];
    return String(number).replace(/\d/g, (digit) => arabicNumerals[digit]);
  }

  
  return (
    <Box
      className="table-container"
      sx={{
        py: 2,
        height: "75vh",
        bgcolor: "#F5F5F5",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        flexWrap: "wrap",
        overflowY: "scroll",
        "&::-webkit-scrollbar": {
          display: "none", // Hide scrollbar
        },
      }}
    >
      {showPopup && (
        <Modal
          open={handleOpenPopup}
          onClose={handleClosePopup}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Paper
            sx={{ border: "none", display: "flex", justifyContent: "center" }}
          >
            <Box
              sx={{
                position: "absolute",
                left: "50%",
                padding: "26px",
                backgroundColor: "background.paper",
                width: "25em",
                borderRadius: "5px",
                top: "50%",
                transform: "translate(-50%,-50%)",
                display: "flex",
                flexDirection: "column",
                gap: "5px",
                borderRadius: "17px",
                paddingTop: "35px ",
              }}
            >
              <Box sx={{ marginBottom: "25px" }}>
                <Typography
                  sx={{
                    fontFamily: "poppins",
                    fontSize: "18px",
                    fontWeight: "600",
                    textAlign: "center",
                  }}
                >
                  {switchedLanguage ? "هذا الجدول محجوز. هل تريد الاستمرار؟": "This table is reserved. Do you want to continue ?"}
                </Typography>
              </Box>

              <Box>
                <Box
                  sx={{
                    marginBottom: "5px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "15px",
                  }}
                ></Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  gap: "30px",
                  justifyContent: "center",
                }}
              >
                <Button
                  onClick={handlePopupCancelBtn}
                  variant="contained"
                  sx={{
                    boxShadow: "none",
                    width: "30%",
                    height: "43px",
                    // backgroundColor: "#f40000",
                    backgroundColor: "#FFFFFF",
                    // color: "#f2f3f8",
                    color: "#4D575D",
                    border: "none",
                    fontFamily: "poppins",
                    cursor: "pointer",
                    borderRadius: "11px",
                    border: "1px solid #F0F0F0",
                    textTransform: "none",
                    "&:hover": {
                      boxShadow: "none",
                      // backgroundColor: "#f40000",
                      backgroundColor: "#FFFFFF",
                    },
                  }}
                >
                  {switchedLanguage ? "إلغاء": "Cancel"}
                </Button>
                <Button
                  onClick={handlePopupConfirmBtn}
                  variant="contained"
                  sx={{
                    boxShadow: "none",
                    width: "30%",
                    height: "43px",
                    backgroundColor: "#f40000",
                    color: "#f2f3f8",
                    border: "none",
                    fontFamily: "poppins",
                    cursor: "pointer",
                    borderRadius: "11px",
                    textTransform: "none",
                    "&:hover": {
                      boxShadow: "none",
                      backgroundColor: "#f40000",
                    },
                  }}
                >
                  {switchedLanguage ? "التأكيد": "Confirm"}
                </Button>
              </Box>
            </Box>
          </Paper>
        </Modal>
      )}
      {tables?.map((table, index) =>
        query === "All" ? (
          <Tables
            key={table._id}
            table={table}
            handleTableClick={handleTableClick}
            reservedTables={reservedTables}
            handleOpenPopup={handleOpenPopup}
            setPopupTableValue={setPopupTableValue}
          />
        ) : (
          table?.area?.areaName === query && (
            <Tables
              key={table._id}
              table={table}
              handleTableClick={handleTableClick}
              reservedTables={reservedTables}
              handleOpenPopup={handleOpenPopup}
              setPopupTableValue={setPopupTableValue}
            />
          )
        )
      )}
    </Box>
  );
}

function Tables({
  table,
  handleTableClick,
  reservedTables,
  handleOpenPopup,
  setPopupTableValue,
}) {
  return (
    <>
      {/* {table.noOfPeople == "2" && (
        <Box
          className="2-chair"
          sx={{
            bgcolor: "#F5F5F5",
            height: "210px",
            width: "300px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            onClick={() => handleTableClick(table._id, table)}
            className="2-chair-table"
            sx={{
              bgcolor: "#FFFF",
              height: "120px",
              width: "125px",
              borderRadius: "20px",
              py: 3,
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              position: "relative",
              cursor: "pointer",
            }}
          >
            <Box
              sx={{
                bgcolor:
                  table.status === "Available"
                    ? "#D9D9D9"
                    : table.status === "Occupied"
                    ? "#0095F7"
                    : table.status === "Reserved"
                    ? "#F8AF2A"
                    : "#2ABC7A",
                color: "#fff",
                height: "35px",
                width: "35px",
                borderRadius: "50%",
                // mx: "auto",
                margin: "auto",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography sx={{ fontSize: "13px" }}>
                T-{table.tableNo}
              </Typography>
            </Box> */}
      {/* <Box
            sx={{
              bgcolor: "#F5F5F5",
              p: 1,
              borderRadius: "10px",
              margin: "auto",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography sx={{ fontSize: "13px" }}>01:30 PM</Typography>
          </Box> */}
      {/* <Box
              sx={{
                bgcolor:
                  table.status === "Available"
                    ? "#D9D9D9"
                    : table.status === "Occupied"
                    ? "#0095F7"
                    : table.status === "Reserved"
                    ? "#F8AF2A"
                    : "#2ABC7A",
                height: "35px",
                width: "60px",
                borderTopLeftRadius: "17px",
                borderTopRightRadius: "17px",
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                position: "absolute",
                top: -40,
                left: 33,
              }}
            ></Box>
            <Box
              sx={{
                bgcolor:
                  table.status === "Available"
                    ? "#D9D9D9"
                    : table.status === "Occupied"
                    ? "#0095F7"
                    : table.status === "Reserved"
                    ? "#F8AF2A"
                    : "#2ABC7A",
                height: "35px",
                width: "60px",
                borderBottomLeftRadius: "17px",
                borderBottomRightRadius: "17px",
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                position: "absolute",
                bottom: -40,
                left: 33,
              }}
            ></Box>
          </Box>
        </Box>
      )} */}

      {
        <Box
          className="4-chair"
          sx={{
            bgcolor: "#F5F5F5",
            height: "210px",
            width: "20%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            onClick={() => {
              if (reservedTables.includes(table._id)) {
                handleOpenPopup();
                setPopupTableValue(table);
              } else {
                handleTableClick(table);
              }
            }}
            className="4-chair-table"
            sx={{
              bgcolor: "#fff",
              height: "100px",
              width: "120px",
              borderRadius: "20px",
              py: 3,
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              position: "relative",
              cursor: "pointer",
            }}
          >
            <Box
              sx={{
                bgcolor: reservedTables?.includes(table._id)
                  ? "#F8AF2A"
                  : table.status === "Available"
                  ? "#D9D9D9"
                  : table.status === "Occupied"
                  ? "#0095F7"
                  : "#2ABC7A",

                color: "#fff",
                height: "35px",
                width: "35px",
                borderRadius: "50%",
                margin: "auto",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography sx={{ fontSize: "13px" }}>
                T-{table.tableNo}
                
              </Typography>
            </Box>
            {/* <Box
            sx={{
              bgcolor: "#F5F5F5",
              p: 1,
              borderRadius: "10px",
              margin: "auto",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography sx={{ fontSize: "13px" }}>01:30 PM</Typography>
          </Box> */}
            <Box
              sx={{
                bgcolor: reservedTables?.includes(table._id)
                  ? "#F8AF2A"
                  : table.status === "Available"
                  ? "#D9D9D9"
                  : table.status === "Occupied"
                  ? "#0095F7"
                  : "#2ABC7A",
                height: "33px",
                width: "55px",
                borderTopLeftRadius: "17px",
                borderTopRightRadius: "17px",
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                position: "absolute",
                top: -38,
                left: 30,
              }}
            ></Box>
            <Box
              sx={{
                bgcolor: reservedTables?.includes(table._id)
                  ? "#F8AF2A"
                  : table.status === "Available"
                  ? "#D9D9D9"
                  : table.status === "Occupied"
                  ? "#0095F7"
                  : "#2ABC7A",
                height: "33px",
                width: "55px",
                borderBottomLeftRadius: "17px",
                borderBottomRightRadius: "17px",
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                position: "absolute",
                bottom: -38,
                left: 30,
              }}
            ></Box>
            <Box
              sx={{
                bgcolor: reservedTables?.includes(table._id)
                  ? "#F8AF2A"
                  : table.status === "Available"
                  ? "#D9D9D9"
                  : table.status === "Occupied"
                  ? "#0095F7"
                  : "#2ABC7A",
                height: "53px",
                width: "35px",
                borderTopLeftRadius: "17px",
                borderBottomLeftRadius: "17px",
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                position: "absolute",
                bottom: 25,
                left: -40,
              }}
            ></Box>
            <Box
              sx={{
                bgcolor: reservedTables?.includes(table._id)
                  ? "#F8AF2A"
                  : table.status === "Available"
                  ? "#D9D9D9"
                  : table.status === "Occupied"
                  ? "#0095F7"
                  : "#2ABC7A",
                height: "53px",
                width: "35px",
                borderTopRightRadius: "17px",
                borderBottomRightRadius: "17px",
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                position: "absolute",
                bottom: 25,
                right: -40,
              }}
            ></Box>
          </Box>
        </Box>
      }

      {/*{table.noOfPeople == "6" && (
        <Box
          className="6-chair"
          sx={{
            bgcolor: "#F5F5F5",
            height: "210px",
            width: "300px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
          }}
        >
          <Box
            onClick={() => handleTableClick(table._id, table)}
            sx={{
              bgcolor: "#fff",
              height: "120px",
              width: "210px",
              borderRadius: "20px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: "10px",
              position: "relative",
            }}
          >
            <Box
              sx={{
                bgcolor:
                  table.status === "Available"
                    ? "#D9D9D9"
                    : table.status === "Occupied"
                    ? "#0095F7"
                    : table.status === "Reserved"
                    ? "#F8AF2A"
                    : "#2ABC7A",
                    color: "#fff",
                height: "35px",
                width: "35px",
                borderRadius: "50%",
                margin: "auto",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography sx={{ fontSize: "13px" }}>
                T-{table.tableNo}
              </Typography>
            </Box> */}
      {/* <Box
            sx={{
              bgcolor: "#F5F5F5",
              height: "50px",
              width: "120px",
              borderRadius: "10px",
              mx: "auto",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography sx={{ fontSize: "13px" }}>12 Aug 23</Typography>
            <Typography sx={{ fontSize: "13px" }}>01:30 PM</Typography>
          </Box> */}
      {/* <Box
              sx={{
                bgcolor:
                  table.status === "Available"
                    ? "#D9D9D9"
                    : table.status === "Occupied"
                    ? "#0095F7"
                    : table.status === "Reserved"
                    ? "#F8AF2A"
                    : "#2ABC7A",
                height: "35px",
                width: "50px",
                borderTopLeftRadius: "17px",
                borderTopRightRadius: "17px",
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                position: "absolute",
                top: -40,
                left: 20,
              }}
            ></Box>
            <Box
              sx={{
                bgcolor:
                  table.status === "Available"
                    ? "#D9D9D9"
                    : table.status === "Occupied"
                    ? "#0095F7"
                    : table.status === "Reserved"
                    ? "#F8AF2A"
                    : "#2ABC7A",
                height: "35px",
                width: "50px",
                borderTopLeftRadius: "17px",
                borderTopRightRadius: "17px",
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                position: "absolute",
                top: -40,
                left: 80,
              }}
            ></Box>
            <Box
              sx={{
                bgcolor:
                  table.status === "Available"
                    ? "#D9D9D9"
                    : table.status === "Occupied"
                    ? "#0095F7"
                    : table.status === "Reserved"
                    ? "#F8AF2A"
                    : "#2ABC7A",
                height: "35px",
                width: "50px",
                borderTopLeftRadius: "17px",
                borderTopRightRadius: "17px",
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                position: "absolute",
                top: -40,
                left: 140,
              }}
            ></Box>
            <Box
              sx={{
                bgcolor:
                  table.status === "Available"
                    ? "#D9D9D9"
                    : table.status === "Occupied"
                    ? "#0095F7"
                    : table.status === "Reserved"
                    ? "#F8AF2A"
                    : "#2ABC7A",
                height: "35px",
                width: "50px",
                borderBottomLeftRadius: "17px",
                borderBottomRightRadius: "17px",
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                position: "absolute",
                bottom: -40,
                left: 140,
              }}
            ></Box>
            <Box
              sx={{
                bgcolor:
                  table.status === "Available"
                    ? "#D9D9D9"
                    : table.status === "Occupied"
                    ? "#0095F7"
                    : table.status === "Reserved"
                    ? "#F8AF2A"
                    : "#2ABC7A",
                height: "35px",
                width: "50px",
                borderBottomLeftRadius: "17px",
                borderBottomRightRadius: "17px",
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                position: "absolute",
                bottom: -40,
                left: 20,
              }}
            ></Box>
            <Box
              sx={{
                bgcolor:
                  table.status === "Available"
                    ? "#D9D9D9"
                    : table.status === "Occupied"
                    ? "#0095F7"
                    : table.status === "Reserved"
                    ? "#F8AF2A"
                    : "#2ABC7A",
                height: "35px",
                width: "50px",
                borderBottomLeftRadius: "17px",
                borderBottomRightRadius: "17px",
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                position: "absolute",
                bottom: -40,
                left: 80,
              }}
            ></Box>
          </Box>
        </Box>
      )}
      {table.noOfPeople == "8" && (
        <Box
          className="8-chair"
          sx={{
            bgcolor: "#F5F5F5",
            height: "210px",
            width: "300px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            onClick={() => handleTableClick(table._id, table)}
            sx={{
              bgcolor: "#fff",
              height: "120px",
              width: "210px",
              borderRadius: "20px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              // gap: "10px",
              position: "relative",
              cursor: "pointer",
            }}
          >
            <Box
              sx={{
                bgcolor:
                  table.status === "Available"
                    ? "#D9D9D9"
                    : table.status === "Occupied"
                    ? "#0095F7"
                    : table.status === "Reserved"
                    ? "#F8AF2A"
                    : "#2ABC7A",
                    color: "#fff",
                height: "35px",
                width: "35px",
                borderRadius: "50%",
                margin: "auto",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography sx={{ fontSize: "13px" }}>
                T-{table.tableNo}
              </Typography>
            </Box> */}
      {/* <Box
            sx={{
              bgcolor: "#F5F5F5",
              height: "50px",
              width: "120px",
              borderRadius: "10px",
              margin: "auto",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography sx={{ fontSize: "13px" }}>12 Aug 23</Typography>
            <Typography sx={{ fontSize: "13px" }}>01:30 PM</Typography>
          </Box> */}
      {/* <Box
              sx={{
                bgcolor:
                  table.status === "Available"
                    ? "#D9D9D9"
                    : table.status === "Occupied"
                    ? "#0095F7"
                    : table.status === "Reserved"
                    ? "#F8AF2A"
                    : "#2ABC7A",
                height: "35px",
                width: "50px",
                borderTopLeftRadius: "17px",
                borderTopRightRadius: "17px",
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                position: "absolute",
                top: -40,
                left: 20,
              }}
            ></Box>
            <Box
              sx={{
                bgcolor:
                  table.status === "Available"
                    ? "#D9D9D9"
                    : table.status === "Occupied"
                    ? "#0095F7"
                    : table.status === "Reserved"
                    ? "#F8AF2A"
                    : "#2ABC7A",
                height: "35px",
                width: "50px",
                borderTopLeftRadius: "17px",
                borderTopRightRadius: "17px",
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                position: "absolute",
                top: -40,
                left: 80,
              }}
            ></Box>
            <Box
              sx={{
                bgcolor:
                  table.status === "Available"
                    ? "#D9D9D9"
                    : table.status === "Occupied"
                    ? "#0095F7"
                    : table.status === "Reserved"
                    ? "#F8AF2A"
                    : "#2ABC7A",
                height: "35px",
                width: "50px",
                borderTopLeftRadius: "17px",
                borderTopRightRadius: "17px",
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                position: "absolute",
                top: -40,
                left: 140,
              }}
            ></Box>

            <Box
              sx={{
                bgcolor:
                  table.status === "Available"
                    ? "#D9D9D9"
                    : table.status === "Occupied"
                    ? "#0095F7"
                    : table.status === "Reserved"
                    ? "#F8AF2A"
                    : "#2ABC7A",
                height: "35px",
                width: "50px",
                borderBottomLeftRadius: "17px",
                borderBottomRightRadius: "17px",
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                position: "absolute",
                bottom: -40,
                left: 140,
              }}
            ></Box>
            <Box
              sx={{
                bgcolor:
                  table.status === "Available"
                    ? "#D9D9D9"
                    : table.status === "Occupied"
                    ? "#0095F7"
                    : table.status === "Reserved"
                    ? "#F8AF2A"
                    : "#2ABC7A",
                height: "35px",
                width: "50px",
                borderBottomLeftRadius: "17px",
                borderBottomRightRadius: "17px",
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                position: "absolute",
                bottom: -40,
                left: 20,
              }}
            ></Box>
            <Box
              sx={{
                bgcolor:
                  table.status === "Available"
                    ? "#D9D9D9"
                    : table.status === "Occupied"
                    ? "#0095F7"
                    : table.status === "Reserved"
                    ? "#F8AF2A"
                    : "#2ABC7A",
                height: "35px",
                width: "50px",
                borderBottomLeftRadius: "17px",
                borderBottomRightRadius: "17px",
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                position: "absolute",
                bottom: -40,
                left: 80,
              }}
            ></Box>

            <Box
              sx={{
                bgcolor:
                  table.status === "Available"
                    ? "#D9D9D9"
                    : table.status === "Occupied"
                    ? "#0095F7"
                    : table.status === "Reserved"
                    ? "#F8AF2A"
                    : "#2ABC7A",
                height: "55px",
                width: "35px",
                borderTopLeftRadius: "17px",
                borderBottomLeftRadius: "17px",
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                position: "absolute",
                bottom: 35,
                left: -40,
              }}
            ></Box>
            <Box
              sx={{
                bgcolor:
                  table.status === "Available"
                    ? "#D9D9D9"
                    : table.status === "Occupied"
                    ? "#0095F7"
                    : table.status === "Reserved"
                    ? "#F8AF2A"
                    : "#2ABC7A",
                height: "55px",
                width: "35px",
                borderTopRightRadius: "17px",
                borderBottomRightRadius: "17px",
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                position: "absolute",
                bottom: 35,
                right: -40,
              }}
            ></Box>
          </Box>
        </Box>
      )}

      {table.noOfPeople == "10" && (
        <Box
          className="10-chair"
          sx={{
            bgcolor: "#F5F5F5",
            height: "210px",
            width: "360px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            onClick={() => handleTableClick(table._id, table)}
            sx={{
              bgcolor: "#fff",
              height: "120px",
              width: "270px",
              borderRadius: "20px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: "10px",
              position: "relative",
              cursor: "pointer",
            }}
          >
            <Box
              sx={{
                bgcolor:
                  table.status === "Available"
                    ? "#D9D9D9"
                    : table.status === "Occupied"
                    ? "#0095F7"
                    : table.status === "Reserved"
                    ? "#F8AF2A"
                    : "#2ABC7A",
                    color: "#fff",
                height: "35px",
                width: "35px",
                borderRadius: "50%",
                margin: "auto",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography sx={{ fontSize: "13px" }}>
                T-{table.tableNo}
              </Typography>
            </Box> */}
      {/* <Box
            sx={{
              bgcolor: "#F5F5F5",
              height: "50px",
              width: "120px",
              borderRadius: "10px",
              margin: "auto",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography sx={{ fontSize: "13px" }}>12 Aug 23</Typography>
            <Typography sx={{ fontSize: "13px" }}>01:30 PM</Typography>
          </Box> */}
      {/* <Box
              sx={{
                bgcolor:
                  table.status === "Available"
                    ? "#D9D9D9"
                    : table.status === "Occupied"
                    ? "#0095F7"
                    : table.status === "Reserved"
                    ? "#F8AF2A"
                    : "#2ABC7A",
                height: "35px",
                width: "50px",
                borderTopLeftRadius: "17px",
                borderTopRightRadius: "17px",
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                position: "absolute",
                top: -40,
                left: 20,
              }}
            ></Box>
            <Box
              sx={{
                bgcolor:
                  table.status === "Available"
                    ? "#D9D9D9"
                    : table.status === "Occupied"
                    ? "#0095F7"
                    : table.status === "Reserved"
                    ? "#F8AF2A"
                    : "#2ABC7A",
                height: "35px",
                width: "50px",
                borderTopLeftRadius: "17px",
                borderTopRightRadius: "17px",
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                position: "absolute",
                top: -40,
                left: 80,
              }}
            ></Box>
            <Box
              sx={{
                bgcolor:
                  table.status === "Available"
                    ? "#D9D9D9"
                    : table.status === "Occupied"
                    ? "#0095F7"
                    : table.status === "Reserved"
                    ? "#F8AF2A"
                    : "#2ABC7A",
                height: "35px",
                width: "50px",
                borderTopLeftRadius: "17px",
                borderTopRightRadius: "17px",
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                position: "absolute",
                top: -40,
                left: 140,
              }}
            ></Box>
            <Box
              sx={{
                bgcolor:
                  table.status === "Available"
                    ? "#D9D9D9"
                    : table.status === "Occupied"
                    ? "#0095F7"
                    : table.status === "Reserved"
                    ? "#F8AF2A"
                    : "#2ABC7A",
                height: "35px",
                width: "50px",
                borderBottomLeftRadius: "17px",
                borderBottomRightRadius: "17px",
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                position: "absolute",
                bottom: -40,
                left: 140,
              }}
            ></Box>
            <Box
              sx={{
                bgcolor:
                  table.status === "Available"
                    ? "#D9D9D9"
                    : table.status === "Occupied"
                    ? "#0095F7"
                    : table.status === "Reserved"
                    ? "#F8AF2A"
                    : "#2ABC7A",
                height: "35px",
                width: "50px",
                borderTopLeftRadius: "17px",
                borderTopRightRadius: "17px",
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                position: "absolute",
                top: -40,
                left: 200,
              }}
            ></Box>
            <Box
              sx={{
                bgcolor:
                  table.status === "Available"
                    ? "#D9D9D9"
                    : table.status === "Occupied"
                    ? "#0095F7"
                    : table.status === "Reserved"
                    ? "#F8AF2A"
                    : "#2ABC7A",
                height: "35px",
                width: "50px",
                borderBottomLeftRadius: "17px",
                borderBottomRightRadius: "17px",
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                position: "absolute",
                bottom: -40,
                left: 200,
              }}
            ></Box>
            <Box
              sx={{
                bgcolor:
                  table.status === "Available"
                    ? "#D9D9D9"
                    : table.status === "Occupied"
                    ? "#0095F7"
                    : table.status === "Reserved"
                    ? "#F8AF2A"
                    : "#2ABC7A",
                height: "35px",
                width: "50px",
                borderBottomLeftRadius: "17px",
                borderBottomRightRadius: "17px",
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                position: "absolute",
                bottom: -40,
                left: 20,
              }}
            ></Box>
            <Box
              sx={{
                bgcolor:
                  table.status === "Available"
                    ? "#D9D9D9"
                    : table.status === "Occupied"
                    ? "#0095F7"
                    : table.status === "Reserved"
                    ? "#F8AF2A"
                    : "#2ABC7A",
                height: "35px",
                width: "50px",
                borderBottomLeftRadius: "17px",
                borderBottomRightRadius: "17px",
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                position: "absolute",
                bottom: -40,
                left: 80,
              }}
            ></Box>
            <Box
              sx={{
                bgcolor:
                  table.status === "Available"
                    ? "#D9D9D9"
                    : table.status === "Occupied"
                    ? "#0095F7"
                    : table.status === "Reserved"
                    ? "#F8AF2A"
                    : "#2ABC7A",
                height: "55px",
                width: "35px",
                borderTopLeftRadius: "17px",
                borderBottomLeftRadius: "17px",
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                position: "absolute",
                bottom: 35,
                left: -40,
              }}
            ></Box>
            <Box
              sx={{
                bgcolor:
                  table.status === "Available"
                    ? "#D9D9D9"
                    : table.status === "Occupied"
                    ? "#0095F7"
                    : table.status === "Reserved"
                    ? "#F8AF2A"
                    : "#2ABC7A",
                height: "55px",
                width: "35px",
                borderTopRightRadius: "17px",
                borderBottomRightRadius: "17px",
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                position: "absolute",
                bottom: 35,
                right: -40,
              }}
            ></Box>
          </Box>
        </Box>
      )}

      {table.noOfPeople == "12" && (
        <Box
          className="12-chair"
          sx={{
            bgcolor: "#F5F5F5",
            height: "210px",
            width: "420px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            onClick={() => handleTableClick(table._id, table)}
            sx={{
              bgcolor: "#fff",
              height: "120px",
              width: "330px",
              borderRadius: "20px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: "10px",
              position: "relative",
              cursor: "pointer",
            }}
          >
            <Box
              sx={{
                bgcolor:
                  table.status === "Available"
                    ? "#D9D9D9"
                    : table.status === "Occupied"
                    ? "#0095F7"
                    : table.status === "Reserved"
                    ? "#F8AF2A"
                    : "#2ABC7A",
                    color: "#fff",
                height: "35px",
                width: "35px",
                borderRadius: "50%",
                margin: "auto",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography sx={{ fontSize: "13px" }}>
                T-{table.tableNo}
              </Typography>
            </Box> */}
      {/* <Box
            sx={{
              bgcolor: "#F5F5F5",
              height: "50px",
              width: "120px",
              borderRadius: "10px",
              margin: "auto",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography sx={{ fontSize: "13px" }}>12 Aug 23</Typography>
            <Typography sx={{ fontSize: "13px" }}>01:30 PM</Typography>
          </Box> */}
      {/* <Box
              sx={{
                bgcolor:
                  table.status === "Available"
                    ? "#D9D9D9"
                    : table.status === "Occupied"
                    ? "#0095F7"
                    : table.status === "Reserved"
                    ? "#F8AF2A"
                    : "#2ABC7A",
                height: "35px",
                width: "50px",
                borderTopLeftRadius: "17px",
                borderTopRightRadius: "17px",
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                position: "absolute",
                top: -40,
                left: 20,
              }}
            ></Box>
            <Box
              sx={{
                bgcolor:
                  table.status === "Available"
                    ? "#D9D9D9"
                    : table.status === "Occupied"
                    ? "#0095F7"
                    : table.status === "Reserved"
                    ? "#F8AF2A"
                    : "#2ABC7A",
                height: "35px",
                width: "50px",
                borderTopLeftRadius: "17px",
                borderTopRightRadius: "17px",
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                position: "absolute",
                top: -40,
                left: 80,
              }}
            ></Box>
            <Box
              sx={{
                bgcolor:
                  table.status === "Available"
                    ? "#D9D9D9"
                    : table.status === "Occupied"
                    ? "#0095F7"
                    : table.status === "Reserved"
                    ? "#F8AF2A"
                    : "#2ABC7A",
                height: "35px",
                width: "50px",
                borderTopLeftRadius: "17px",
                borderTopRightRadius: "17px",
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                position: "absolute",
                top: -40,
                left: 140,
              }}
            ></Box>
            <Box
              sx={{
                bgcolor:
                  table.status === "Available"
                    ? "#D9D9D9"
                    : table.status === "Occupied"
                    ? "#0095F7"
                    : table.status === "Reserved"
                    ? "#F8AF2A"
                    : "#2ABC7A",
                height: "35px",
                width: "50px",
                borderBottomLeftRadius: "17px",
                borderBottomRightRadius: "17px",
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                position: "absolute",
                bottom: -40,
                left: 140,
              }}
            ></Box>
            <Box
              sx={{
                bgcolor:
                  table.status === "Available"
                    ? "#D9D9D9"
                    : table.status === "Occupied"
                    ? "#0095F7"
                    : table.status === "Reserved"
                    ? "#F8AF2A"
                    : "#2ABC7A",
                height: "35px",
                width: "50px",
                borderTopLeftRadius: "17px",
                borderTopRightRadius: "17px",
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                position: "absolute",
                top: -40,
                left: 200,
              }}
            ></Box>
            <Box
              sx={{
                bgcolor:
                  table.status === "Available"
                    ? "#D9D9D9"
                    : table.status === "Occupied"
                    ? "#0095F7"
                    : table.status === "Reserved"
                    ? "#F8AF2A"
                    : "#2ABC7A",
                height: "35px",
                width: "50px",
                borderBottomLeftRadius: "17px",
                borderBottomRightRadius: "17px",
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                position: "absolute",
                bottom: -40,
                left: 200,
              }}
            ></Box>
            <Box
              sx={{
                bgcolor:
                  table.status === "Available"
                    ? "#D9D9D9"
                    : table.status === "Occupied"
                    ? "#0095F7"
                    : table.status === "Reserved"
                    ? "#F8AF2A"
                    : "#2ABC7A",
                height: "35px",
                width: "50px",
                borderTopLeftRadius: "17px",
                borderTopRightRadius: "17px",
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                position: "absolute",
                top: -40,
                left: 260,
              }}
            ></Box>
            <Box
              sx={{
                bgcolor:
                  table.status === "Available"
                    ? "#D9D9D9"
                    : table.status === "Occupied"
                    ? "#0095F7"
                    : table.status === "Reserved"
                    ? "#F8AF2A"
                    : "#2ABC7A",
                height: "35px",
                width: "50px",
                borderBottomLeftRadius: "17px",
                borderBottomRightRadius: "17px",
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                position: "absolute",
                bottom: -40,
                left: 260,
              }}
            ></Box>
            <Box
              sx={{
                bgcolor:
                  table.status === "Available"
                    ? "#D9D9D9"
                    : table.status === "Occupied"
                    ? "#0095F7"
                    : table.status === "Reserved"
                    ? "#F8AF2A"
                    : "#2ABC7A",
                height: "35px",
                width: "50px",
                borderBottomLeftRadius: "17px",
                borderBottomRightRadius: "17px",
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                position: "absolute",
                bottom: -40,
                left: 20,
              }}
            ></Box>
            <Box
              sx={{
                bgcolor:
                  table.status === "Available"
                    ? "#D9D9D9"
                    : table.status === "Occupied"
                    ? "#0095F7"
                    : table.status === "Reserved"
                    ? "#F8AF2A"
                    : "#2ABC7A",
                height: "35px",
                width: "50px",
                borderBottomLeftRadius: "17px",
                borderBottomRightRadius: "17px",
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                position: "absolute",
                bottom: -40,
                left: 80,
              }}
            ></Box>
            <Box
              sx={{
                bgcolor:
                  table.status === "Available"
                    ? "#D9D9D9"
                    : table.status === "Occupied"
                    ? "#0095F7"
                    : table.status === "Reserved"
                    ? "#F8AF2A"
                    : "#2ABC7A",
                height: "55px",
                width: "35px",
                borderTopLeftRadius: "17px",
                borderBottomLeftRadius: "17px",
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                position: "absolute",
                bottom: 35,
                left: -40,
              }}
            ></Box>
            <Box
              sx={{
                bgcolor:
                  table.status === "Available"
                    ? "#D9D9D9"
                    : table.status === "Occupied"
                    ? "#0095F7"
                    : table.status === "Reserved"
                    ? "#F8AF2A"
                    : "#2ABC7A",
                height: "55px",
                width: "35px",
                borderTopRightRadius: "17px",
                borderBottomRightRadius: "17px",
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                position: "absolute",
                bottom: 35,
                right: -40,
              }}
            ></Box>
          </Box>
        </Box>
      )} */}
    </>
  );
}
